import { connect } from "react-redux";

function Loader({username}){

    return (
        <div className="loader">
        <div className="spinner"></div>
        <div className="text">{username!='' ? username : 'Loading'}</div>
      </div>
    )
}
const mapStateToProps = (state) => {
  return {
    username: state.authUser.username,
  };
};
export default connect(mapStateToProps)(Loader);