import {useState,useEffect} from 'react';
import Popup from 'reactjs-popup';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CalculateIcon from '@mui/icons-material/Calculate';
import {RadioGroup,Radio,FormControlLabel} from '@mui/material'
import store from '../../store';
import TaxCalc from '../TaxCalc';

const accountBalanceColor=(balance)=>{
    if (isNaN(balance)) {
        return 'invalid-balance';
    }
    switch (true) {
        case balance > 0:
            return 'positive-balance';
        case balance === 0:
            return 'warning-balance';
        case balance < 0:
            return 'negative-balance';
        default:
            return 'invalid-balance';
    }
}


const BankInfo = ({bank , sumIncomes,sumExpenses,currenciesRatio}) => {
    const [isOpen,setIsOpen]=useState(false);
    const [isCalcOpen,setIsCalcOpen]=useState(false);
    const [sumIncExpes,SetSumIncExpes]=useState(store.getIncomeExpensesReport(sumIncomes,sumExpenses));
    const [chosenCoin,setChosenCoin]=useState('shekel');






    const getIncomeExpensesReport_byCurrency=(coin)=>{
        const reportByCurrency={
            coin: coin ? coin:chosenCoin,
            income:0,
            expense:0,
        }
        sumIncomes.forEach(income => {
            reportByCurrency.income+=parseFloat(income.averagePaycheck*currenciesRatio[income.coin][chosenCoin])
        });
        sumExpenses.forEach(expense => {
            reportByCurrency.expense+=parseFloat(expense.cost*currenciesRatio[expense.coin][chosenCoin])
        });
        return reportByCurrency;
    }
    const [reportByCurrency,setReportByCurrency]=useState(getIncomeExpensesReport_byCurrency());

    useEffect(()=>{
        setReportByCurrency(getIncomeExpensesReport_byCurrency());
    },[chosenCoin])

    


    const handleChangeCoin=(e)=>{
        setChosenCoin(e.target.value);
    }
    useEffect(()=>{
        SetSumIncExpes((store.getIncomeExpensesReport(sumIncomes,sumExpenses)));
    },[sumIncomes,sumExpenses])






    return (
        <div className='popup_report-container'>
            <h5>Bank Account Information </h5>
            <span className='report_icon_wrapper' onClick={()=>setIsOpen(true)}><SummarizeIcon /></span>
            <span className='report_icon_wrapper' onClick={()=>setIsCalcOpen(true)}><CalculateIcon /></span>
            <Popup open={isOpen} closeOnDocumentClick onClose={()=>setIsOpen(false)}>
                <div className='report-container'>
                    <h3>{bank.name}</h3>
                    <h4>Account Number : {bank.accountNumber}</h4>
                    <p>Account Info : {bank.accountInfo}</p>
                    <div className='flex-column'>
                        <h4 className='middleHeader'>Foreign Currency Account Summary</h4>
                        <div className='bank-sumCoin-container'>
                            {sumIncExpes.map(sumIncExp=>
                                <div className='container-boxShadow bank_coin-container'>
                                    <h5>Currency : {sumIncExp.coin}</h5>
                                    <p>Sum Incomes : {sumIncExp.income}</p>
                                    <p>Sum Expenses : {sumIncExp.expense}</p>
                                    <p className={accountBalanceColor(sumIncExp.income-sumIncExp.expense)}>Account Balance : {sumIncExp.income-sumIncExp.expense}</p>
                                </div>
                                )}
                        </div>
                    </div>
                   <div className='account-balance-currencytransform flex-column'>
                        <h4 className='middleHeader'>Foreign Currencies Account Summary </h4>
                        <div className='chooseCoin_balance-container'>
                            <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="coin"
                                value={chosenCoin}
                                onChange={handleChangeCoin}
                            >
                                <FormControlLabel value="shekel" control={<Radio />} label="Shekel" />
                                <FormControlLabel value="dollar" control={<Radio />} label="Dollar" />
                                <FormControlLabel value="euro" control={<Radio />} label="Euro" />
                            </RadioGroup>
                            <div className='container-boxShadow bank_coin-container bank_coinCurrency-container'>
                                    <h5>Currency : {reportByCurrency.coin}</h5>
                                    <p>Sum Incomes : {reportByCurrency.income}</p>
                                    <p>Sum Expenses : {reportByCurrency.expense}</p>
                                    <p className={accountBalanceColor(reportByCurrency.income-reportByCurrency.expense)}>Account Balance : {reportByCurrency.income-reportByCurrency.expense}</p>
                            </div>
                        </div>
                   </div>
                </div>
            </Popup>
            <TaxCalc state={isCalcOpen} setState={setIsCalcOpen} />
        </div>
    );
};

export default BankInfo;