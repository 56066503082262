import dayjs from 'dayjs';

// 'rgb(148, 147, 213)',
const store={
    workout:{
        bodyParts:['chest','backHand','back','frontHand','legs','lowerLegs','shoulders'],
        part:['run','football','swimming']
    },
    rechartMonthTemplate:[
      { name: 'January',  },
      { name: 'February',  },
      { name: 'March',  },
      { name: 'April',  },
      { name: 'May',  },
      { name: 'June',  },
      { name: 'July',  },
      { name: 'August',  },
      { name: 'September',  },
      { name: 'October',  },
      { name: 'November',  },
      { name: 'December',  }
    ],
    funnyTaskReminders : [
      "Looks like someone's procrastination just leveled up to villain. Time to defeat it, hero!",
      "Uh oh, your to-do list is about to go full Thanos snap on your free time. Get crackin'!",
      "Is that to-do list staring at you harder than a Demogorgon? Time to fight fire with fire... by actually doing it!",
      "I see that to-do list is turning into a dust bunny monster. Time to unleash your inner cleaning cheetah!",
      "Uh oh, that inbox is overflowing faster than a penguin at a buffet. Time to waddle over and tackle it!",
      "The suspense is killing me! Will you conquer your to-do list, or will it conquer you? The world waits with bated breath...",
      "Is that to-do list giving you the side-eye? Don't let it win! Show it who's boss!",
      "That to-do list is going 'tick-tock'! Time to answer its call with a resounding 'whoosh, I got this done!'" ,
      "Hear that faint 'whinge'? It's your to-do list begging for attention. Give it some love (by completing it)!",
    ],
    colors : ['rgb(89, 105, 235)','rgb(136,198,150)', 'rgb(0, 128, 255)','rgb(255, 165, 0)'],
    getRandomFunnyTaskReminder:function(){
      return this.funnyTaskReminders[Math.floor((Math.random()*this.funnyTaskReminders.length))]
    },
    emailRegex : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    deepCopy:(data)=>(JSON.parse(JSON.stringify(data))),
    compareDataSameOrder:(dataA,dataB)=>(JSON.stringify(dataA)===JSON.stringify(dataB)),
    groupByKey:function(arr, key) {
      if(arr[0].hasOwnProperty(key)){
        const grouped = arr.reduce((acc, item) => {
          const groupKey = item[key];
          if (groupKey) { // Check if the key exists and is not undefined or null
            if (!acc[groupKey]) {
              acc[groupKey] = [];
            }
            acc[groupKey].push(item);
          }
          return acc;
        }, {});
      
        return Object.values(grouped);
      }
      return [arr];
    },
    formKeys:(dbtype,method)=>{
        switch (dbtype) {
            case "project":
               return {name:'',_id:'',startDate:'',finishDate:''};
            
            case "projectStep":
              return  {name:'',_id:'',startDate:'',finishDate:'',projectId:'',order:1};
            
            case "task":
               return {name:'',_id:'',projectStepId:'',startDate:'',finishDate:'',status:-1,duration:0,info:'',order:1};
            
            case "user":
              return {_id:'',username:'',password:'',firstName:'',lastName:'',email:''};
            
            case "idea":
              return { _id:'',name: '', description: '', notes:[''] };
          
            case "workoutPlan":
              return {
                _id:'',
                name:'',
                rotationInfo:{},
                weeklyCycle:false,
                rotation:'',
                isActive:false,
                startDate:null,
                isBeforeWorkDay:true//before or after work ? (morning /night) before work default set true
            };
            
            case "bank":
                return {_id:'',name:'',accountInfo:'',accountNumber:'',};
            
            case "card":
              return {_id:'',name:'',bankId:'',billingMonthDay:''};
            
            case "expense":
                return {_id:'',cardId:'',name:'',cost:'',billingMonthDay:'',coin:'shekel',type:''};
          
            case "notificationGroup":
              return {_id:'',name:''};
          
            case "notification":
              return {_id:'',name:'',description:'',type:'',finishDate:null,byHour:['']};
          
            case "weeklySchedule":
              return {
                _id:'',
                name:'',
                startDayHour:'',
                startWorkHour:'',
                schedule:[
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}],
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}],
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}],
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}],
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}],
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}],
                  [{startDayHour:[0,0],startWorkHour: [0, 0]},{key:null,hours:[0,0]}]
                ],
                isActive:false,
            };
          
            case "weights":
                return {
                    _id:'',
                    legs:{},
                    lowerLegs:{},
                    shoulders:{},
                    back:{},
                    frontHand:{},
                    chest:{},
                    backHand:{},
            }
            case "income":
             return {_id:'',name:'',bankId:'',coin:'',averagePaycheck:''};
            case "paycheck":
              return {_id:'',incomeId:'',money:''};
            case "FinancialConduct":
              return {name:'',cost:'',coin:'shekel',type:'leisure',isIncome:false}
            default:
                return false;
          }
    },
    notRequiredFormKeys:(dbtype)=>{
        switch (dbtype) {

            case "project":
               return [];
            
            case "projectStep":
              return  ['order'];
            
            case "task":
               return ['order','info'];
            
            case "user":
              return [];
            
            case "idea":
              return ['notes'];
          
            case "workoutPlan":
              return ['startDate'];
              
            
            case "bank":
                return ['accountInfo'];
            
            case "card":
              return [];
            
            case "expense":
              return ['billingMonthDay'];
            
              case "income":
                return [];
               case "paycheck":
                 return []
          
            case "notificationGroup":
              return [];
          
            case "notification":
              return ['byHour','description','finishDate'];
          
            case "weeklySchedule":
              return ['startDayHour','startWorkHour'];
              
          
            case "weights":
                return [];
           
            default:
                return false;
          }
    },
    getDayNameByNumber:(dayNumber)=>{
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayNumber];
    },
    parentBydbtype:(dbtype)=>{
        switch (dbtype){
            case 'projectStep':
                return 'project';
            case 'task':
                return 'projectStep';
            case 'card':
                return 'bank';
            case 'income':
              return 'bank';
            case 'paycheck':
              return 'income';
            case 'expense':
                return 'card';
            case 'notification':
                return 'notificationGroup'
            default:
                return false;
        }
    },
    grandpaBydbtype:(dbtype)=>{
        switch (dbtype){
            case 'task':
                return 'project'
            default:
                return false;
        }
    },
    formDataToElement:(elementType,data)=>{
      switch (elementType){
        case 'dateRange':
          return  [{
            startDate: new Date(data.startDate),
            endDate: new Date(data.finishDate),
            key: 'selection'
          }];
        case 'calendar':
          return;
        case 'textField':
          return;
        case 'timePicker':
          const timePicker = new Date();
          timePicker.setHours(data.byHour[0]);
          timePicker.setMinutes(data.byHour[1]);
          return dayjs(timePicker);
        case 'verification':
          return;
        case 'hoursMins':
          if(data.duration==''){
            return {
              hours:'',
              mins:''
            }
          }
          const hours = Math.floor(data.duration / 60);
          const mins = data.duration % 60;
          return {
            hours,
            mins
          };
        default:
        
          return;
      }
    },
    elementDataToForm:(elementType,data)=>{
      switch (elementType){
        case 'dateRange': //96
          return  {
            startDate: new Date(data.startDate),
            finishDate: new Date(data.endDate),
          };
        case 'calendar':
          return;
        case 'timeField':
          return;
        case 'timePicker':
          return [data.hour(),data.minute()];
        case 'verification':
          return;
        case 'hoursMins':
          return (Number(data.hours)*60 + Number(data.mins));
        default:
          return;
      }
    },
    formValidation:function(dbtype,method,formData){
      let auth=this.notRequiredFormKeys(dbtype);
      let basicFormKeys=this.formKeys(dbtype);

      // methods added auth key
        // post add to auth '_id'
        // delete required: '_id'
        // put required : all

      switch (method) {
        case 'POST':
          auth.push('_id');
          break;
        case 'DELETE':
          basicFormKeys={_id:''}
          break;
        default:
          break;
      }

      let unvalidKeys=[];
      for(const key in formData){
        if(formData[key]==basicFormKeys[key]){
          !auth.includes(key) && unvalidKeys.push(key);
        }
      }
      if(dbtype==='user'){
        !this.emailRegex.test(formData['email'])  && unvalidKeys.push('email');
        
      }
      return unvalidKeys;
    },
    getDailyScheduleSumHours:(schedule,isNextday=false)=>{
      let sumHours=[0,0];
      let gotToNextDay=false;
      schedule.forEach(element => {
        sumHours[0]+=element.hours[0];
        sumHours[1]+=element.hours[1];
        if(sumHours[1]>=60){
          let left=sumHours[1]%60;
          sumHours[0]+=1;
          sumHours[1]=left;
        }
        if(sumHours[0]>=24){
          let left=sumHours[0]%24;
          sumHours[0]=left;
          gotToNextDay=true;
        }
       
      });
      return !isNextday ? sumHours : {sumHours,gotToNextDay};
    },
    formatMinutesToHHMM:(minutes)=> {
      if (isNaN(minutes) || minutes < 0) {
          return '00:00'; // Handle invalid input
      }
  
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
  
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(remainingMinutes).padStart(2, '0');
  
      return `${formattedHours}:${formattedMinutes}`;
    },
    displayHours:(hours)=>{
      let strHours=`${hours[0]=='0' ? '00' : hours[0]<10 ? `0${hours[0]}` : hours[0]}:${hours[1]=='0' ? '00' : hours[1]<10 ? `0${hours[1]}` : hours[1]}`;
      return strHours;
    },
    pretifyTime:(time)=>{
      let strTime=time<10 ? `0${time}` :time;
      return strTime;
    },
    getEndtimeByDuration:(duration)=>{
      const newDate = new Date();
      const hoursToAdd = Math.floor(duration/60);
      const minutesToAdd = duration%60;

      // Milliseconds per hour and second
      const millisecondsPerHour = 3600000;
      const millisecondsPerMinute = 60000;

      // Calculate total milliseconds to add
      const totalMillisecondsToAdd = (hoursToAdd * millisecondsPerHour) + (minutesToAdd * millisecondsPerMinute);

      // Add milliseconds to the new date
      newDate.setTime(newDate.getTime() + totalMillisecondsToAdd);

      return newDate;

    },
    calculateTimeLeft : function (endTime){
      let difference = +endTime - +new Date();
      let timeLeft = {};
      if (difference > 0) {
        timeLeft = {
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }
      return timeLeft;
    },
    compareDatesByDay:function(date1, date2) {
      const date1Year = date1.getFullYear();
      const date1Month = date1.getMonth();
      const date1Date = date1.getDate();
  
      const date2Year = date2.getFullYear();
      const date2Month = date2.getMonth();
      const date2Date = date2.getDate();
  
      if (date1Year !== date2Year) {
          return date1Year - date2Year;
      }
      if (date1Month !== date2Month) {
          return date1Month - date2Month;
      }
      return date1Date - date2Date;
  },
    getNotificationPopUp_obj:function (notifications){
      let byHour=[];
      let duringDay=[];
      let missedByhour=[];
      let timeToPopUp=false;
      notifications.forEach((not,index)=>{
        if(!not['isFinished']){  
          if(not.hasOwnProperty('byHour')){
            this.notificationValidation_ByTime(not,{isAbove:true}) ?  byHour.push(not) && duringDay.push(not) : missedByhour.push(not);
          }
          else{
            duringDay.push(not)
          }
        }
      })
      if(byHour.length>0 ){
        byHour.sort((a, b) => {
        if (a.byHour[0] < b.byHour[0]) {
          return -1;
        } else if (a.byHour[0] > b.byHour[0]) {
          return 1;
        } else {
          // If the first elements are equal, sort by the second elements
          if (a.byHour[1] < b.byHour[1]) {
            return -1;
          } else if (a.byHour[1] > b.byHour[1]) {
            return 1;
          } else {
            return 0;
          }
        }
        });
        let closestByHourNot=new Date();
        closestByHourNot.setHours(byHour[0]['byHour'][0]);
        closestByHourNot.setMinutes(byHour[0]['byHour'][1]);
        timeToPopUp = +closestByHourNot - +new Date();
      }
      // missedByhour.length>0 && missedByhour.sort((a, b) => {
      //   if (a.byHour[0] < b.byHour[0]) {
      //     return -1;
      //   } else if (a.byHour[0] > b.byHour[0]) {
      //     return 1;
      //   } else {
      //     // If the first elements are equal, sort by the second elements
      //     if (a.byHour[1] < b.byHour[1]) {
      //       return -1;
      //     } else if (a.byHour[1] > b.byHour[1]) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
      //   }
      // });
      return {
        byHour,
        duringDay,
        missedByhour,
        timeToPopUp
      }
    },
    notificationValidation_ByTime:(notification,{isExact=false,isAbove=false,isBelow=false,all=false})=>{
      const now=new Date();
      if(isExact && (now.getHours()==notification['byHour'][0] && now.getMinutes()==notification['byHour'][1]))return true;
      if(isAbove && (now.getHours()<notification['byHour'][0] || now.getHours()==notification['byHour'][0]) && now.getMinutes()<notification['byHour'][1]) return true;
      if(isBelow && (now.getHours()>notification['byHour'][0] || now.getHours()==notification['byHour'][0]) && now.getMinutes()>notification['byHour'][1]) return true;
      if(all)return true;
      return false;
    },
    getLatestHour:function(activeWeeklySchedule){
      let latestWorkDay=[0,0];
      let isNextday=false;
      for(const dailySchedule in activeWeeklySchedule.schedule){
        const daySchedule=activeWeeklySchedule.schedule[dailySchedule].slice(1);
        const {sumHours:daily_endWorkHour,gotToNextDay}=this.getDailyScheduleSumHours([...daySchedule,{hours:activeWeeklySchedule.schedule[dailySchedule][0].startWorkHour}],true);
        if(gotToNextDay===isNextday){
          if(daily_endWorkHour[0]>latestWorkDay[0]){
            latestWorkDay[0]=daily_endWorkHour[0];
            latestWorkDay[1]=daily_endWorkHour[1];
          }
          if(daily_endWorkHour[0]==latestWorkDay[0]&&daily_endWorkHour[1]>latestWorkDay[1])latestWorkDay[1]=daily_endWorkHour[1];
        }
        else{
          if(!gotToNextDay&&isNextday) continue;
          if(gotToNextDay&&!isNextday){
            latestWorkDay[0]=daily_endWorkHour[0];
            latestWorkDay[1]=daily_endWorkHour[1];
            isNextday=true;
          }
        }
      }

      if(latestWorkDay[1]>0){
        latestWorkDay[0]++;
        latestWorkDay[1]=0;
      }
      return latestWorkDay;
    },
    getEarliestHour:function(activeWeeklySchedule){
      let earliestHour=[];
      activeWeeklySchedule.schedule.forEach((daySchedule,index)=>{
        
        if(index===0) earliestHour=[...daySchedule[0].startDayHour];
        else if(index!=6){
          if(daySchedule[0].startDayHour[0]<earliestHour[0]){
            earliestHour=[...daySchedule[0].startDayHour];
            
          }
          if(daySchedule[0].startDayHour[0]==earliestHour[0]&&daySchedule[0].startDayHour[1]<earliestHour[1])earliestHour[1]=daySchedule[0].startDayHour[1];
        }
      })
      return earliestHour;
    },
    getSchedule_latestAndEarliest_hour:function(activeWeeklySchedule){
      let scheduleHours=  {earliestHour:this.getEarliestHour(activeWeeklySchedule),latestHour:this.getLatestHour(activeWeeklySchedule)};
      scheduleHours['sumHour']=(scheduleHours.latestHour[0]<scheduleHours.earliestHour[0] ? 24 : 0)+scheduleHours.latestHour[0]-scheduleHours.earliestHour[0];
      console.log(scheduleHours);
      return scheduleHours;
    },
    getHoursDifference:(fHours,sHours)=>{
      let hoursDiff=fHours[0]-sHours[0];
      let minsDiff=fHours[1]-sHours[1];
      if( minsDiff<0){
        hoursDiff--;
        minsDiff=60-minsDiff;
      }
      return [hoursDiff,minsDiff];

    },
    isEarlier:(fHours,sHours)=>{
    
      let hoursDiff=fHours[0]-sHours[0];
      let minsDiff=fHours[1]-sHours[1];
      let isErlier;
      if(hoursDiff<0) isErlier=true;
      if(minsDiff<0 && hoursDiff==0)isErlier=true;
      return  isErlier;

    },
    getProjectsYearlyTimeLine_obj:(projects,year)=>{
      const date=new Date();
      if(year) date.setFullYear(year);
      const byMonth = new Array(12).fill().map(()=>[]);
      let projectsSortedByMonth=projects.filter(project=>new Date(project.startDate).getFullYear()===date.getFullYear()).sort((a,b)=>new Date(a.startDate).getMonth()<new Date(b.startDate));
      projectsSortedByMonth.forEach(project=>{
        const projectStartDate=new Date(project.startDate);
        const projectFinishDate=new Date(project.finishDate);
        const monthDifference=projectFinishDate.getMonth()-projectStartDate.getMonth();
        const projUIData={...project,startMonth:projectStartDate.getMonth(),untillEndYear:projectStartDate.getFullYear()<projectFinishDate.getFullYear(),monthDifference};
        // on finish after year push to all month , if not push to (start month + moth diffrence ) number of months
        projectStartDate.getFullYear()<=projectFinishDate.getFullYear() ? byMonth.forEach((month,index)=> index>=projectStartDate.getMonth() && month.push(projUIData)) : byMonth.forEach((month,index)=> index>=projectStartDate.getMonth() && index<=projectStartDate.getMonth()+monthDifference && month.push(projUIData));
       

      })
      
      let positions = [];
      let projectsTimeLineData=[];
      byMonth.forEach((month, monthIndex) => {
        month.forEach((project) => {
          if (project.position === undefined) {
            // Find the first available position
            let position = positions.findIndex(pos => pos === null);
            if (position === -1) {
              position = positions.length;
            }
            // Assign the position to the project
            project.position = position;
            projectsTimeLineData.push(project);
            // maybe can take out
            // Update the project in all relevant months
            for (let i = monthIndex; i < 12; i++) {
              let projIndex = byMonth[i].findIndex(proj => proj.name === project.name);
              if (projIndex !== -1) {
                byMonth[i][projIndex].position = position;
              }
            }
            // Mark the position as occupied
            positions[position] = true;
          }
        });

        // Free up positions for projects that are not in the next month
        if (monthIndex < 11) {
          const nextMonthProjects = byMonth[monthIndex + 1].map(proj => proj.name);
          byMonth[monthIndex].forEach(project => {
            if (!nextMonthProjects.includes(project.name)) {
              positions[project.position] = null;
            }
          });
        }
      });
      return projectsTimeLineData;
    },
    formatDate:(sDate)=>{
      const date=new Date(sDate);
       // Get day, month, and year from the Date object
        let day = date.getDate();
        let month = date.getMonth() + 1; // Months are zero-based, so add 1
        let year = date.getFullYear();

        // Add leading zero to day and month if they are less than 10
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        // Return the formatted date string
        return `${day}/${month}/${year}`;
    },
    rechartGetNext12Months:function(startMonth){
     
      const months = [];
      for (let i = 0; i < 12; i++) {
        const monthIndex = (startMonth + i) % 12;
        months.push({ ...this.rechartMonthTemplate[monthIndex] });
      }
    
      return months;
    
      
    },
    rechartGetNext6Months:function(startMonth){
     
      const months = [];
      for (let i = 0; i < 6; i++) {
        const monthIndex = (startMonth + i) % 12;
        months.push({ ...this.rechartMonthTemplate[monthIndex] });
      }
    
      return months;
    
      
    },
    getIncomeExpensesReport:(sumIncomes,sumExpenses)=>{
      let sumIncExpes=[
          {
              coin:'Dollar',
              income:0,
              expense:0,
          },
          {
              coin:'Shekel',
              income:0,
              expense:0,
          },
          {
              coin:'Euro',
              income:0,
              expense:0,
          },
      ]
  
      sumIncomes.forEach(income => {
          switch (income.coin) {
              case 'dollar':
                  sumIncExpes[0].income+=parseFloat(income.averagePaycheck)
                  break;
              case 'shekel':
                  sumIncExpes[1].income+=parseFloat(income.averagePaycheck)
                  break;
              case 'euro':
                  sumIncExpes[2].income+=parseFloat(income.averagePaycheck)
                  break;
              default:
                  break;
          }
      });
      sumExpenses.forEach(expense => {
          switch (expense.coin) {
              case 'dollar':
                  sumIncExpes[0].expense+=parseFloat(expense.cost)
                  break;
              case 'shekel':
                  sumIncExpes[1].expense+=parseFloat(expense.cost)
                  break;
              case 'euro':
                  sumIncExpes[2].expense+=parseFloat(expense.cost)
                  break;
              default:
                  break;
          }
      });
      return sumIncExpes;
    },
    getIncomeExpensesReport_byCurrency:(coin,sumIncomes,sumExpenses,currenciesRatio)=>{
      const reportByCurrency={
          coin: coin,
          income:0,
          expense:0,
          investment:0,
      }
      sumIncomes.forEach(income => {
          reportByCurrency.income+=parseFloat(income.averagePaycheck*currenciesRatio[income.coin][reportByCurrency.coin])
      });
      sumExpenses.forEach(expense => {
          if(expense.type!='investment'){
            reportByCurrency.expense+=parseFloat(expense.cost*currenciesRatio[expense.coin][reportByCurrency.coin])
          }
          else reportByCurrency.investment+=parseFloat(expense.cost*currenciesRatio[expense.coin][reportByCurrency.coin])
      });
      return reportByCurrency;
    },
    getTasksWithinTimePeriod:(byHours, tasks)=> {
      const [hours, minutes] = byHours;
      const totalTimeInMinutes = hours * 60 + minutes;
      let timeRemaining = totalTimeInMinutes;
      let resultTasks = [];
      for (const task of tasks) {
          const taskDuration = task.duration;
  
          if (taskDuration <= timeRemaining) {
              resultTasks.push(task);
              timeRemaining -= taskDuration;
          } else {
              timeRemaining>0 && resultTasks.push(task);
              timeRemaining -= taskDuration;
              break;
          }
      }
  
      return {resultTasks,byHoursFormat:[Math.floor(timeRemaining / 60),timeRemaining%60],timeRemaining};
    },
    splitArrays: function(arrays) {
      const totalLength = arrays.reduce((sum, arr) => sum + arr.length, 0);
      let bestSplit = [[], []];
      let closestDifference = Infinity;

      function backtrack(index, subArray1, subArray2) {
          // Calculate current lengths
          const len1 = subArray1.reduce((sum, arr) => sum + arr.length, 0);
          const len2 = subArray2.reduce((sum, arr) => sum + arr.length, 0);

          // Check if we have a closer split
          const currentDifference = Math.abs(len1 - len2);
          // Update best split only if the lengths are not both zero
          if (len1 > 0 || len2 > 0) {
              if (currentDifference < closestDifference) {
                  closestDifference = currentDifference;
                  bestSplit = [subArray1.slice(), subArray2.slice()];
              }
          }

          // Stop if we've gone through all arrays
          if (index === arrays.length) return;

          // Include current array in subArray1
          subArray1.push(arrays[index]);
          backtrack(index + 1, subArray1, subArray2);
          subArray1.pop();

          // Include current array in subArray2
          subArray2.push(arrays[index]);
          backtrack(index + 1, subArray1, subArray2);
          subArray2.pop();
      }

      backtrack(0, [], []);
      return bestSplit;
  },
};
store.formKeys=store.formKeys.bind(store);
store.formatMinutesToHHMM=store.formatMinutesToHHMM.bind(store);

export default store;





