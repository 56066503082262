import {call,put,takeLatest,all,select} from "redux-saga/effects";
import api from "../../api";
import{SET_TASK_FINISH} from '../types/onTaskTypes';
import {setFinishTaskSuccess,setFinishTaskFailure} from '../actions/onTaskActions';
import {setValueByKeyAndId,setFinishTasksToday} from '../actions/userInfoActions';
import {getOnTask} from '../selectors';
import localStorageUtil from "../../localStorageUtil";
function* setTaskFinish_Saga(){
    try{
        const onTask=yield select(getOnTask);
        const response=yield call(api.post,'task','finish-task',onTask);
        if(response && response.ok){
            onTask.status===1 && localStorageUtil.insertID('task',onTask['_id']);
            yield put(setFinishTasksToday());
            yield put(setValueByKeyAndId('task',onTask['_id'],{ startTime: onTask.startTime,endTime: onTask.endTime,extraTime: onTask.extraTime,tookBreaks: onTask.tookBreaks,}));
            yield put(setFinishTaskSuccess());
        }
    }
    catch(err){
        console.log(err);
        yield put(setFinishTaskFailure());
    }
}

function* watchSetFinishTask(){
    yield takeLatest(SET_TASK_FINISH,setTaskFinish_Saga)
}

export default function* onTaskSaga(){
    yield all([watchSetFinishTask()])
}