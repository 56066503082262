
import { all } from 'redux-saga/effects';
import authSaga from "./authSaga";
import userInfoSaga from "./userInfoSaga";
import onTaskSaga from './onTaskSaga';




export default function* rootSaga(){
    yield all([authSaga(),userInfoSaga(),onTaskSaga()])
}