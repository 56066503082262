import { useEffect,useState,cloneElement } from 'react';
import './PageSlider.css' 
const PageSlider = ({pages,classes}) => {
    const [pagesView,setPagesView]=useState(0);

    const handleSubPageChange=e=>{
        // on adding more li add to css class pages-container width+=100
        const element = e.currentTarget;
        const parent = element.parentNode;
        const childNodes = Array.from(parent.children);
        const position = childNodes.indexOf(element);
        childNodes.forEach((child) => {
            if (child.classList.contains('nav_chosen')) {
              child.classList.remove('nav_chosen');
            }
          });
      
          // Add the 'nav_chosen' class to the clicked element
          element.classList.add('nav_chosen');
          setPagesView(position)
    }
    
    return (
        <div className={`pageslider-container ${classes && classes}`}>
            <nav>
                <ul className="pageslider-nav">
                    {pages.map((page,index)=> <li onClick={handleSubPageChange} className={index==0 && 'nav_chosen'}> {typeof page.name !== 'object' ? page.name : page.name.icon()} </li>)}
                </ul>
            </nav>
            <div className='pages-container' style={{right:`${pagesView*100}%`,width:`${pages.length}00%`}}>
                {pages.map(page=> 
                        <div className='page-container'>
                            <h3 className='pageHeading'>{page.hasOwnProperty('heading') ? page.heading : 'No Heading'}</h3>
                            {page.hasOwnProperty('subHeader') &&<h2 className='pageSubHeader'> {page.subHeader}</h2>}
                            {page.component()}
                        </div>
                        )}
            </div>
        </div>
    );
};

export default PageSlider;