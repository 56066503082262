import  {NavLink} from "react-router-dom";
import {connect} from 'react-redux';

const NavHeader = ({isAdmin}) => {
    return (
      <nav dir="rtl">
        <ul className="navbar">
          <NavLink to="/"><li className="nav-item">דף בית</li></NavLink>
          <NavLink to="/projects"><li className="nav-item">פרויקטים</li></NavLink>
          <NavLink to="/ideas"><li className="nav-item">רעיונות</li></NavLink>
          <NavLink to="/personal"><li className="nav-item">איזור אישי</li></NavLink>
          {isAdmin && <NavLink to="/admin">Admin Panel</NavLink>}
        </ul>
      </nav>
    )
  }
const mapStateToProps = (state) => {
return {
    isAdmin: state.authUser.hasOwnProperty('isAdmin'),
  };
};


export default connect(mapStateToProps)(NavHeader);
  