import Tasks from "../Tasks";
import {connect} from 'react-redux';
import { useState,useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import TocIcon from '@mui/icons-material/Toc';
import {InfoHover,Form} from "..";
import Popup from 'reactjs-popup';
import ProjectStepTaskOrganizer from './ProjectStepTaskOrganizer';
import {BucketOfWater} from '../';
import store from "../../store";

const totalHours=(duration)=>{

}

const ProjectStep=({projectId,projectStepId,index,tasks,getProjectStepInfo})=>{
    const [popOn,setPopOn]=useState(false);
    const projectStep=getProjectStepInfo(projectStepId);
    const [sortList,setSortList]=useState([]);
    const [formParams,setFormParams]=useState(false);
    const [totalDuration,setTotalDuration]=useState(0);
    const openForm=()=>{
      setFormParams(['task','POST']);
    }
    useEffect(()=>{
      let sortedByOrder=tasks.filter((task) => task.projectStepId === projectStepId && task.status!==1 );
      sortedByOrder.sort((a,b)=>a.order-b.order);
      setSortList(sortedByOrder);
  },[tasks]);
    useEffect(()=>{
      if(!popOn){
        let sortedByOrder=tasks.filter((task) => task.projectStepId === projectStepId && task.status!==1 );
        sortedByOrder.sort((a,b)=>a.order-b.order);
        setSortList(sortedByOrder);
      }
  },[popOn]);

    useEffect(()=>{
      let sumD=0;
      sortList.forEach(ts=>sumD+=ts.duration);
      setTotalDuration(sumD)
    },[sortList])
    return (
      <> 
        { projectStep &&  
          <div className="projectStep-container element-boxShadow">
            <div className="flex-even ps_quick_add-container">
              <h1>{`${projectStep.name}(${store.displayHours([Math.floor(totalDuration / 60),totalDuration%60])})`}</h1>
              <InfoHover info='Quick Task Add' classes={'quick_add_icon task flex-mid click'} children={<AddIcon/>} onClick={openForm}  />
              <InfoHover info='Order Tasks' classes={'quick_add_icon task flex-mid click'} children={<TocIcon/>} onClick={()=>setPopOn(true)}  />
              <BucketOfWater bucketStyle={{height:'50px',width:'25px'}} validIdArr={[projectStepId]} showPercentage />
            </div>
              
              {formParams && <Form dbtype={'task'} method='POST' setFormMethod={setFormParams} quickAddData={{projectId,projectStepId}} />}
              {popOn && 
                  <Popup open={popOn}>
                      <ProjectStepTaskOrganizer dbtype={'task'} parentIdKey={'projectStepId'} setPopOn={setPopOn} filterId={projectStepId} name={getProjectStepInfo.name}/>
                  </Popup>
              }
              {sortList.length > 0 && <Tasks projectStepId={projectStepId} tasks={sortList}/>}
        </div> 
        }
  
    </>
    )
}
const mapStateToProps = (state) => {
    return {
        tasks: state.userInfo.task,
        getProjectStepInfo:(projectStepId)=>state.userInfo.projectStep.find(currentProjectStep=>currentProjectStep._id===projectStepId)
      };
    };
    const mapDispatchToProps = (dispatch) => {
      return {
       
      };
    };
export default connect(mapStateToProps,mapDispatchToProps)(ProjectStep);