const localStorageUtil = {
    getByDbType: function (dbtype) {
        const savedData = localStorage.getItem(dbtype);
        if (savedData) {
            const { data, expiration } = JSON.parse(savedData);
            // Check if the data has expired
            if (Date.now() <= expiration) {
                return data;
            } else {
                // If expired, clear localStorage and return empty array
                localStorage.removeItem(dbtype);
                return [];
            }
        } else {
            return [];
        }
    },
    saveData: function (dbtype, data, expiration) {
        const savedData = JSON.stringify({ data, expiration });
        localStorage.setItem(dbtype, savedData);
    },
    insertID: function (dbtype, newID) {
        const savedData = this.getByDbType(dbtype);
        savedData.push(newID);
        const expiration = new Date();
        expiration.setHours(23, 59, 59, 999); // Set expiration to end of current day (23:59:59.999)
        this.saveData(dbtype, savedData, expiration.getTime());
    },
    idExists: function (dbtype, id) {
        const savedData = this.getByDbType(dbtype);
        return savedData.includes(id);
    },
    reset:function(dbtype){
        localStorage.removeItem(dbtype);
        return true;
    }
};

export default localStorageUtil;