const Idea=({ideaInfo})=>{
    return (
        <div className="idea-container container-boxShadow">
            <div className="idea-header-wrapper">
                <h4>{ideaInfo.name}</h4>
            </div>
            <div className="idea-description-wrapper">
                <h5>{ideaInfo.description}</h5>
            </div>
            <div className="notes-container">
                <ul>
                {ideaInfo.notes && ideaInfo.notes.length>0 ? 
                ideaInfo.notes.map((note,index)=><li key={index} className="note-wrapper">{note}</li>)
                :
                <li className="note-wrapper">No Notes</li>
                }
                </ul>
            </div>
        </div>
    )
}

export default Idea;