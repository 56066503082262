const taxes={
     taxBrackets : [
        {
          rate: '10',
          monthly: {
            from: 0,
            upto: 7010
          },
          yearly: {
            from: 0,
            upto: 84120
          }
        },
        {
          rate: '14',
          monthly: {
            from: 7011,
            upto: 10060
          },
          yearly: {
            from: 84121,
            upto: 120720
          }
        },
        {
          rate: '20',
          monthly: {
            from: 10061,
            upto: 16150
          },
          yearly: {
            from: 120721,
            upto: 193800
          }
        },
        {
          rate: '31',
          monthly: {
            from: 16151,
            upto: 22440
          },
          yearly: {
            from: 193801,
            upto: 269280
          }
        },
        {
          rate: '35',
          monthly: {
            from: 22441,
            upto: 46690
          },
          yearly: {
            from: 269281,
            upto: 560280
          }
        },
        {
          rate: '47',
          monthly: {
            from: 46691,
            upto: 60130
          },
          yearly: {
            from: 560281,
            upto: 721560
          }
        },
        {
          rate: '50',
          monthly: {
            from: 60131,
            upto: Infinity
          },
          yearly: {
            from: 721561,
            upto: Infinity
          }
        }
      ],
    getAfterTaxes:function (moneyToCalculate,yearly=false){
        let totalTax = 0;
    
        // Find the applicable tax bracket
        for (let i = 0; i < this.taxBrackets.length; i++) {
          const bracket = this.taxBrackets[i];
          // Check if the income falls within the bracket range
          if (yearly) {
            if (moneyToCalculate > bracket.yearly.from && (moneyToCalculate <= bracket.yearly.upto || bracket.yearly.upto === Infinity)) {
                totalTax += (moneyToCalculate - bracket.yearly.from) * (parseInt(bracket.rate) / 100);
                break;
            }
            totalTax += (bracket.yearly.upto - bracket.yearly.from) * (parseInt(bracket.rate) / 100);
          } 
          else {
            if (moneyToCalculate > bracket.monthly.from && (moneyToCalculate <= bracket.monthly.upto || bracket.monthly.upto === Infinity)) {
                totalTax += (moneyToCalculate - bracket.monthly.from) * (parseInt(bracket.rate) / 100);
                break;
            }
            totalTax += (bracket.monthly.upto - bracket.monthly.from) * (parseInt(bracket.rate) / 100);
          }
        }


        // Calculate after-tax moneyToCalculate
        const afterTaxIncome = moneyToCalculate - totalTax;


        return afterTaxIncome ; // Convert back to monthly if necessary
    
   
      
    },
}



export default taxes;