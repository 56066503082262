import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField} from '@mui/material'
import { LoadingButton } from '@mui/lab';
import {connect} from 'react-redux';
import moment from 'moment';
import {setNotificationsPopUpPeriodic} from '../../redux/actions/userInfoActions'; 
import { useState } from 'react';

const Notifications = ({ notificationGroups, notifications , month,notificationPopUpPeriodic,missedNotificationPopUpPeriodic,setPeriodic }) => {
  const [poppPer,setPoppPer]=useState(notificationPopUpPeriodic);
  const [missed,setMissed]=useState(missedNotificationPopUpPeriodic);
  const updateNotPopPer=()=>{
    setPeriodic(Number(poppPer),Number(missed));
  }
    return (
      <div className='notifications_window-container'>
        <div className='popUp_inputs-container'>
          <TextField
            margin="normal"
          required
          color="secondary"
          fullWidth
          id="popup"
          type='number'
          label="PopUp Periodic"
          name="popup"
          value={poppPer}
          onChange={(e)=>setPoppPer(e.target.value)}
          onBlur={()=>poppPer==='' && setPoppPer(notificationPopUpPeriodic)}
          sx={{
              "& input": {
                  bgcolor:'whitesmoke'
                },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "1rem",
                "&.Mui-focused": {
                  color: "black",
                }
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.8rem",
              },
            }}    
          />
          <TextField
            margin="normal"
            required
            color="secondary"
            fullWidth
            type='number'
            id="popup"
            label="PopUp Missed By Time Periodic"
            name="popup"
            value={missed}
            onChange={(e)=>setMissed(e.target.value)}
            onBlur={()=>missed==='' && setMissed(missedNotificationPopUpPeriodic)}
            sx={{
                "& input": {
                    bgcolor:'whitesmoke'
                  },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "1rem",
                  "&.Mui-focused": {
                    color: "black",
                  }
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.8rem",
                },
            }}
          />
        </div>
        <div className='submit-wrapper'>
            <LoadingButton loading={false} type='submit' variant="contained" color='secondary' onClick={updateNotPopPer}>Update</LoadingButton>
        </div>
        <div className='notifications-container'>
          {notificationGroups.length > 0 &&
            notificationGroups.map((notificationGroup) => (
              <div key={notificationGroup._id}>
                  <h2>{notificationGroup.name}</h2>
                  <TableContainer component={Paper}>
                      <Table size='small'>
                          <TableHead>
                          <TableRow key={notificationGroup._id}>
                              <TableCell>notificationGroup Notifications</TableCell>
                              <TableCell align="right">Description</TableCell>
                              <TableCell align="right">Type</TableCell>
                              <TableCell align="right">Hour</TableCell>
                              <TableCell align="right">Finish Date</TableCell>
                              <TableCell align="right">Completed</TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          {notifications
                              .filter((notification) => notification.notificationGroupId === notificationGroup._id)
                              .map((filterednotification) => (
                              <TableRow key={filterednotification.id}>
                                  <TableCell>{filterednotification.name}</TableCell>
                                  <TableCell align="right">{filterednotification.description}</TableCell>
                                  <TableCell align="right">{filterednotification.type}</TableCell>
                                  <TableCell align="right">{filterednotification.byHour ? `${filterednotification.byHour[0].toString().padStart(2,'0')}:${filterednotification.byHour[1].toString().padStart(2,'0')}` : 'Till End Of Day'}</TableCell>
                                  {/* <TableCell align="right">{filterednotification.finishDate ? '': 'Today By Hour'}</TableCell> */}
                                  <TableCell align="right">
                                      {(()=>{
                                          switch (filterednotification.type) {
                                              case 'daily':
                                                return <TableCell key={filterednotification.type} align="right">Today By Hour</TableCell>;
                                              case 'weekly':
                                                return <TableCell key={filterednotification.type} align="right">{moment().day(filterednotification.finishDate).format('dddd')}</TableCell>
                                              case 'monthly':
                                                return <TableCell key={filterednotification.type} align="right">{moment().day(filterednotification.finishDate).format('dddd')}</TableCell>
                                              case 'yearly':
                                                return <TableCell key={filterednotification.type} align="right">{moment().day(filterednotification.finishDate).format('dddd')}</TableCell>
                                              case 'once':
                                                return <TableCell key={filterednotification.type} align="right">{moment().day(filterednotification.finishDate || new Date()).format('dddd')}</TableCell>
                                              default:
                                                return <TableCell key={filterednotification.type} align="right">Weird Shit</TableCell>
                                            }
                                      })()}
                                  </TableCell>
                                  <TableCell align="right">{filterednotification.isFinished}</TableCell>
                              </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </div>
            ))}
          
        </div>
      </div>
    );
  };
  
  
const mapStateToProps = (state) => {
    return {
      notificationGroups: state.userInfo.notificationGroup,
      notifications:state.userInfo.notification,
      notificationPopUpPeriodic:state.userInfo.notificationPopUpPeriodic,
      missedNotificationPopUpPeriodic:state.userInfo.missedNotificationPopUpPeriodic,
      month:(moment().month()+1).toString().padStart(2,'0')
    };
  };


const mapDispatchToProps = (dispatch) => {
  return {
      setPeriodic:(popUp,missedByHour)=>dispatch(setNotificationsPopUpPeriodic({notificationPopUpPeriodic:popUp,missedNotificationPopUpPeriodic:missedByHour})),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Notifications);