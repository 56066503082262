import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Workout = ({workoutPlans,notificationGroups,notifications}) => {
    const today = moment();
    const daysArray = [];
    const activeWorkoutPlan={...(workoutPlans.filter(workoutPlan=>workoutPlan.isActive)[0])}
    const rotationSet = new Set(activeWorkoutPlan.rotation);
    const rotationArray = Array.from(rotationSet).map(value=>value);
    for (let i = 0; i < 7; i++) {
      const day = today.clone().add(i, 'days');
      daysArray.push(day.format('dddd'));
    }
    return (
        <div className='workout-container' dir='ltr'>
            <div className='active-container'>
                <h2>Active Workout: {activeWorkoutPlan.name}</h2>
                {rotationArray.map(value=> value!=='R' &&
                 <div className='rotationIfno'>
                    {value} : {activeWorkoutPlan.rotationInfo[value].map(bodyPart=><span>{bodyPart},</span>)}
                </div>)}
            </div>
            <div className='days-container'>
                {activeWorkoutPlan.hasOwnProperty('rotation') ? 
                    daysArray.map((day,index)=>
                        <div>
                            {activeWorkoutPlan.rotation[index]}<br/>{day}
                        </div>
                    )
                    :
                    <div>
                        Activate Workout To View 
                    </div>
            }
            </div>
            {/* <div className='workoutNot-container'>
                {notificationGroups.map((notificationGroup)=>{
                    if(notificationGroup.workoutId===activeWorkoutPlan._id){
                        return (
                            <div>
                                <h2>{notificationGroup.name}</h2>
                                <TableContainer component={Paper}>
                                    <Table size='small'>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>notificationGroup Notifications</TableCell>
                                            <TableCell align="right">Description</TableCell>
                                            <TableCell align="right">Type</TableCell>
                                            <TableCell align="right">Hour</TableCell>
                                            <TableCell align="right">Finish Date</TableCell>
                                            <TableCell align="right">Completed</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {notifications.map(notification=>{
                                                if(notification.notificationGroupId===notificationGroup._id){
                                                    return (
                                                        <TableRow>
                                                            <TableCell>{notification.name}</TableCell>
                                                            <TableCell>{notification.description}</TableCell>
                                                            <TableCell>{notification.type}</TableCell>
                                                            <TableCell>{notification.byHour ? `${notification.byHour[0].toString().padStart(2,'0')}:${notification.byHour[1].toString().padStart(2,'0')}` : 'Till End Of Day'}</TableCell>
                                                            <TableCell align="right">
                                                                {(()=>{
                                                                    switch (notification.type) {
                                                                        case 'daily':
                                                                        return <TableCell align="right">Today By Hour</TableCell>;
                                                                        case 'weekly':
                                                                        return <TableCell align="right">{moment().day(parseInt(notification.finishDate)).format('dddd')}</TableCell>
                                                                        case 'monthly':
                                                                        return <TableCell align="right">{moment().day(parseInt(notification.finishDate)).format('dddd')}</TableCell>
                                                                        case 'yearly':
                                                                        return <TableCell align="right">{moment().day(parseInt(notification.finishDate)).format('dddd')}</TableCell>
                                                                        case 'once':
                                                                        return <TableCell align="right">{moment().day(parseInt(notification.finishDate)).format('dddd')}</TableCell>
                                                                        default:
                                                                        return <TableCell align="right">Weird Shit</TableCell>
                                                                    }
                                                                })()}
                                                            </TableCell>
                                                            <TableCell align="right">{notification.isFinished}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )
                    }
                })}
            </div> */}
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        workoutPlans: state.userInfo.workoutPlan,
        notificationGroups:state.userInfo.notificationGroup,
        notifications:state.userInfo.notification
    };
  };
export default connect(mapStateToProps)(Workout);