export const LOGIN_REQUEST='LOGIN_REQUEST';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAILURE='LOGIN_FAILURE';

export const IS_AUTH_REQUEST='IS_AUTH_REQUEST';
export const IS_AUTH_SUCCESS='IS_AUTH_SUCCESS';
export const IS_AUTH_FAILURE='IS_AUTH_FAILURE';

export const REGISTER_REQUEST='REGISTER_REQUEST';
export const REGISTER_SUCCESS='REGISTER_SUCCESS';
export const REGISTER_FAILURE='REGISTER_FAILURE';

export const LOGOUT_REQUEST='LOGOUT_REQUEST';
export const LOGOUT_SUCCESS='LOGOUT_SUCCESS';
export const LOGOUT_FAILURE='LOGOUT_FAILURE';

export const GOT_USER_DATA='GOT_USER_DATA'
