import {SET_TASK_START,SET_TASK_EXTRA_TIME,SET_TASK_BREAK,SET_TASK_RETURN_BREAK,
    SET_TASK_FINISH,SET_TASK_FINISH_SUCCESS,SET_TASK_FINISH_FAILURE} from '../types/onTaskTypes';
import store from '../../store';

const initializeOnTask={
    name:false,
    _id:false,
    duration:false,
    startTime:false,
    endTime:false,
    extraTime:false,
    breakStart:false,
    tookBreaks:false,
    status:-1,
}


export default function reducer(state=initializeOnTask,action){
    switch (action.type) {
        case SET_TASK_START:
            return {...state,...action.payload,startTime:new Date(),endTime:store.getEndtimeByDuration(action.payload.duration),status:0};
        case SET_TASK_BREAK:
            return {...state,breakStart:new Date(),tookBreaks:true};
        case SET_TASK_RETURN_BREAK:
            // Calculate the time I was on a break
            let timeOnBreak = new Date().getTime() - state.breakStart.getTime();
            let newEndTime=new Date();
            newEndTime.setTime(state.endTime.getTime() + timeOnBreak);
            return {...state,breakStart:false,endTime:newEndTime};
        case SET_TASK_EXTRA_TIME:
            if(action.payload===''){
                return {...state,extraTime:false}
            }
            else{
                return {...state,extraTime:Number(action.payload)}
            }

        case SET_TASK_FINISH:
            // return {name:false,_id:false,duration:false,startTime:false,endTime:false,extraTime:false,breakStart:false,tookBreaks:false,status:-1,}
            return {...state,status:action.payload};
        case SET_TASK_FINISH_SUCCESS:
            return {name:false,_id:false,duration:false,startTime:false,endTime:false,extraTime:false,breakStart:false,tookBreaks:false,status:-1}
        case SET_TASK_FINISH_FAILURE:
            return state;
        default:
            return state;
    }
}