import { combineReducers } from 'redux';

import authReducer from "./authReducer";
import userInfoReducer from './userInfoReducer';
import onTaskReducer from './onTaskReducer';


const rootReducer=combineReducers({
    authUser:authReducer,
    userInfo:userInfoReducer,
    onTask:onTaskReducer
})

export default rootReducer;