import React, { useState,useEffect} from 'react';
import {connect} from 'react-redux';
import store from '../store';
import  Draggable  from 'react-draggable';
import Popup from 'reactjs-popup';
import { Switch,TextField } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import TimerHook from './TimerHook';

import{setBreakTask,setReturnBreakTask,setExtraTimeTask,setFinishTask} from '../redux/actions/onTaskActions';


const TaskTimer = ({onTask,finishTask,NextTask,startTaskBreak,returnTaskBreak,setExtraTime}) => {
    const [fixedPosition,setFixedPosition]=useState(true);
    const [onBreak,setOnBreak]=useState(false);
    const [extraMins,setExtraMins]=useState(onTask.extraTime ? onTask.extraTime : '');
    const [popOn,setPopOn]=useState(false);

    const handleFixedPosition=()=>{
        fixedPosition===false && document.getElementById('task_timer-container').removeAttribute('style')
        setFixedPosition(!fixedPosition);
    }
    const handleOnBreakChange=()=>{
        onBreak ? returnTaskBreak() : startTaskBreak();
        setOnBreak(!onBreak);
    }
    useEffect(()=>{
        setExtraMins(onTask.extraTime ? onTask.extraTime : '');
    },[onTask.extraTime])
    return (
     
            <Draggable disabled={fixedPosition}>
                <div id='task_timer-container' className={`task_timer-container ${fixedPosition && 'fixed'}`}>
                    <div className='task_timer-header-container'>
                        <div>
                            <span>Fixed Position</span>
                            <Switch color='success' checked={fixedPosition} onChange={handleFixedPosition}/>
                        </div>
                        <div className='task_timer-options-container'>
                            <div>
                                <span>Set Break</span>
                                <Switch checked={onBreak} onChange={handleOnBreakChange} color='warning'/>
                            </div>
                            <div>
                                <span>Set Finish</span>
                                <LoadingButton onClick={finishTask}   variant="contained" color='success'>Finish</LoadingButton>
                            </div>
                            <div>
                                <span>Next Task</span>
                                <LoadingButton onClick={NextTask}   variant="contained" color='warning'>Finish Later</LoadingButton>
                            </div>
                            <div>
                                <span>Add Extra Time</span>
                                <LoadingButton  variant="contained" color='info' onClick={()=>setPopOn(true)} >Extra Time</LoadingButton>
                                <Popup open={popOn} closeOnDocumentClick onClose={()=>setPopOn(false)}>
                                    <div className='extra_time_input-wrapper container-boxShadow'>
                                    <TextField
                                        label="Minutes"
                                        type="number"
                                        name='mins'
                                        value={extraMins}
                                        onChange={(e)=>setExtraMins(e.target.value)}
                                        inputProps={{
                                        min: 0,
                                        max: 59,
                                        step: 1,
                                        }}
                                        sx={{
                                            "& input": {
                                                width: "7ch", // Set width to 3 characters
                                                color:'white'
                                            },
                                            "& label": {
                                                left: "unset",
                                                right: "1.75rem",
                                                transformOrigin: "right",
                                                fontSize: "0.8rem",
                                                color:'white',
                                            },
                                            "& legend": {
                                                textAlign: "right",
                                                fontSize: "0.6rem",
                                            },
                                            }}
                                    />
                                    <LoadingButton onClick={()=>setExtraTime(extraMins)} variant="contained" color='info'>Add Extra Time</LoadingButton>
                                    </div>
                                </Popup>
                            </div>

                        </div>
                    </div>
                    <TimerHook extraTimeEl={()=>setPopOn(true)} />
                </div>
            </Draggable>
      
    );
};


const mapStateToProps = (state) => {
    return {
        onTask:state.onTask,
        isTaskDone:(taskId)=>state.userInfo.tasks.find(task=>task['_id']===taskId)
      };
    };
const mapDispatchToProps = (dispatch) => {
    return {
        finishTask:()=>dispatch(setFinishTask(1)),
        NextTask:()=>dispatch(setFinishTask(0)),
        startTaskBreak:()=>dispatch(setBreakTask()),
        returnTaskBreak:()=>dispatch(setReturnBreakTask()),
        setExtraTime:(duration)=>dispatch(setExtraTimeTask(duration)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(TaskTimer);