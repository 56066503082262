import React from 'react';
import {connect} from 'react-redux';
import  Draggable  from 'react-draggable';
import { LoadingButton } from '@mui/lab';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessibleIcon from '@mui/icons-material/Accessible';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import api from '../api';
// change from drag to popup 
const WorkoutDone = ({activeWorkoutPlan}) => {
    const handleClick=(type)=>{
        if(type){
            api.post('workout','finish-workout')
            .then(res=>{
                if(res.ok){

                }
            })
            .catch(err=>console.log(err))
        }
    }
    return (
        <Draggable positionOffset={{x:'5px',y:'30px'}}>
            <div className='workout_done-container pos_abs container-boxShadow notifications_popup-container during_day'>
                <header>
                    <div>Workout Check</div>
                    Have You Done The Fucking SHIT?!
                </header>
                <div className='flex-even mid options'>
                        <LoadingButton variant="contained" color='success'>FUCK YEA <FitnessCenterIcon/> </LoadingButton>
                        <LoadingButton variant="contained" color='warning'> Later <AccessibleForwardIcon/> </LoadingButton>
                        <LoadingButton variant="contained" color='error'>FUCK NAH <AccessibleIcon/> </LoadingButton>
                </div>
            </div>
        </Draggable>
    );
};
const mapStateToProps = (state) => {
    return {
        activeWorkoutPlan: state.userInfo.workoutPlan && state.userInfo.workoutPlan.find(ws=>ws.isActive),
    };
};

export default connect(mapStateToProps)(WorkoutDone);