import { useEffect,useState } from 'react';
import {BasicTable} from '../';
function extractSpecificKeys(objectsArray, keys) {
    return objectsArray.map(object => {
      const newObj = {};
      keys.forEach(key => {
        if (object.hasOwnProperty(key)) {
          newObj[key] = object[key];
        }
      });
      return newObj;
    });
  }
function capitalizeFirstCharOfWords(str) {
  return str.split(' ') // Split the string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter and concatenate with the rest
    .join(' '); // Join the words back into a single string
}
const Expenses = ({expenses}) => {

  
    const headCells = [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'cost',
            label: 'Cost',
        },
        {
          key: 'billingMonthDay',
          label: 'Billing date',
        },
        {
          key: 'coin',
          label: 'Coin',
        },
     
      ];
      const rows=extractSpecificKeys(expenses,['name','cost','billingMonthDay','coin']);
      const [tableData,setTableData]=useState({headCells,dataList:rows,header:`${capitalizeFirstCharOfWords(expenses[0].type)}`});
      useEffect(()=>{
        const rows=extractSpecificKeys(expenses,['name','cost','billingMonthDay','coin']);
        setTableData({...tableData,dataList:rows});
      },[expenses])
    return (
            <BasicTable tableData={tableData}/>
    );
};

export default Expenses;