import React,{useState} from 'react';
import Popup from 'reactjs-popup';
import { TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import taxes from '../../store/taxes';
const TaxCalc = ({state,setState}) => {

    const [money,setMoney]=useState(0);
    const [afterTax,setAfterTax]=useState(0);

    const handleClose=()=>{
        setState(false);
        setMoney(0)
        setAfterTax(0)
    }
    const handleChangeInMoney=(e)=>{
        setMoney(e.target.value);
        afterTax>0 && setAfterTax(0);
    }
    const calcTax=()=>{
        setAfterTax(taxes.getAfterTaxes(money));
    }
    return (
        <Popup open={state} closeOnDocumentClick onClose={handleClose}>
            <div className='container-boxShadow notifications_popup-container bg-white'>
                    <header>
                        <h3>Tax Calculator</h3>
                        <span style={{fontSize:'25px'}} onClick={handleClose} className="exit-form exit_form_abs">X</span>
                    </header>
                    <div className='flex-column mid '>
                        <TextField
                            label="Money To Calculate"
                            type="number"
                            value={money}
                            onChange={handleChangeInMoney}
                            inputProps={{
                            min: 0,
                            max: 23,
                            step: 1,
                            }}
                            sx={{
                                "& input": {
                                    width: "7ch", // Set width to 3 characters
                                },
                                "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "0.8rem",
                                },
                                "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.6rem",
                                },
                                }}
                        />
                        <LoadingButton onClick={calcTax} variant="contained" >Calculate After Tax</LoadingButton>
                        <h4>After Tax-{afterTax.toFixed(1)} ₪</h4>
                        <h4>Sum Tax-{afterTax > 0 ? (money-afterTax).toFixed(1) : '0.0'} ₪</h4>
                </div>
            </div>
        </Popup>
    );
};

export default TaxCalc;