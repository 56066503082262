import React,{ useState ,useEffect} from 'react';
import store from '../../store';
import {connect} from 'react-redux';
import {setBackEnd} from '../../redux/actions/userInfoActions'
import { LoadingButton } from '@mui/lab';
import { WidthProvider, Responsive } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);
const ProjectStepTaskOrganizer = ({dbtype,parentIdKey,filterId,setPopOn,name,dataList,setNewOrder}) => {

    const [organizeList,setOrganizeList]=useState([]);
    // const [layout,setLayout]=useState([]);
    const [enableSave,setEnableSave]=useState(false);
    const onLayOut=(updatedLayout)=>{

        let newOrganizeList = organizeList.map(item => {
            // Find the corresponding layout object by matching _id with i
            const layoutItem = updatedLayout.find(layout => layout.i === item._id);
            if (layoutItem) {
                // Update the order key
                return { ...item, order: Number(layoutItem.y) + 1 };
            }
            // If no matching layout item, return the item as is
            return item;
        }).sort((a,b)=>a.order-b.order);
    
        // Update the organizeList state
        setOrganizeList(newOrganizeList);
    

    }
    useEffect(()=>{
        let list=store.deepCopy(dataList(dbtype,filterId,parentIdKey)).sort((a,b)=>a.order-b.order);
        setOrganizeList(list);
        // let initLayout=list.map((step,index)=>({i:step['_id'],x: 0, y: index, w: 1, h: 1+index}));
        // setLayout(initLayout);
    },[])
    useEffect(()=>{
        setEnableSave(store.compareDataSameOrder(organizeList,store.deepCopy(dataList(dbtype,filterId,parentIdKey)).sort((a,b)=>a.order-b.order)) ? false :true);
    },[organizeList])
    // useEffect(()=>{
    //     console.log('layout')
    //     console.log(layout)
    // },[layout])
    const updateOrder=()=>{
        setPopOn(false);
        setNewOrder(dbtype,organizeList.map(obj=>({order:obj.order,_id:obj._id})))
    }
    return (
    
            <div className='projectStepTaskOrganizer-container form-container container-boxShadow'>
                <header className='txt_center cursor_def'>
                     <h4>Organize-{name && name}</h4> 
                    <span style={{fontSize:'25px'}} onClick={()=>setPopOn(false)} className="exit-form exit_form_abs">X</span>
                </header>
                <div className='flex-even mid pad'>
                    <LoadingButton onClick={updateOrder} className={`${!enableSave && 'cursor_def'}`}  style={{backgroundColor:enableSave ?`rgb(0, 128, 255)` : 'grey',color:'white'}} variant="contained" >Save Changes</LoadingButton>
                </div>
                <ResponsiveGridLayout onLayoutChange={onLayOut} style={{color:"black"}}  className="layout" cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }} resizable={false}>
                    {organizeList.map((dataObj,index)=>
                        <div key={dataObj['_id']} className='organize_list_item-container cursor_def element-boxShadow'>
                            <span className={`quick_add_icon flex-mid ${dbtype==='task' && 'task'}`}>{dataObj.order}</span>
                            <div>
                                {dataObj.name}
                            </div>
                        </div>
                    )}
                </ResponsiveGridLayout>
            </div>
       
    );
};
const mapStateToProps = (state) => {
    return {
        dataList:(dbtype,filterId,parentIdKey)=>state.userInfo[dbtype].filter(dataObj=>dataObj[parentIdKey]===filterId && (dataObj.status === undefined || dataObj.status !== 1) ),
      };
    };
  const mapDispatchToProps = (dispatch) => {
    return {
        setNewOrder:(dbtype,data)=>{dispatch(setBackEnd(dbtype,'update-order','post',data))},
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(ProjectStepTaskOrganizer);
