import React from 'react';
import {WaitingList,UseDB,ResetNot} from '../components/admin'
const AdminPanel = () => {
    return (
        <div className="page-container">
            <h1>Admin Page</h1>
            <div className='flex-even mid extra'>
                <UseDB/>
                <ResetNot/>
            </div>
            <WaitingList/>
            
        </div>
    );
};

export default AdminPanel;