import {
    LOGOUT_SUCCESS,RESET_IS_ERROR,SET_BY_KEY,
    FETCH_DATA_REQUEST,FETCH_DATA_SUCCESS,FETCH_DATA_FAILURE,
    FETCH_USER_INFO,FETCH_USER_INFO_SUCCESS,FETCH_USER_INFO_FAILURE,
    SET_DATA_EXAMPLE,SET_DATA_EXAMPLE_SUCCESS,SET_DATA_EXAMPLE_FAILURE,
    SET_DATA_REQUEST,SET_DATA_SUCCESS,SET_DATA_FAILURE,SET_BY_KEY_AND_ID,SET_ORDER_BY_DBTYPE,
    SET_NOTIFICATIONS_POPUP_PERIODIC,SET_BY_KEY_FIXED,
    SET_BACKEND,SET_BACKEND_SUCCESS,SET_BACKEND_FAILURE,SET_SUCCESS_API,SET_TASKS_FINISH_TODAY
} from "../types/userInfoTypes";

const initializeUserInfoState={
        bank:false,
        card:false,
        expense:false,
        idea:false,
        notification:false,
        notificationGroup:false,
        project:false,
        projectStep:false,
        task:false,
        weeklySchedule:false,
        workoutPlan:false,
        income:false,
        paycheck:false,
        loader:false,
        currenciesRatio:false,
        isError:{
            status:null , 
            message:'' , 
            messageType:''
        },
        isExample:true,
        notificationPopUpPeriodic:30,
        missedNotificationPopUpPeriodic:10,
        finishTasksToday:false,
        liveBitcoin:-1,
}


export default function reducer(state=initializeUserInfoState,action){
    switch (action.type){

        case SET_SUCCESS_API:
            return{...state,isError:{...state.isError,status:false,message:action.payload},loader:false};

        case FETCH_DATA_REQUEST:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case FETCH_DATA_SUCCESS:
            return{...state,[action.dbtype]: action.payload,loader:false,isError:{status:null ,message:'' ,messageType:'' }};
        case FETCH_DATA_FAILURE:
            return{...state,isError:{...state.isError,status:true,message:action.payload||''},loader:false};

        case FETCH_USER_INFO:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case FETCH_USER_INFO_SUCCESS:
            return {...state,...action.payload,loader:false,isError:{status:null ,message:'' ,messageType:'' }}
        case FETCH_USER_INFO_FAILURE:
            return{...state,isError:{...state.isError,status:true},loader:false};
        case LOGOUT_SUCCESS:
            return {
                card:false,
                expense:false,
                idea:false,
                notification:false,
                notificationGroup:false,
                project:false,
                projectStep:false,
                task:false,
                weeklySchedule:false,
                workoutPlan:false,
                loader:false,
                isError:{status:null ,message:'' ,messageType:'' },
        }
        // TURN ON OF RAM DATA
        case SET_DATA_EXAMPLE:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case SET_DATA_EXAMPLE_SUCCESS:
            return{...state,isExample:action.payload,loader:false,isError:{status:null ,message:'' ,messageType:'' }};
        case SET_DATA_EXAMPLE_FAILURE:
            return{...state,isError:{...state.isError,status:true},loader:false};


        // HANDLE API REQ ON ANYTHING RELATED TO USER DATA
        case SET_DATA_REQUEST:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case SET_DATA_SUCCESS:
            if(!action.hasOwnProperty('method')){
                return {...state,loader:false,isError:{...state.isError,status:false}};
            }
            if(action.method==='POST'){
                return {...state,[action.dbtype]:[...state[action.dbtype],action.payload],loader:false,isError:{...state.isError,status:false}}
            }
            if(action.method==='DELETE'){
                const indexOfItemToDelete = state[action.dbtype].findIndex(item => item._id == action.payload._id);
                if (indexOfItemToDelete !== -1) {
                    return {
                      ...state,
                      [action.dbtype]: state[action.dbtype].filter((item, index) => index !== indexOfItemToDelete),
                      loader:false,
                      isError:{...state.isError,status:false}
                    };
                }    
                
               
            }
            if(action.method==='PUT'){
                const indexOfItemToUpdate = state[action.dbtype].findIndex(item => item._id == action.payload._id);
                if (indexOfItemToUpdate !== -1) {
                    return {
                      ...state,
                      [action.dbtype]: state[action.dbtype].map((item, index) =>
                        index === indexOfItemToUpdate ? { ...item, ...action.payload } : item
                      ),
                      loader:false,
                      isError:{...state.isError,status:false}
                    }
                }
            }
            return {...state,loader:false,isError:{...state.isError,status:true}};
        case SET_DATA_FAILURE:
            return {...state,loader:false,isError:{...state.isError,status:true}};
        case RESET_IS_ERROR:
            return {...state,isError:{status:null ,message:'' ,messageType:'' }};
        case SET_BY_KEY:
            return {...state,[action.key]:{...state[action.key],...action.payload}}
        case SET_BY_KEY_FIXED:
            return {...state,[action.key]:action.payload}
        case SET_BY_KEY_AND_ID:
            let newStateKey=state[action.key].map((keyValue)=> (keyValue['_id']==action['_id']) ? {...keyValue,...action.payload} : keyValue);
            return {...state,[action.key]:newStateKey};
        case SET_ORDER_BY_DBTYPE:
            // Create a copy of the organizeList from the state
            const updatedOrganizeList = state[action.key].map(item => ({ ...item }));

            // Update order for each item based on action.payload
            action.payload.forEach(updatedItem => {
                // Find the item in the updatedOrganizeList by _id
                const index = updatedOrganizeList.findIndex(item => item._id === updatedItem._id);
                if (index !== -1) {
                // Update the order of the item
                updatedOrganizeList[index].order = updatedItem.order;
                }
            });

            // Return the updated state with the modified organizeList
            return {
                ...state,
                [action.key]: updatedOrganizeList
            };
           
        case SET_NOTIFICATIONS_POPUP_PERIODIC:
            return {...state,...action.payload};

        case SET_BACKEND:
            return {...state};
        case SET_TASKS_FINISH_TODAY:
            return {...state,finishTasksToday:action.payload};
        case SET_BACKEND_SUCCESS:
            return {...state,isError:{...state.isError,status:false}};
        case SET_BACKEND_FAILURE:
            return {...state,isError:{...state.isError,status:true}}
        
        default :
            return state
    }
}


