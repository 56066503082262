import React from 'react';
import {connect} from 'react-redux';
import { useState,useEffect } from 'react';
import store from '../../store';
import ScheduledProjects from './ScheduledProjects';

const ScheduledDay = ({activeWeeklySchedule,userInfo}) => {
    let daySchedule;
    const day=new Date().getDay();
    const [projectSchedule,setProjectSchedule]=useState(false);
    const [endWorkDay,setEndWorkDay]=useState([0,0]);
    const getDataById_dbType=(id,dbType)=>{
        return userInfo[dbType].find(type=>type._id==id)
    }


    const getProjectsBySchedule=()=>{
        let projectsSchedule=[];
        daySchedule.map(p=>{
            let projectData={...(p.key!='personal' ? getDataById_dbType(p.key,'project') : {name:'personal'}) };
            projectData['hours']=[...p['hours']];
            
            projectsSchedule.push(projectData);
        })

        projectsSchedule.forEach((pr,index)=>{
           let projectStepsByProject= userInfo.projectStep.filter(ps=>ps['projectId']==pr._id).sort((a,b)=>a.order-b.order);
           projectsSchedule[index]['projectSteps']=projectStepsByProject;
           projectsSchedule[index]['isActive']=false;
           let timeLeft_byHoursFormat=projectsSchedule[index]['hours'];

           projectsSchedule[index]['projectSteps'].forEach((ps,secIndex)=>{
            if(timeLeft_byHoursFormat[0]>0||timeLeft_byHoursFormat[1]>0){
                const today=new Date();
                let tasksByProjectSteps= userInfo.task.filter(ts=>(ts['projectStepId']==ps._id) && ((ts['status']!==1)||(ts['status']===1 && store.compareDatesByDay(today,new Date(ts.statisticsInfo.endTime) || (new Date()))===0))).sort((a,b)=>a.order-b.order);
                let {resultTasks,byHoursFormat}=store.getTasksWithinTimePeriod(timeLeft_byHoursFormat,tasksByProjectSteps)
                timeLeft_byHoursFormat=byHoursFormat;
                if(resultTasks.length>0) {
                    projectsSchedule[index]['projectSteps'][secIndex]['tasks']=resultTasks;
                    projectsSchedule[index]['isActive']=true;
                    projectsSchedule[index]['projectSteps'][secIndex]['isActive']=true;
                }
                else{
                    projectsSchedule[index]['projectSteps'][secIndex]['tasks']=[];
                }
            }
            else{
                projectsSchedule[index]['projectSteps'][secIndex]['tasks']=[];
            }
           })
           const scheduledProjectSteps=projectsSchedule[index]['projectSteps'].filter(ps=>ps.tasks.length>0);
           projectsSchedule[index]['projectSteps']=scheduledProjectSteps;
        })
        return projectsSchedule;
    
    }
   
    useEffect(()=>{
        if(activeWeeklySchedule){
             if(activeWeeklySchedule.schedule.length===1){
                daySchedule=activeWeeklySchedule.schedule[0];
            }
            else{
                daySchedule=activeWeeklySchedule.schedule[day].slice(1);
            }
            setProjectSchedule([...getProjectsBySchedule()]);
            let endcheck=store.getDailyScheduleSumHours([...daySchedule,{hours:activeWeeklySchedule.schedule[day][0].startWorkHour}]);
         
            setEndWorkDay(endcheck);
        }
    },[userInfo])
    
    return (
        <div className='ScheduledDay-container'>
            <div className='scheduledDay_timer container-boxShadow'>
                <h2>Scheduled Working Hours</h2>
                { activeWeeklySchedule && <h4>{store.displayHours(activeWeeklySchedule.schedule[day][0].startWorkHour)} - {store.displayHours(endWorkDay)}</h4>}
            </div>
           {Array.isArray(projectSchedule) && <ScheduledProjects scheduledProjects={projectSchedule} startHour={activeWeeklySchedule ? activeWeeklySchedule.startWorkHour : [0,0]}/>}
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        activeWeeklySchedule: state.userInfo.weeklySchedule && state.userInfo.weeklySchedule.find(ws=>ws.isActive),
        userInfo:state.userInfo
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(ScheduledDay);