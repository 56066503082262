
import { useState,useEffect } from "react";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AddOptions from "./AddOptions";
const Add=({route,setFormMethod})=>{
    const [isOn,setIsOn]=useState(false);
    const setAddOptions=()=>{
        setIsOn(!isOn);
    }
    useEffect(()=>{
        if(isOn){
            setTimeout(()=>setIsOn(!isOn),15000)
        }
    },[isOn])
    return (
        <div className="add-container">
            <Fab color="secondary" aria-label="add">
                <AddIcon onClick={setAddOptions}/>
            </Fab>
            <AddOptions route={route} isOn={isOn} setFormMethod={setFormMethod}/>
        </div>
    )
}

export default Add;