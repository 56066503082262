import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import InfoHover from '../InfoHover';
import './style.css'
const BucketOfWater = ({ bucketStyle={},waterStyle={}, totalTasks,validIdArr,showPercentage,name }) => {
    const [finishedTasks,setFinishedTasks]=useState(0);
    const [persentage,setPersentage]=useState(0);
    
    useEffect(()=>{
        const allTasks=totalTasks(validIdArr);
        const fTasks=allTasks.filter(ts=>ts.status===1);
        setFinishedTasks(fTasks.length);
    },[totalTasks])
    useEffect(()=>{
        const allTasks=totalTasks(validIdArr);
        const numberOfTasks=allTasks.length===0 ? 1 : allTasks.length;
        setPersentage((finishedTasks /numberOfTasks) * 100);
    },[finishedTasks])
    const hoverInfo=()=>(
      
            `<div style="color:red;">
                Unfinished:${persentage==0 ? 0 :(((100*finishedTasks)/persentage)-finishedTasks).toFixed(0)}
            </div>
            <div style="color:rgb(136,198,150);">
                Finished:${finishedTasks}
            </div>`
        
    )
  

    return (

        <InfoHover 
         info={hoverInfo()}>
            {showPercentage && <span className='bucket_percentage'>{persentage==0 ? 0 : persentage.toFixed(1)}%</span>}
            <div className="bucket" style={bucketStyle}>
                <div className="water" style={{height: `${persentage}%`,...waterStyle}}>
                    <div className="wave wave1"></div>
                    <div className="wave wave2"></div>
                </div>
                
            </div>
        </InfoHover>

    );
};
const mapStateToProps = (state) => {
    return {
        totalTasks:(validIdArr)=>state.userInfo.task.filter(ts=>validIdArr==='all' ? true : validIdArr.includes(ts.projectStepId)),
      };
    };
const mapDispatchToProps = (dispatch) => {
    return {
    
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(BucketOfWater);

