import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {connect} from 'react-redux';
import store from '../store';
import {setBackEnd,resetIsError} from '../redux/actions/userInfoActions';
const NotPopupComp = ({notification,getNotificationGroupById,byHour,setIsAssigmentDone}) => {
    const finishAssigment=()=>{
        
        const notificationStatsObj={
            score:{finishTime:new Date()},
            notificationGroupId:notification['notificationGroupId'],
            notificationId:notification['_id']
        }
        setIsAssigmentDone(notificationStatsObj);
    }
 
    return (
        <div className='notification_AssigmentComp-wrapper container-boxShadow'>
            <div className='headers'>
                <h4>{getNotificationGroupById(notification['notificationGroupId']).name}</h4>
                <h5>{notification.name}</h5>
                {byHour && <h6>{store.displayHours(notification['byHour'])}</h6>}
            </div>
            <div className='description'>
                <p>{notification.description}</p>
            </div>
            <div className='flex-even mid'>
                    <LoadingButton onClick={finishAssigment} style={{backgroundColor:`${ byHour ? 'rgb(255, 192, 0)' : 'rgb(136,198,150)'}`}} variant="contained" color={byHour ? 'warning' : 'success'}>Finish</LoadingButton>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
return {
    getNotificationGroupById:(not_group_ID)=>state.userInfo.notificationGroup.find(ng=>ng['_id']===not_group_ID)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetErrorState:()=>dispatch(resetIsError()),
        setIsAssigmentDone:(data)=>dispatch(setBackEnd('notification','finish-notification','post',data,true,{_id:data.notificationId,key:'notification',value:{isFinished:true}})),
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(NotPopupComp);