import {useState,useEffect } from 'react';
import store from "../store";
import Note from "./Note";
import {connect} from 'react-redux';
import moment from 'moment';
import {setDataReq} from '../redux/actions/userInfoActions';
import { registerRequest } from '../redux/actions/authActions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// UI COMPONENTS
import { TextField,FormControlLabel,InputLabel, Switch,FormControl,FormLabel,RadioGroup,Radio,Checkbox,Select,MenuItem} from '@mui/material';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import VerificationInput from 'react-verification-input';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateRange,Calendar } from 'react-date-range';
import { LoadingButton } from '@mui/lab';
import {setByKey} from '../redux/actions/userInfoActions';
import InfoHover from './InfoHover';
import api from '../api';
const ChosenForm=({dbtype,method,userInfo,submitForm,loader,getNearestDay,userId,setError,quickAddData})=>{

// Initialize formData object by selected dbtype and method
const [formData,setFormData]=useState({...store.formKeys(dbtype,method),userId});

//   For Select Values
  const [selectedValue,setSelectedValue]=useState('');
  const [selectedPValue,setSelectedPValue]=useState('');
  const [selectedGValue,setSelectedGValue]=useState('');
  const handleSelectChange=(e)=>{
    setSelectedValue(e.target.value);
    handleFormInputByKey('_id',e.target.value);
    method==='PUT' && initializeFormData(e.target.value);
  }
  const handleSelectPChange=(e)=>{
    setSelectedPValue(e.target.value);
    const parentKey=store.parentBydbtype(dbtype);
    handleFormInputByKey(`${parentKey}Id`,e.target.value);
  }
  const handleSelectGChange=(e)=>{
    setSelectedGValue(e.target.value);
    // const grandpatKey=store.parentBydbtype(dbtype);
    // handleFormInputByKey(`${grandpatKey}Id`,e.target.value);
  }
  const initializeFormData=(id)=>{
    const indexOfItemToUpdate = userInfo[dbtype].findIndex(item => item._id === id);
    if(indexOfItemToUpdate!=-1 ){
        setFormData({...userInfo[dbtype][indexOfItemToUpdate],userId});

        // handle setting component that requires state and different data type
        handleFormDataToState(userInfo[dbtype][indexOfItemToUpdate]);
    }
  }

  const handleFormDataToState=(data)=>{
    switch (dbtype){
        case 'bank':
            return;
        case 'card':
            return;
        case 'expense':
            return;
        case 'idea':
            return;
        case 'notification':
            setSpecHour(store.formDataToElement('timePicker',data));
            return;
        case 'notificationGroup':
            return;
        case 'project':
            const dateRangeData=store.formDataToElement('dateRange',data);
            setDateRangeState(dateRangeData);
            return;
        case 'projectStep':
            setDateRangeState(store.formDataToElement('dateRange',data));
            return;
        case 'task':
            setHoursMins(store.formDataToElement('hoursMins',data));
            return;
        case 'weeklySchedule':
            return;
        case 'workoutPlan':
            initializePickedBP(data);
            return;
    }
  }
 


 


//   CUSTOMIZE DBTYPE FORM FUNCTIONS:

    // Project / ProjectStep
    // Required for react-date-range library
    const [dateRangeState,setDateRangeState]=useState([
        {
        startDate:new Date(),
        endDate: null,
        key: 'selection'
        }
    ]);
    const handleDateRangePick=(range)=>{
        setDateRangeState(range);
        setFormData({...formData, ...store.elementDataToForm('dateRange',range[0])});
    }

    // WorkoutPLAN
    // used in workout rotation input : on complete opens the rotation info
    const [rotationSchedule,setRotationSchedule]=useState([]); // rotationInfo object cant run through it REACT
    const rotationIsSet=(daysArr)=>{
            const rInfo={};
            const distinctSort=daysArr.filter((value,index,self)=>value!=='R'&&self.indexOf(value)===index).sort();
        setRotationSchedule(distinctSort);
        distinctSort.forEach(day=>rInfo[day]=[]);
            method!='PUT' && handleFormInputByKey('rotationInfo',rInfo);
    }
    useEffect(()=>{
        if(dbtype==='workoutPlan'&&rotationSchedule.length===0){
            if(formData.rotation.length===7){
                rotationIsSet(formData.rotation.split(''));
            }
        }
    },[formData])
    
    //used in workout to disable picked body parts
    const [pickedBP,setPickedBP]=useState([]);
    const handlePickBodyP_Day=(e)=>{
        const [chosenDay,chosenBP]= e.target.name.split(',');
        if(e.target.checked){
            const update_rInfo={...formData.rotationInfo};
            update_rInfo[chosenDay].push(chosenBP);
            handleFormInputByKey('rotationInfo',update_rInfo);
            setPickedBP((prevPickedBP) => [...prevPickedBP, chosenBP]);
        }
        else{
            const update_rInfo={...formData.rotationInfo};
            update_rInfo[chosenDay]=update_rInfo[chosenDay].filter(bodyPart=>bodyPart!=chosenBP);
            handleFormInputByKey('rotationInfo',update_rInfo);
            setPickedBP(pickedBP.filter(bodyPart=>bodyPart!=chosenBP))
        }
    }
    const initializePickedBP=(data)=>{
        let arr=[];
        for(const workoutDay in data.rotationInfo){
            for(const bodyPart of data.rotationInfo[workoutDay]){
                arr.push(bodyPart);
            }
        }
        setPickedBP(arr);
    }
    // TASK 
    // DURATION TO MIN HOURS
    const [hoursMins,setHoursMins]=useState({
        hours:'',
        mins:''
    });

    const handleSetHoursMins=(e,data)=>{
        if(e){
            const updatedHoursMins={...hoursMins,[e.target.name]:e.target.value};
            setHoursMins({...updatedHoursMins});
            setFormData({...formData,duration:store.elementDataToForm('hoursMins',updatedHoursMins)});
        }

    }

    // WEEKLYSCHEDULE
    const addProject=(weekDay)=>{
        const initialProjObj={key:null,hours:[0,0]};
        const nSchedule=[...formData.schedule];
        nSchedule[weekDay].push(initialProjObj);
        setFormData({...formData,schedule:nSchedule});
    }
    const removeLastOneProject=(weekDay)=>{
        const nSchedule=[...formData.schedule];
        nSchedule[weekDay].pop();
        setFormData({...formData,schedule:nSchedule});
    }


    // NOTIFICATION
    // GET ALL NOTIFICATIONS ON PUT(UPDATE) AND DELETE 

    const [allNots,setAllNots]=useState([]);

    useState(()=>{
        if(dbtype==='notification' && (method==='DELETE' || method==='PUT')){
            api.getAll('notification').then(res=>res.json())
            .then(nots=>{
                setAllNots(nots);
            })
            .catch(err=>console.log(err))
        }
    },[dbtype,method])
   
    //  HANDLE SET NOTE HOURS
    const [specHour,setSpecHour]=useState(store.formDataToElement('timePicker',{byHour:[0,0]}));

    const handleSetSpecHour=(newValue)=>{
        
    setSpecHour(newValue);
    setFormData({...formData,byHour:store.elementDataToForm('timePicker',newValue)});

    }

// END CUSTOMIZE DBTYPE FORM FUNCTIONS



// handle update form state
const handleFormInputByKey=(key,value)=>{
    const updateValue= value==='false' ? true : value==='true' ? false : value;
    setFormData({...formData,[key]:updateValue})
}
const handleFormInput=(e)=>{
    const updateValue= e.target.value==='false' ? true : e.target.value==='true' ? false : e.target.value;
    setFormData({...formData,[e.target.name]:updateValue})
}

  useEffect(()=>{
    if (quickAddData) {
        
        const grandpaValue = store.grandpaBydbtype(dbtype);
        const parentValue = store.parentBydbtype(dbtype);
    
        if (grandpaValue) {
          setSelectedGValue(quickAddData[grandpaValue + 'Id']);
        }
    
        if (parentValue) {
          setSelectedPValue(quickAddData[parentValue + 'Id']);
          setFormData({...store.formKeys(dbtype,method),userId,[parentValue + 'Id']: quickAddData[parentValue + 'Id']});
          
        }
      }
      else {
        setFormData({...store.formKeys(dbtype,method),userId});
      }

  },[dbtype,method]);

  
  const chosenForm={
    idea:{
        DELETE:()=>{
            return (
            <>
                  
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Idea</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Idea"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.idea.map((currentIdea) => (
                                <MenuItem key={currentIdea._id} value={currentIdea._id}>
                                    {currentIdea.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
            </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="idea name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="description"
                            label="description"
                            name="description"
                            value={formData.description}
                            onChange={handleFormInput}
                            autoFocus
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <div className="input_label-wrapper"><label htmlFor="">הוסף הערות</label></div>
                        <div><Note formData={formData} setFormData={handleFormInputByKey}/></div>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                        <InputLabel id="demo-simple-select-label">Idea</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Idea"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.idea.map((currentIdea) => (
                                <MenuItem key={currentIdea._id} value={currentIdea._id}>
                                    {currentIdea.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="idea name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="description"
                                label="description"
                                name="description"
                                value={formData.description}
                                onChange={handleFormInput}
                                autoFocus
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                            <div className="input_label-wrapper"><label htmlFor="">הוסף הערות</label></div>
                            <div><Note formData={formData} setFormData={handleFormInputByKey}/></div>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    project:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                    <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="project"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
            <>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="project name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                    <FormLabel >Project range</FormLabel>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => handleDateRangePick([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRangeState}
                            />
                </FormControl>
                <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                </FormControl>
            </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="project"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="project name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                            <FormLabel >Project range</FormLabel>
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={item => handleDateRangePick([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={dateRangeState}
                                    />
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
  
    },
    projectStep:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                    <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Project"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        {selectedPValue!='' && 
                         <>
                            <InputLabel id="demo-simple-select-label">projectStep</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="projectStep"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.projectStep.filter(currentProjectStep=>currentProjectStep.projectId==selectedPValue).map(currentProjectStep=>
                                    <MenuItem key={currentProjectStep._id} value={currentProjectStep._id}>
                                        {currentProjectStep.name}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                     </FormControl>
                     <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
          
            return (
                <>
                    <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="projectStep name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                    </FormControl>
                    <FormControl>
                        <FormLabel >Project Step range</FormLabel>
                            <DateRange
                                editableDateInputs={true}
                                onChange={item => handleDateRangePick([item.selection])}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRangeState}
                                />
                    </FormControl>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Associated project</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPValue}
                                label="Project"
                                onChange={handleSelectPChange}
                                style={{backgroundColor:'white'}}
                                
                            >
                                {userInfo.project.map((currentProject) => (
                                    <MenuItem key={currentProject._id} value={currentProject._id}>
                                        {currentProject.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                    </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                        </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Project"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                <FormControl >
                    {selectedPValue!='' && 
                        <>
                        <InputLabel id="demo-simple-select-label">projectStep</InputLabel> 
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValue}
                                label="projectStep"
                                onChange={handleSelectChange}
                                style={{backgroundColor:'white'}}
                            >
                            {userInfo.projectStep.filter(currentProjectStep=>currentProjectStep.projectId==selectedPValue).map(currentProjectStep=>
                                <MenuItem key={currentProjectStep._id} value={currentProjectStep._id}>
                                    {currentProjectStep.name}
                                </MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="projectStep name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel >Project Step range</FormLabel>
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={item => handleDateRangePick([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={dateRangeState}
                                    />
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Associated project</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="Project"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                    
                                >
                                    {userInfo.project.map((currentProject) => (
                                        <MenuItem key={currentProject._id} value={currentProject._id}>
                                            {currentProject.name}
                                        </MenuItem>
                                        ))}
                                </Select>
                        </FormControl>
                        <FormControl>
                        <InputLabel id="demo-simple-select-label">Order</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.order}
                                label="Order"
                                name='order'
                                onChange={handleFormInput}
                                style={{backgroundColor:'white'}}
                                
                            >
                            {userInfo.projectStep.filter(ps=>ps['projectId']==selectedPValue).map((currentBank) => (
                                    <MenuItem key={currentBank._id} value={currentBank._id}>
                                        {currentBank.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
  
    },
    task:{
        DELETE:()=>{
            return (
                <>
                    
                    <FormControl >
                    <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGValue}
                            label="Project"
                            onChange={handleSelectGChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        {selectedGValue!='' && 
                            <>
                                <InputLabel id="demo-simple-select-label">projectStep</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="projectStep"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.projectStep.filter(currentProjectStep=>currentProjectStep.projectId==selectedGValue).map(currentProjectStep=>
                                    <MenuItem key={currentProjectStep._id} value={currentProjectStep._id}>
                                        {currentProjectStep.name}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                     </FormControl>
                     <FormControl >
                        {selectedPValue!='' && 
                            <>
                                 <InputLabel id="demo-simple-select-label">task</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="Task"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.task.filter(currentTask=>currentTask.projectStepId==selectedPValue).map(currentTask=>
                                    <MenuItem key={currentTask._id} value={currentTask._id}>
                                        {currentTask.name}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                     </FormControl>
                     <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
              <>
             
              <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="task name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="info"
                            label="task info"
                            name="info"
                            value={formData.info}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                   <div className='flex-even mid'> 
                    <TextField
                            label="Hours"
                            type="number"
                            value={hoursMins.hours}
                            onChange={handleSetHoursMins}
                            name='hours'
                            inputProps={{
                            min: 0,
                            max: 23,
                            step: 1,
                            }}
                            sx={{
                                "& input": {
                                    width: "7ch", // Set width to 3 characters
                                },
                                "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "0.8rem",
                                },
                                "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.6rem",
                                },
                                }}
                        />
                        <div className='flex-even mid'> <span>:</span></div>
                        <TextField
                            label="Minutes"
                            type="number"
                            name='mins'
                            value={hoursMins.mins}
                            onChange={handleSetHoursMins}
                            inputProps={{
                            min: 0,
                            max: 59,
                            step: 1,
                            }}
                            sx={{
                                "& input": {
                                    width: "7ch", // Set width to 3 characters
                                },
                                "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "0.8rem",
                                },
                                "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.6rem",
                                },
                                }}
                        />
                    </div>
                </FormControl>
                <FormControl>
                    <h4 id="demo-simple-select-label">Association</h4>
                    <div className='flex-column'>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGValue}
                            label="Project"
                            onChange={handleSelectGChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <FormControl >
                        {selectedGValue!='' && 
                            <>
                                <InputLabel id="demo-simple-select-label">projectStep</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="projectStep"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.projectStep.filter(currentProjectStep=>currentProjectStep.projectId==selectedGValue).map(currentProjectStep=>
                                    <MenuItem key={currentProjectStep._id} value={currentProjectStep._id}>
                                        {currentProjectStep.name}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                     </FormControl>
                     </div>
                </FormControl>
                <FormControl>
                    <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                </FormControl>
          </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">project</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGValue}
                            label="Project"
                            onChange={handleSelectGChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.project.map((currentProject) => (
                                <MenuItem key={currentProject._id} value={currentProject._id}>
                                    {currentProject.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                <FormControl >
                    {selectedGValue!='' && 
                        <>
                            <InputLabel id="demo-simple-select-label">projectStep</InputLabel> 
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPValue}
                                label="projectStep"
                                onChange={handleSelectPChange}
                                style={{backgroundColor:'white'}}
                            >
                            {userInfo.projectStep.filter(currentProjectStep=>currentProjectStep.projectId==selectedGValue).map(currentProjectStep=>
                                <MenuItem key={currentProjectStep._id} value={currentProjectStep._id}>
                                    {currentProjectStep.name}
                                </MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </FormControl>
                <FormControl >
                {selectedPValue!='' && 
                    <>
                            <InputLabel id="demo-simple-select-label">task</InputLabel> 
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Task"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                        {userInfo.task.filter(currentTask=>currentTask.projectStepId==selectedPValue).map(currentTask=>
                            <MenuItem key={currentTask._id} value={currentTask._id}>
                                {currentTask.name}
                            </MenuItem>
                            )}
                        </Select>
                    </>
                }
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="task name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                                <TextField
                                    margin="normal"
                                    required
                                    color="secondary"
                                    fullWidth
                                    id="info"
                                    label="task info"
                                    name="info"
                                    value={formData.info}
                                    onChange={handleFormInput}
                                    sx={{
                                        "& input": {
                                            bgcolor:'whitesmoke'
                                        },
                                        "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "1rem",
                                        "&.Mui-focused": {
                                            color: "black",
                                        }
                                        },
                                        "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.8rem",
                                        },
                                    }}
                                    
                                />
                        </FormControl>
                        <FormControl>
                        <div className='flex-even mid'> 
                            <TextField
                                    label="Hours"
                                    type="number"
                                    name='hours'
                                    value={hoursMins.hours}
                                    onChange={handleSetHoursMins}
                                    inputProps={{
                                    min: 0,
                                    max: 23,
                                    step: 1,
                                    }}
                                    sx={{
                                        "& input": {
                                            width: "7ch", // Set width to 3 characters
                                        },
                                        "& label": {
                                            left: "unset",
                                            right: "1.75rem",
                                            transformOrigin: "right",
                                            fontSize: "0.8rem",
                                        },
                                        "& legend": {
                                            textAlign: "right",
                                            fontSize: "0.6rem",
                                        },
                                        }}
                                />
                                <div className='flex-even mid'> <span>:</span></div>
                                <TextField
                                    label="Minutes"
                                    type="number"
                                    name='mins'
                                    value={hoursMins.mins}
                                    onChange={handleSetHoursMins}
                                    inputProps={{
                                    min: 0,
                                    max: 59,
                                    step: 1,
                                    }}
                                    sx={{
                                        "& input": {
                                            width: "7ch", // Set width to 3 characters
                                        },
                                        "& label": {
                                            left: "unset",
                                            right: "1.75rem",
                                            transformOrigin: "right",
                                            fontSize: "0.8rem",
                                        },
                                        "& legend": {
                                            textAlign: "right",
                                            fontSize: "0.6rem",
                                        },
                                        }}
                                />
                            </div>
                        </FormControl>
                        <FormControl>
                            <h4 id="demo-simple-select-label">Association</h4>
                            <div className='flex-column'>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">project</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedGValue}
                                    label="Project"
                                    onChange={handleSelectGChange}
                                    style={{backgroundColor:'white'}}
                                    
                                >
                                    {userInfo.project.map((currentProject) => (
                                        <MenuItem key={currentProject._id} value={currentProject._id}>
                                            {currentProject.name}
                                        </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <FormControl >
                                {selectedGValue!='' && 
                                    <>
                                        <InputLabel id="demo-simple-select-label">projectStep</InputLabel> 
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedPValue}
                                            label="projectStep"
                                            onChange={handleSelectPChange}
                                            style={{backgroundColor:'white'}}
                                        >
                                        {userInfo.projectStep.filter(currentProjectStep=>currentProjectStep.projectId==selectedGValue).map(currentProjectStep=>
                                            <MenuItem key={currentProjectStep._id} value={currentProjectStep._id}>
                                                {currentProjectStep.name}
                                            </MenuItem>
                                            )}
                                        </Select>
                                    </>
                                }
                            </FormControl>
                            </div>
                        </FormControl>
                        <FormControl>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.status}
                                label="status"
                                name='status'
                                onChange={handleFormInput}
                                style={{backgroundColor:'white'}}
                                
                            >
                           
                            <MenuItem key={'status'} value={-1}>
                                Didnt Start
                            </MenuItem>
                            <MenuItem key={'status'} value={0}>
                                Mid 
                            </MenuItem>
                            <MenuItem key={'status'} value={1}>
                                Fininshed
                            </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                        <InputLabel id="demo-simple-select-label">Order</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.order}
                                label="order"
                                name='order'
                                onChange={handleFormInput}
                                style={{backgroundColor:'white'}}
                                
                            >
                            {userInfo.bank.map((currentBank) => (
                                    <MenuItem key={currentBank._id} value={currentBank._id}>
                                        {currentBank.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    user:{
        DELETE:()=>{
            return (
            <>
            </>
            )
        },
        POST:()=>{
            return (
            <>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="username"
                            label="user name"
                            name="username"
                            value={formData.username}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="password"
                            label="user password"
                            type='password'
                            name="password"
                            value={formData.password}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="firstName"
                            label="first name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="lastName"
                            label="last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="email"
                            label="email"
                            name="email"
                            type='email'
                            value={formData.email}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                </FormControl>
                <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" color='primary'>Add</LoadingButton>
                </FormControl>
            </>
            )
        },
        PUT:()=>{
            return (
              <>
              </>
            )
        },
    },
    workoutPlan:{
        DELETE:()=>{
            return (
                <>
                  
                <FormControl >
                <InputLabel id="demo-simple-select-label">workoutPlan</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="workoutPlan"
                        onChange={handleSelectChange}
                        style={{backgroundColor:'white'}}
                    >
                        {userInfo.workoutPlan.map((currentWorkoutPlan) => (
                            <MenuItem key={currentWorkoutPlan._id} value={currentWorkoutPlan._id}>
                                {currentWorkoutPlan.name}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                </FormControl>
        </>
            )
        },
        POST:()=>{
            return (
            <>
                <FormControl>
                    <TextField
                        margin="normal"
                        required
                        color="secondary"
                        fullWidth
                        id="name"
                        label="workoutPlan name"
                        name="name"
                        value={formData.name}
                        onChange={handleFormInput}
                        sx={{
                            "& input": {
                                bgcolor:'whitesmoke'
                                },
                            "& label": {
                                left: "unset",
                                right: "1.75rem",
                                transformOrigin: "right",
                                fontSize: "1rem",
                                "&.Mui-focused": {
                                color: "black",
                                }
                            },
                            "& legend": {
                                textAlign: "right",
                                fontSize: "0.8rem",
                            },
                            }}      
                    />
                </FormControl>
                <FormControl>
                    <div className='flex-even mid'>
                        <VerificationInput classNames={{character:'toUpperCase'}} length={7} validChars='A-Fa-frRpP' name='rotation' value={formData.rotation} onChange={value=>handleFormInputByKey('rotation',value.toUpperCase())}   placeholder="_"/>
                    </div>
                </FormControl>
                <FormControl> 
                        <div className='flex-even mid'>
                            <FormControlLabel
                                label='Before Work'
                                labelPlacement='top'
                                control={<Switch  value={formData.isBeforeWorkDay} checked={formData.isBeforeWorkDay} name='isBeforeWorkDay' onChange={handleFormInput}/>}
                            />

                            <FormControlLabel
                                label='Weekly Cycle'
                                labelPlacement='top'
                                control={<Switch  value={formData.weeklyCycle} checked={formData.weeklyCycle} name='weeklyCycle' onChange={handleFormInput}/>}
                            />
                        </div>
                </FormControl>
                <FormControl>
                    {rotationSchedule.length>0 && rotationSchedule.map((day,index)=>
                    day!=='P'?
                        <div key={index}>
                            
                                <h4 className=''>{day}</h4>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    {store.workout.bodyParts.map(bodyPart=><FormControlLabel   control={<Checkbox disabled={pickedBP.includes(bodyPart) && !(formData.rotationInfo[day].includes(bodyPart))} name={`${day},${bodyPart}`} onChange={handlePickBodyP_Day}/>} label={bodyPart}/>)}
                                </RadioGroup>
                            
                        </div>
                    :
                    <div key={index}>
                         
                                <h4 className=''>{day}</h4>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    {store.workout.part.map(bodyPart=><FormControlLabel   control={<Checkbox disabled={pickedBP.includes(bodyPart) && !(formData.rotationInfo[day].includes(bodyPart))} name={`${day},${bodyPart}`} onChange={handlePickBodyP_Day}/>} label={bodyPart}/>)}
                                </RadioGroup>
                       
                    </div>
                    )}
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        label='Starting Date'
                        labelPlacement='top'
                        control={
                            <Calendar 
                                date={formData.startDate}
                                onChange={(date=>{
                                    setFormData({...formData,startDate:date});
                                })} 
                                />
                            }
                    />
                </FormControl>
                <FormControl>
                    <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                </FormControl>
            </>
            )
        },
        PUT:()=>{
            return (
            <>
                 <FormControl >
                    <InputLabel id="demo-simple-select-label">workoutPlan</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="workoutPlan"
                        onChange={handleSelectChange}
                        style={{backgroundColor:'white'}}
                    >
                        {userInfo.workoutPlan.map((currentWorkoutPlan) => (
                            <MenuItem key={currentWorkoutPlan._id} value={currentWorkoutPlan._id}>
                                {currentWorkoutPlan.name}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {selectedValue!='' && formData.rotationInfo[(Object.keys(formData.rotationInfo))[0]].length > 0 &&
                    <>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="workoutPlan name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                        },
                                    "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "1rem",
                                        "&.Mui-focused": {
                                        color: "black",
                                        }
                                    },
                                    "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.8rem",
                                    },
                                    }}      
                            />
                        </FormControl>
                        <FormControl>
                        <FormControlLabel
                                label='Set Active'
                                labelPlacement='top'
                                control={<Switch  value={formData.isActive}  checked={formData.isActive} name='isActive' onChange={handleFormInput}/>}
                            />
                            
                        </FormControl>
                        <FormControl>
                            <div className='flex-even mid'>
                                <VerificationInput classNames={{character:'toUpperCase'}} length={7} validChars='A-Fa-frRpP' name='rotation' value={formData.rotation} onChange={value=>handleFormInputByKey('rotation',value.toUpperCase())}   placeholder="_"/>
                            </div>
                        </FormControl>
                        <FormControl> 
                                <div className='flex-even mid'>
                                    <FormControlLabel
                                        label='Before Work'
                                        labelPlacement='top'
                                        control={<Switch  value={formData.isBeforeWorkDay} checked={formData.isBeforeWorkDay} name='isBeforeWorkDay' onChange={handleFormInput}/>}
                                    />

                                    <FormControlLabel
                                        label='Weekly Cycle'
                                        labelPlacement='top'
                                        control={<Switch  value={formData.weeklyCycle} checked={formData.weeklyCycle} name='weeklyCycle' onChange={handleFormInput}/>}
                                    />
                                </div>
                        </FormControl>
                        {   formData.rotationInfo &&
                            Object.keys(formData.rotationInfo).length > 0 &&
                            formData.rotationInfo[(Object.keys(formData.rotationInfo))[0]] &&
                            formData.rotationInfo[(Object.keys(formData.rotationInfo))[0]].length > 0 &&
                            <FormControl>
                                {rotationSchedule.length>0  &&  rotationSchedule.map((day,index)=>
                                    <div key={index}>
                                            <h4 className=''>{day}</h4>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                {day!=='P'? 
                                                    store.workout.bodyParts.map(bodyPart=><FormControlLabel   control={<Checkbox checked={formData.rotationInfo[day].includes(bodyPart)} disabled={pickedBP.includes(bodyPart) && !(formData.rotationInfo[day].includes(bodyPart))} name={`${day},${bodyPart}`} onChange={handlePickBodyP_Day}/>} label={bodyPart}/>)
                                                    :
                                                    store.workout.part.map(bodyPart=><FormControlLabel   control={<Checkbox checked={formData.rotationInfo[day].includes(bodyPart)} disabled={pickedBP.includes(bodyPart) && !(formData.rotationInfo[day].includes(bodyPart))} name={`${day},${bodyPart}`} onChange={handlePickBodyP_Day}/>} label={bodyPart}/>)
                                                }
                                            </RadioGroup>
                                        
                                    </div>
                                
                                
                                )}
                            </FormControl>
                        }
                        <FormControl>
                            <FormControlLabel
                                label='Starting Date'
                                labelPlacement='top'
                                control={
                                    <Calendar 
                                        date={new Date(formData.startDate)}
                                        onChange={(date=>{
                                            setFormData({...formData,startDate:date});
                                        })} 
                                        />
                                    }
                            />
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
  
    },
    bank:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="banks"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="bank name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="accountNumber"
                            label="Account Number"
                            name="accountNumber"
                            value={formData.accountNumber}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="Account Info"
                            name="accountInfo"
                            value={formData.accountInfo}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke',
                                    height:'100px'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                        <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="banks"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="bank name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="accountNumber"
                            label="Account Number"
                            name="accountNumber"
                            value={formData.accountNumber}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="Account Info"
                                name="accountInfo"
                                value={formData.accountInfo}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke',
                                        height:'100px'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    card:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                    <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Bank"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                             {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        {selectedPValue!='' && 
                         <>
                            <InputLabel id="demo-simple-select-label">Card</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="Card"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.card.filter(currentCard=>currentCard.bankId==selectedPValue).map(currentCard=>
                                    <MenuItem key={currentCard._id} value={currentCard._id}>
                                        {currentCard.name}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                     </FormControl>
                     <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="Card name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Associated Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="banks"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                           {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                    <InputLabel id="demo-simple-select-label">Billing Day</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Card"
                            value={formData.billingMonthDay}
                            onChange={e=>{setFormData({...formData,billingMonthDay:e.target.value})}}
                            maxRows={2}
                            style={{backgroundColor:'white',maxHeight:'200'}}
                        >
                            {((new Array(31)).fill(null).map((value, index) => 
                                 <MenuItem key={index+1} value={index+1}>
                                    {index+1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Bank"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                             {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                <FormControl >
                    {selectedPValue!='' && 
                        <>
                        <InputLabel id="demo-simple-select-label">Card</InputLabel> 
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValue}
                                label="Card"
                                onChange={handleSelectChange}
                                style={{backgroundColor:'white'}}
                            >
                            {userInfo.card.filter(currentCard=>currentCard.bankId==selectedPValue).map(currentCard=>
                                <MenuItem key={currentCard._id} value={currentCard._id}>
                                    {currentCard.name}
                                </MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="Card name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Associated Bank</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPValue}
                                label="banks"
                                onChange={handleSelectPChange}
                                style={{backgroundColor:'white'}}
                                
                            >
                            {userInfo.bank.map((currentBank) => (
                                    <MenuItem key={currentBank._id} value={currentBank._id}>
                                        {currentBank.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                        <InputLabel id="demo-simple-select-label">Billing Day</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Card"
                                value={formData.billingMonthDay}
                                onChange={e=>{setFormData({...formData,billingMonthDay:e.target.value})}}
                                maxRows={2}
                                style={{backgroundColor:'white',maxHeight:'200'}}
                            >
                                {((new Array(31)).fill(null).map((value, index) => 
                                    <MenuItem key={index+1} value={index+1}>
                                        {index+1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    expense:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGValue}
                            label="Card"
                            onChange={handleSelectGChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        { selectedGValue!='' && 
                            <>
                                <InputLabel id="demo-simple-select-label">card</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="Card"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                    
                                >
                                    {userInfo.card.map((currentCard) => (
                                        <MenuItem key={currentCard._id} value={currentCard._id}>
                                            {currentCard.name}
                                        </MenuItem>
                                        ))}
                                </Select>
                            </> 
                        }
                    </FormControl>
                    <FormControl >
                        {selectedPValue!='' && 
                        <>
                            <InputLabel id="demo-simple-select-label">expense</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="expense"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.expense.filter(currentExpense=>currentExpense.cardId==selectedPValue).map(currentExpense=>
                                    <MenuItem key={currentExpense._id} value={currentExpense._id}>
                                        {currentExpense.name}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="expense name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="cost"
                            label="expense cost"
                            name="cost"
                            value={formData.cost}
                            onChange={handleFormInput}
                            // onKeyDown={(event) => {
                            //     const allowedCharacters = /^\d+(\.\d+)?$/;
                            //     if (!allowedCharacters.test(event.key)) {
                            //       event.preventDefault();
                            //     }
                            //   }}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Billing Day</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Card"
                            value={formData.billingMonthDay}
                            onChange={e=>{setFormData({...formData,billingMonthDay:e.target.value})}}
                            maxRows={2}
                            style={{backgroundColor:'white',maxHeight:'200'}}
                        >
                            {((new Array(31)).fill(null).map((value, index) => 
                                 <MenuItem key={index+1} value={index+1}>
                                    {index+1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Associated Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGValue}
                            label="Card"
                            onChange={handleSelectGChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        { selectedGValue!='' && 
                            <>
                                <InputLabel id="demo-simple-select-label">Associated card</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="card"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                    
                                >
                                    {userInfo.card.map((currentCard) => (
                                    <MenuItem key={currentCard._id} value={currentCard._id}>
                                        {currentCard.name}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </>
                        }
                    </FormControl>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Coin</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="coin"
                            onChange={handleFormInput}
                            value={formData.coin}
                        >
                            <FormControlLabel value="shekel" control={<Radio />} label="Shekel" />
                            <FormControlLabel value="dollar" control={<Radio />} label="Dollar" />
                            <FormControlLabel value="euro" control={<Radio />} label="Euro" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="type"
                            onChange={handleFormInput}
                            value={formData.type}
                        >
                            <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
                            <FormControlLabel value="leisure" control={<Radio />} label="Leisure" />
                            <FormControlLabel value="lifestyle" control={<Radio />} label="Life Style" />
                            <FormControlLabel value="investment" control={<Radio />} label="Investment" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                        <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGValue}
                            label="Card"
                            onChange={handleSelectGChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                </FormControl>
                <FormControl >
                    { selectedGValue!='' && 
                        <>
                            <InputLabel id="demo-simple-select-label">card</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPValue}
                                label="Card"
                                onChange={handleSelectPChange}
                                style={{backgroundColor:'white'}}
                                
                            >
                                {userInfo.card.map((currentCard) => (
                                    <MenuItem key={currentCard._id} value={currentCard._id}>
                                        {currentCard.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </> 
                    }
                </FormControl>
                <FormControl >
                    {selectedPValue!='' && 
                    <>
                        <InputLabel id="demo-simple-select-label">expense</InputLabel> 
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValue}
                                label="expense"
                                onChange={handleSelectChange}
                                style={{backgroundColor:'white'}}
                            >
                            {userInfo.expense.filter(currentExpense=>currentExpense.cardId==selectedPValue).map(currentExpense=>
                                <MenuItem key={currentExpense._id} value={currentExpense._id}>
                                    {currentExpense.name}
                                </MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="expense name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="const"
                                label="expense cost"
                                name="const"
                                value={formData.cost}
                                onChange={handleFormInput}
                                // onKeyDown={(event) => {
                                //     const allowedCharacters = /^\d+(\.\d+)?$/;
                                //     if (!allowedCharacters.test(event.key)) {
                                //       event.preventDefault();
                                //     }
                                //   }}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Billing Day</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Card"
                                value={formData.billingMonthDay}
                                onChange={e=>{setFormData({...formData,billingMonthDay:e.target.value})}}
                                maxRows={2}
                                style={{backgroundColor:'white',maxHeight:'200'}}
                            >
                                {((new Array(31)).fill(null).map((value, index) => 
                                    <MenuItem key={index+1} value={index+1}>
                                        {index+1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Associated card</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="card"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                    
                                >
                                    {userInfo.card.map((currentCard) => (
                                    <MenuItem key={currentCard._id} value={currentCard._id}>
                                        {currentCard.name}
                                    </MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                        <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="type"
                                onChange={handleFormInput}
                                value={formData.type}
                            >
                                <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
                                <FormControlLabel value="leisure" control={<Radio />} label="Leisure" />
                                <FormControlLabel value="lifestyle" control={<Radio />} label="Life Style" />
                                <FormControlLabel value="investment" control={<Radio />} label="Investment" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    notificationGroup:{
        DELETE:()=>{
            return (
                <>
                  
                <FormControl >
                    <InputLabel id="demo-simple-select-label">notificationGroup</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="notificationGroup"
                        onChange={handleSelectChange}
                        style={{backgroundColor:'white'}}
                    >
                        {userInfo.notificationGroup.map((currentNotificationGroup) => (
                            <MenuItem key={currentNotificationGroup._id} value={currentNotificationGroup._id}>
                                {currentNotificationGroup.name}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                </FormControl>
        </>
            )
        },
        POST:()=>{
            return (
                <>
                     <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="notificationGroup name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                  
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">notificationGroup</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="notificationGroup"
                        onChange={handleSelectChange}
                        style={{backgroundColor:'white'}}
                    >
                        {userInfo.notificationGroup.map((currentNotificationGroup) => (
                            <MenuItem key={currentNotificationGroup._id} value={currentNotificationGroup._id}>
                                {currentNotificationGroup.name}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="notificationGroup name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    notification:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">notificationGroup</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="notificationGroup"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.notificationGroup.map((currentNotificationGroup) => (
                                <MenuItem key={currentNotificationGroup._id} value={currentNotificationGroup._id}>
                                    {currentNotificationGroup.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        {selectedPValue!='' && 
                            <>
                                <InputLabel id="demo-simple-select-label">notification</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="notification"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                        {allNots.filter(currentNotification=>currentNotification.notificationGroupId==selectedPValue).map(currentNotification=>
                                            <MenuItem key={currentNotification._id} value={currentNotification._id}>
                                                {currentNotification.name}
                                            </MenuItem>
                                        )}
                                </Select>
                            </>
                        }
                     </FormControl>
                     <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="notification name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                    </FormControl>
                    <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="description"
                                label="description"
                                name="description"
                                value={formData.description}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">type</InputLabel> 
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='type'
                            value={formData.type}
                            label="notification"
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        >
                                {['daily','weekly','monthly','yearly','once'].map(currentType=>
                                    <MenuItem key={currentType} value={currentType}>
                                        {currentType}
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                    <FormControl>
                        {formData.type!='' && formData.type!='daily' && 
                            <>
                                 <Calendar
                                    date={formData.finishDate===null ? new Date() : formData.finishDate}
                                    onChange={(date=>{
                                       setFormData({...formData,finishDate:date});
                                    })}
                                />
                            </>
                        }
                    </FormControl>
                    <FormControl>
                        <TimeField
                            label="By Hour"
                            value={specHour}
                            onChange={handleSetSpecHour}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                },
                                "& label": {
                                left: "unset",
                                right: "1.75rem",
                                transformOrigin: "right",
                                fontSize: "1rem",
                                "&.Mui-focused": {
                                    color: "black",
                                }
                                },
                                "& legend": {
                                textAlign: "right",
                                fontSize: "0.8rem",
                                },
                            }}
                            
                            format="HH:mm"
                        />
                    </FormControl>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Associated notificationGroup</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPValue}
                                label="Project"
                                onChange={handleSelectPChange}
                                style={{backgroundColor:'white'}}
                                
                            >
                                {userInfo.notificationGroup.map((currentNotificationGroup) => (
                                    <MenuItem key={currentNotificationGroup._id} value={currentNotificationGroup._id}>
                                        {currentNotificationGroup.name}
                                    </MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                        </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                <FormControl >
                        <InputLabel id="demo-simple-select-label">notificationGroup</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="notificationGroup"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                            {userInfo.notificationGroup.map((currentNotificationGroup) => (
                                <MenuItem key={currentNotificationGroup._id} value={currentNotificationGroup._id}>
                                    {currentNotificationGroup.name}
                                </MenuItem>
                            ))}
                        </Select>
                </FormControl>
                <FormControl >
                    {selectedPValue!='' && 
                        <>
                            <InputLabel id="demo-simple-select-label">notification</InputLabel> 
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValue}
                                label="notification"
                                onChange={handleSelectChange}
                                style={{backgroundColor:'white'}}
                            >
                                    {allNots.filter(currentNotification=>currentNotification.notificationGroupId==selectedPValue).map(currentNotification=>
                                        <MenuItem key={currentNotification._id} value={currentNotification._id}>
                                            {currentNotification.name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </>
                    }
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="notification name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                                <TextField
                                    margin="normal"
                                    required
                                    color="secondary"
                                    fullWidth
                                    id="description"
                                    label="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleFormInput}
                                    sx={{
                                        "& input": {
                                            bgcolor:'whitesmoke'
                                        },
                                        "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "1rem",
                                        "&.Mui-focused": {
                                            color: "black",
                                        }
                                        },
                                        "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.8rem",
                                        },
                                    }}
                                    
                                />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">type</InputLabel> 
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.type}
                                label="notification"
                                name='type'
                                onChange={handleFormInput}
                                style={{backgroundColor:'white'}}
                            >
                                    {['daily','weekly','monthly','yearly','once'].map(currentType=>
                                        <MenuItem key={currentType} value={currentType}>
                                            {currentType}
                                        </MenuItem>
                                    )}
                            </Select>
                        </FormControl>
                        <FormControl>
                            {selectedValue!=''&& formData.type!='daily' && 
                                <>
                                    <Calendar
                                        date={getNearestDay(formData.finishDate,formData.type)}
                                        onChange={(date=>{
                                            setFormData({...formData,finishDate:date});
                                        })}
                                    />
                                </>
                            }
                        </FormControl>
                        <FormControl>
                            
                                <TimeField
                                    label="By Hour"
                                    value={specHour}
                                    onChange={handleSetSpecHour}
                                    sx={{
                                        "& input": {
                                            bgcolor:'whitesmoke'
                                        },
                                        "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "1rem",
                                        "&.Mui-focused": {
                                            color: "black",
                                        }
                                        },
                                        "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.8rem",
                                        },
                                    }}
                                    
                                    
                                    format="HH:mm"
                                />
                            
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Associated notificationGroup</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPValue}
                                    label="Project"
                                    onChange={handleSelectPChange}
                                    style={{backgroundColor:'white'}}
                                    
                                >
                                    {userInfo.notificationGroup.map((currentNotificationGroup) => (
                                        <MenuItem key={currentNotificationGroup._id} value={currentNotificationGroup._id}>
                                            {currentNotificationGroup.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    weeklySchedule:{
        DELETE:()=>{
            return (
            <>  
                <FormControl >
                    <InputLabel id="demo-simple-select-label">weeklySchedule</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="weeklySchedule"
                        onChange={handleSelectChange}
                        style={{backgroundColor:'white'}}
                    >
                        {userInfo.weeklySchedule.map((currentWeeklySchedule) => (
                            <MenuItem key={currentWeeklySchedule._id} value={currentWeeklySchedule._id}>
                                {currentWeeklySchedule.name}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                </FormControl>
        </>
            )
        },
        POST:()=>{
            return (
                <>
                   <FormControl>
                        <TextField
                           margin="normal"
                           required
                           color="secondary"
                           fullWidth
                           id="name"
                           value={formData.name}
                           onChange={handleFormInput}
                           label="weeklySchedule name"
                           name="name"
                           sx={{
                               "& input": {
                                   bgcolor:'whitesmoke'
                                 },
                               "& label": {
                                 left: "unset",
                                 right: "1.75rem",
                                 transformOrigin: "right",
                                 fontSize: "1rem",
                                 "&.Mui-focused": {
                                   color: "black",
                                 }
                               },
                               "& legend": {
                                 textAlign: "right",
                                 fontSize: "0.8rem",
                               },
                             }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <div className='flex-even '> 
                            <TimePicker 
                                label="Start time of the day"
                                format="HH:mm"
                                value={store.formDataToElement('timePicker',{byHour:formData.startDayHour})}
                                onChange={(date)=>{
                                    setFormData({...formData,startDayHour:store.elementDataToForm('timePicker',date)})
                                }}
                                sx={{
                                    "& div": {
                                        backgroundColor: "white",
                                      },
                                    "& label": {
                                      left: "unset",
                                      right: "1.75rem",
                                      transformOrigin: "right",
                                      fontSize: "1rem",
                                      "&.Mui-focused": {
                                        color: "black",
                                      }
                                    },
                                    "& legend": {
                                      textAlign: "right",
                                      fontSize: "0.8rem",
                                    },
                                  }}
                                />
                             
                            <TimePicker 
                                label="Start time of the work day"
                                format="HH:mm"
                                value={store.formDataToElement('timePicker',{byHour:formData.startWorkHour})}
                                onChange={(date)=>{
                                    setFormData({...formData,startWorkHour:store.elementDataToForm('timePicker',date)})
                                }}
                                sx={{
                                    "& div": {
                                        backgroundColor: "white",
                                      },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "0.8rem",
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.6rem",
                                    },
                                }}
                                
                            />
                        </div>
                    </FormControl>
                    <FormControl>
                    {(formData.schedule.map((dailySchedule, weekDay) => {
                        const day=store.getDayNameByNumber(weekDay);
                        return (
                            <div className='flex-column' key={weekDay}>
                               <h4>{day}</h4>
                                   { dailySchedule.map((hourlySchedule,index)=>
                                        <>
                                        {index===0 ?
                                            <div className='flex-even '> 
                                                <TimePicker 
                                                    label="Start time of the day"
                                                    format="HH:mm"
                                                    value={store.formDataToElement('timePicker',{byHour:formData.schedule[weekDay][0].startDayHour})}
                                                    onChange={(date)=>{
                                                        // setFormData({...formData,startDayHour:store.elementDataToForm('timePicker',date)})
                                                        let nSchedule=[...formData.schedule];
                                                        nSchedule[weekDay][0].startDayHour=store.elementDataToForm('timePicker',date);
                                                        setFormData({...formData,schedule:nSchedule});
                                                    }}
                                                    sx={{
                                                        "& div": {
                                                            backgroundColor: "white",
                                                        },
                                                        "& label": {
                                                        left: "unset",
                                                        right: "1.75rem",
                                                        transformOrigin: "right",
                                                        fontSize: "1rem",
                                                        "&.Mui-focused": {
                                                            color: "black",
                                                        }
                                                        },
                                                        "& legend": {
                                                        textAlign: "right",
                                                        fontSize: "0.8rem",
                                                        },
                                                    }}
                                                    />
                                                
                                                <TimePicker 
                                                    label="Start time of the work day"
                                                    format="HH:mm"
                                                    value={store.formDataToElement('timePicker',{byHour:formData.schedule[weekDay][0].startWorkHour})}
                                                    onChange={(date)=>{
                                                        // setFormData({...formData,startWorkHour:store.elementDataToForm('timePicker',date)});
                                                        let nSchedule=[...formData.schedule];
                                                        nSchedule[weekDay][0].startWorkHour=store.elementDataToForm('timePicker',date);
                                                        setFormData({...formData,schedule:nSchedule});
                                                    }}
                                                    sx={{
                                                        "& div": {
                                                            backgroundColor: "white",
                                                        },
                                                        "& label": {
                                                        left: "unset",
                                                        right: "1.75rem",
                                                        transformOrigin: "right",
                                                        fontSize: "0.8rem",
                                                        },
                                                        "& legend": {
                                                        textAlign: "right",
                                                        fontSize: "0.6rem",
                                                        },
                                                    }}
                                                    
                                                />
                                            </div>
                                            :
                                            <div className='flex-even'>
                                                    <Select 
                                                        value={hourlySchedule.key}
                                                        onChange={e=>{
                                                            const selectedKey=e.target.value
                                                            let nSchedule=[...formData.schedule];
                                                            nSchedule[weekDay][index].key=selectedKey;
                                                            setFormData({...formData,schedule:nSchedule});
                                                        }}   
                                                        sx={{"& div": {backgroundColor: "white"}}} label='Routine'>
                                                    <MenuItem  value="personal">
                                                        <em>Personal</em>
                                                    </MenuItem>
                                                    {userInfo.project.map(project=><MenuItem value={`${project._id}`}>{project.name}</MenuItem>)}
                                                    </Select>
                                                    <FormControl>
                                                    <div className='flex-even mid'> 
                                                        <TextField
                                                                label="Hours"
                                                                type="number"
                                                                value={`${hourlySchedule.hours[0]}`}
                                                                onChange={e=>{
                                                                    const value=(e.target.value).trim().replace(/^0+/, '');
                                                                    let nSchedule=[...formData.schedule];
                                                                    nSchedule[weekDay][index].hours[0]=Number(value);
                                                                    setFormData({...formData,schedule:nSchedule})
                                                                }}
                                                                inputProps={{
                                                                min: 0,
                                                                max: 23,
                                                                step: 1,
                                                                }}
                                                                sx={{
                                                                    "& div": {
                                                                        backgroundColor: "white",
                                                                      },
                                                                    "& input": {
                                                                        width: "7ch", // Set width to 3 characters
                                                                    },
                                                                    "& label": {
                                                                        left: "unset",
                                                                        right: "1.75rem",
                                                                        transformOrigin: "right",
                                                                        fontSize: "0.8rem",
                                                                    },
                                                                    "& legend": {
                                                                        textAlign: "right",
                                                                        fontSize: "0.6rem",
                                                                    },
                                                                    }}
                                                            />
                                                            <div className='flex-even mid'> <span>:</span></div>
                                                            <TextField
                                                                label="Minutes"
                                                                type="number"
                                                                value={`${hourlySchedule.hours[1]}`}
                                                                onChange={e=>{
                                                                    const value=(e.target.value).trim().replace(/^0+/, '');;
                                                                    let nSchedule=[...formData.schedule];
                                                                    nSchedule[weekDay][index].hours[1]=Number(value);
                                                                    setFormData({...formData,schedule:nSchedule})
                                                                }}
                                                                inputProps={{
                                                                min: 0,
                                                                max: 59,
                                                                step: 1,
                                                                }}
                                                                sx={{
                                                                    "& div": {
                                                                        backgroundColor: "white",
                                                                      },
                                                                    "& input": {
                                                                        width: "7ch", // Set width to 3 characters
                                                                    },
                                                                    "& label": {
                                                                        left: "unset",
                                                                        right: "1.75rem",
                                                                        transformOrigin: "right",
                                                                        fontSize: "0.8rem",
                                                                    },
                                                                    "& legend": {
                                                                        textAlign: "right",
                                                                        fontSize: "0.6rem",
                                                                    },
                                                                    }}
                                                            />
                                                        </div>
                                                    </FormControl>
                                            </div>
                                        }
                                        </>
                                    )}
                                    <div className='flex-even end schedule_add_remove-container'>
                                        <InfoHover onClick={()=>removeLastOneProject(weekDay)} classes={'ws_add-project-wrapper remove'} info="Remove Last Class/Project">
                                            <RemoveCircleIcon/>
                                        </InfoHover>
                                        <InfoHover onClick={()=>addProject(weekDay)} classes={'ws_add-project-wrapper'} info="Add Class/Project">
                                            <AddCircleIcon/>
                                        </InfoHover>
                                   
                                    </div>
                            </div>
                        )
                    }))}
                    </FormControl>
                    <FormControlLabel
                        label='Set Active'
                        labelPlacement='top'
                        control={<Switch  value={formData.isActive} checked={formData.isActive} name='isActive' onChange={handleFormInput}/>}
                    />
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                    
                </>
            )
        },
        PUT:()=>{
            return (
            <>
               <FormControl >
                    <InputLabel id="demo-simple-select-label">weeklySchedule</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        label="weeklySchedule"
                        onChange={handleSelectChange}
                        style={{backgroundColor:'white'}}
                    >
                        {userInfo.weeklySchedule.map((currentWeeklySchedule) => (
                            <MenuItem key={currentWeeklySchedule._id} value={currentWeeklySchedule._id}>
                                {currentWeeklySchedule.name}
                            </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {selectedValue!='' &&
                    <>
                        <FormControl>
                        <TextField
                           margin="normal"
                           required
                           color="secondary"
                           fullWidth
                           id="name"
                           label="weeklySchedule name"
                           name="name"
                           value={formData.name}
                           onChange={handleFormInput}
                           sx={{
                               "& input": {
                                   bgcolor:'whitesmoke'
                                 },
                               "& label": {
                                 left: "unset",
                                 right: "1.75rem",
                                 transformOrigin: "right",
                                 fontSize: "1rem",
                                 "&.Mui-focused": {
                                   color: "black",
                                 }
                               },
                               "& legend": {
                                 textAlign: "right",
                                 fontSize: "0.8rem",
                               },
                             }}
                              
                        />
                        </FormControl>
                        <FormControl>
                        <FormControlLabel
                                label='Set Active'
                                labelPlacement='top'
                                control={<Switch  value={formData.isActive}  checked={formData.isActive} name='isActive' onChange={handleFormInput}/>}
                            />
                            
                        </FormControl>
                        <FormControl>
                            <div className='flex-even '> 
                                <TimePicker 
                                    label="Start time of the day"
                                    format="HH:mm"
                                    value={store.formDataToElement('timePicker',{byHour:formData.startDayHour})}
                                    onChange={(date)=>{
                                        setFormData({...formData,startDayHour:store.elementDataToForm('timePicker',date)})
                                    }}
                                    sx={{
                                        "& div": {
                                            backgroundColor: "white",
                                        },
                                        "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "1rem",
                                        "&.Mui-focused": {
                                            color: "black",
                                        }
                                        },
                                        "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.8rem",
                                        },
                                    }}
                                    />
                                
                                <TimePicker 
                                    label="Start time of the work day"
                                    format="HH:mm"
                                    value={store.formDataToElement('timePicker',{byHour:formData.startWorkHour})}
                                    onChange={(date)=>{
                                        setFormData({...formData,startWorkHour:store.elementDataToForm('timePicker',date)})
                                    }}
                                    sx={{
                                        "& div": {
                                            backgroundColor: "white",
                                        },
                                        "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "0.8rem",
                                        },
                                        "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.6rem",
                                        },
                                    }}
                                    
                                />
                            </div>
                        </FormControl>
                        <FormControl>
                        {(formData.schedule.map((dailySchedule, weekDay) => {
                            const dayString=formData.schedule.length===1? 'Schedule':store.getDayNameByNumber(weekDay);
                            return (
                                <div className='flex-column' key={weekDay}>
                                <h4>{dayString}</h4>
                                    { dailySchedule.map((hourlySchedule,index)=>
                                            <>
                                            {index===0 ?
                                            <div className='flex-even '> 
                                                <TimePicker 
                                                    label="Start time of the day"
                                                    format="HH:mm"
                                                    value={store.formDataToElement('timePicker',{byHour:formData.schedule[weekDay][0].startDayHour})}
                                                    onChange={(date)=>{
                                                        // setFormData({...formData,startDayHour:store.elementDataToForm('timePicker',date)})
                                                        let nSchedule=[...formData.schedule];
                                                        nSchedule[weekDay][0].startDayHour=store.elementDataToForm('timePicker',date);
                                                        setFormData({...formData,schedule:nSchedule});
                                                    }}
                                                    sx={{
                                                        "& div": {
                                                            backgroundColor: "white",
                                                        },
                                                        "& label": {
                                                        left: "unset",
                                                        right: "1.75rem",
                                                        transformOrigin: "right",
                                                        fontSize: "1rem",
                                                        "&.Mui-focused": {
                                                            color: "black",
                                                        }
                                                        },
                                                        "& legend": {
                                                        textAlign: "right",
                                                        fontSize: "0.8rem",
                                                        },
                                                    }}
                                                    />
                                                
                                                <TimePicker 
                                                    label="Start time of the work day"
                                                    format="HH:mm"
                                                    value={store.formDataToElement('timePicker',{byHour:formData.schedule[weekDay][0].startWorkHour})}
                                                    onChange={(date)=>{
                                                        // setFormData({...formData,startWorkHour:store.elementDataToForm('timePicker',date)});
                                                        let nSchedule=[...formData.schedule];
                                                        nSchedule[weekDay][0].startWorkHour=store.elementDataToForm('timePicker',date);
                                                        setFormData({...formData,schedule:nSchedule});
                                                    }}
                                                    sx={{
                                                        "& div": {
                                                            backgroundColor: "white",
                                                        },
                                                        "& label": {
                                                        left: "unset",
                                                        right: "1.75rem",
                                                        transformOrigin: "right",
                                                        fontSize: "0.8rem",
                                                        },
                                                        "& legend": {
                                                        textAlign: "right",
                                                        fontSize: "0.6rem",
                                                        },
                                                    }}
                                                    
                                                />
                                            </div>
                                            :
                                                <div className='flex-even'>
                                                        <Select value={hourlySchedule.key} 
                                                                onChange={e=>{
                                                                    const selectedKey=e.target.value
                                                                    let nSchedule=[...formData.schedule];
                                                                    nSchedule[weekDay][index].key=selectedKey;
                                                                    setFormData({...formData,schedule:nSchedule});
                                                                }}
                                                                sx={{"& div": {backgroundColor: "white"}}}  label='Routine'>
                                                            <MenuItem  value="personal">
                                                                <em>Personal</em>
                                                            </MenuItem>
                                                            {userInfo.project.map(project=><MenuItem value={`${project._id}`}>{project.name}</MenuItem>)}
                                                        </Select>
                                                        
                                                        <div className='flex-even mid'> 
                                                            <TextField
                                                                    label="Hours"
                                                                    type="number"
                                                                    value={`${hourlySchedule.hours[0]}`}
                                                                    onChange={e=>{
                                                                        const value=(e.target.value).trim().replace(/^0+/, '');;
                                                                        let nSchedule=[...formData.schedule];
                                                                        nSchedule[weekDay][index].hours[0]=Number(value);
                                                                        setFormData({...formData,schedule:nSchedule})
                                                                    }}
                                                                    inputProps={{
                                                                    min: 0,
                                                                    max: 23,
                                                                    step: 1,
                                                                    }}
                                                                    sx={{
                                                                        "& div": {
                                                                            backgroundColor: "white",
                                                                        },
                                                                        "& input": {
                                                                            width: "7ch", // Set width to 3 characters
                                                                        },
                                                                        "& label": {
                                                                            left: "unset",
                                                                            right: "1.75rem",
                                                                            transformOrigin: "right",
                                                                            fontSize: "0.8rem",
                                                                        },
                                                                        "& legend": {
                                                                            textAlign: "right",
                                                                            fontSize: "0.6rem",
                                                                        },
                                                                        }}
                                                                />
                                                                <div className='flex-even mid'> <span>:</span></div>
                                                                <TextField
                                                                    label="Minutes"
                                                                    type="number"
                                                                    value={`${hourlySchedule.hours[1]}`}
                                                                    onChange={e=>{
                                                                        const value=(e.target.value).trim().replace(/^0+/, '');;
                                                                        let nSchedule=[...formData.schedule];
                                                                        nSchedule[weekDay][index].hours[1]=Number(value);
                                                                        setFormData({...formData,schedule:nSchedule})
                                                                    }}
                                                                    inputProps={{
                                                                    min: 0,
                                                                    max: 59,
                                                                    step: 1,
                                                                    }}
                                                                    sx={{
                                                                        "& div": {
                                                                            backgroundColor: "white",
                                                                        },
                                                                        "& input": {
                                                                            width: "7ch", // Set width to 3 characters
                                                                        },
                                                                        "& label": {
                                                                            left: "unset",
                                                                            right: "1.75rem",
                                                                            transformOrigin: "right",
                                                                            fontSize: "0.8rem",
                                                                        },
                                                                        "& legend": {
                                                                            textAlign: "right",
                                                                            fontSize: "0.6rem",
                                                                        },
                                                                        }}
                                                                />
                                                            </div>
                                                        
                                                </div>
                                            }
                                            </>
                                            
                                        )}
                                </div>
                            )
                        }))}
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >edit</LoadingButton>
                        </FormControl>
                    </>
                }
            </>
            )
        }
    },
    income:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Income</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Idea"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.income.map((currentIncome) => (
                                <MenuItem key={currentIncome._id} value={currentIncome._id}>
                                    {currentIncome.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="Income name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Associated Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.bankId}
                            label="banks"
                            name='bankId'
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                            
                        >
                           {userInfo.bank.map((currentBank) => (
                                <MenuItem key={currentBank._id} value={currentBank._id}>
                                    {currentBank.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Coin</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="coin"
                            onChange={handleFormInput}
                            value={formData.coin}
                        >
                            <FormControlLabel value="shekel" control={<Radio />} label="Shekel" />
                            <FormControlLabel value="dollar" control={<Radio />} label="Dollar" />
                            <FormControlLabel value="euro" control={<Radio />} label="Euro" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="averagePaycheck"
                            label="Average Paycheck"
                            name="averagePaycheck"
                            value={formData.averagePaycheck}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
                <>
                     <FormControl >
                        <InputLabel id="demo-simple-select-label">Income</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Idea"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.income.map((currentIncome) => (
                                <MenuItem key={currentIncome._id} value={currentIncome._id}>
                                    {currentIncome.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    {selectedValue!='' && chosenForm.income.POST()}
                </>
            )
        }
    },
    paycheck:{
        DELETE:()=>{
            return (
                <>
                    <FormControl >
                    <InputLabel id="demo-simple-select-label">Income</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Income"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                             {userInfo.income.map((currentIncome) => (
                                <MenuItem key={currentIncome._id} value={currentIncome._id}>
                                    {currentIncome.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        {selectedPValue!='' && 
                         <>
                            <InputLabel id="demo-simple-select-label">Paycheck</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="Card"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.paycheck.filter(currentPaychek=>currentPaychek.incomeId==selectedPValue).map(currentPaychek=>
                                    <MenuItem key={currentPaychek._id} value={currentPaychek._id}>
                                        {`${currentPaychek.name} -  ${currentPaychek.name}`}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                    </FormControl>
                     <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >delete</LoadingButton>
                    </FormControl>
                </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Income</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Income"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                             {userInfo.income.map((currentIncome) => (
                                <MenuItem key={currentIncome._id} value={currentIncome._id}>
                                    {currentIncome.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    {selectedPValue!='' &&
                    <>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="money"
                                label="Money"
                                name="money"
                                value={formData.money}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                        </FormControl>
                    </>
                    }
                </>
            )
        },
        PUT:()=>{
            return (
                <>
                    <FormControl >
                    <InputLabel id="demo-simple-select-label">Income</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPValue}
                            label="Income"
                            onChange={handleSelectPChange}
                            style={{backgroundColor:'white'}}
                            
                        >
                             {userInfo.income.map((currentIncome) => (
                                <MenuItem key={currentIncome._id} value={currentIncome._id}>
                                    {currentIncome.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        {selectedPValue!='' && 
                         <>
                            <InputLabel id="demo-simple-select-label">Paycheck</InputLabel> 
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="Card"
                                    onChange={handleSelectChange}
                                    style={{backgroundColor:'white'}}
                                >
                                {userInfo.paycheck.filter(currentPaychek=>currentPaychek.incomeId==selectedPValue).map(currentPaychek=>
                                    <MenuItem key={currentPaychek._id} value={currentPaychek._id}>
                                        {`${currentPaychek.name} -  ${currentPaychek.name}`}
                                    </MenuItem>
                                    )}
                                </Select>
                            </>
                        }
                    </FormControl>
                    {selectedValue!='' && chosenForm.paycheck.POST()}
                </>
            )
        }
    },
    FinancialConduct:{
        DELETE:()=><></>,
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="on what?"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            type="number"
                            id="price"
                            label="What's the Price"
                            name="cost"
                            value={formData.cost}
                            onChange={handleFormInput}
                            autoFocus
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                  },
                                "& label": {
                                  left: "unset",
                                  right: "1.75rem",
                                  transformOrigin: "right",
                                  fontSize: "1rem",
                                  "&.Mui-focused": {
                                    color: "black",
                                  }
                                },
                                "& legend": {
                                  textAlign: "right",
                                  fontSize: "0.8rem",
                                },
                              }}
                              
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Coin</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="coin"
                            onChange={handleFormInput}
                            value={formData.coin}
                        >
                            <FormControlLabel value="shekel" control={<Radio />} label="Shekel" />
                            <FormControlLabel value="dollar" control={<Radio />} label="Dollar" />
                            <FormControlLabel value="euro" control={<Radio />} label="Euro" />
                        </RadioGroup>
                    </FormControl>
                    <FormControlLabel
                                label='Common say its an Income'
                                labelPlacement='top'
                                control={<Switch style={formData.isIncome ? {color:'blue'} : {}}  value={formData.isIncome} checked={formData.isIncome} name='isIncome' onChange={handleFormInput}/>}
                            />
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="type"
                                onChange={handleFormInput}
                                value={formData.type}
                            >
                                <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
                                <FormControlLabel value="leisure" control={<Radio />} label="Leisure" />
                                <FormControlLabel value="lifestyle" control={<Radio />} label="Life Style" />
                                <FormControlLabel value="investment" control={<Radio />} label="Investment" />
                            </RadioGroup>
                        </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" >Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=><></>,
    },
  }

  const handleSubmit=(e)=>{
    e.preventDefault();
    const unvalidKeys=store.formValidation(dbtype,formData);
    unvalidKeys.length===0 ? submitForm(dbtype,method,formData) : setError();
  }
  
  return (
    <div dir='ltr'>
        <form  onSubmit={handleSubmit}>
            {chosenForm[dbtype][method]()}
        </form>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        loader:state.userInfo.loader,
        userId:state.authUser._id
      };
};
const mapDispatchToProps = (dispatch) => {
      return {
       submitForm:(dbtype,method,data)=>dispatch(setDataReq(dbtype,method,data)),
       setError:(message)=>dispatch(setByKey('isError',{status:true})),
       getNearestDay:(date,type) =>{
         // Clone the input date to avoid modifying the original object
         const clonedDate = new Date(date);
         // Get the current date
         const currentDate = new Date();

        switch (type){
            case 'weekly':
                // Calculate the difference in days between the current day and the target day
                const dayDifference = (clonedDate.getDay() - currentDate.getDay() + 7) % 7;
                // Clone the current date and set it to the nearest occurrence of the target day
                
                currentDate.setDate(currentDate.getDate() + dayDifference);
                break;
            case 'monthly':
                currentDate.getDate()>clonedDate.getDate() && currentDate.setMonth(currentDate.getMonth()+1);
                currentDate.setDate(clonedDate.getDate());
                break;
           
            case 'yearly':
                currentDate.getMonth()>clonedDate.getMonth() && currentDate.setFullYear(currentDate.getFullYear()+1);
                currentDate.setDate(clonedDate.getDate());
                currentDate.setMonth(clonedDate.getMonth());
                break;
               
        }
        
        return currentDate;
      }
      };
};

export default connect(mapStateToProps,mapDispatchToProps)(ChosenForm);