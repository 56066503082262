import Task from "./Task";
import DropDown from "./DropDown";
import { useEffect ,useState,useRef} from "react";
import {connect} from 'react-redux';
import {setFinishTasksToday} from '../redux/actions/userInfoActions';
import store from "../store";

const Tasks = ({tasks,isHomePage,classes,finishTasksToday,initFinishedToday,getFinishedToday,projectStepId,byHours}) =>{
        // viewed tasks are are the tasks of the day ragardless of status
        const [viewedTasks,setViewedTasks]=useState([]);
        const isInitialized = useRef(false);
        useEffect(()=>{
            if (!isInitialized.current) {
                initFinishedToday();
                isInitialized.current = true;
            }
            else{

                let allViewTasks=[];

                if(finishTasksToday && finishTasksToday.length>0){
                    let finishTodayArrObj=getFinishedToday(finishTasksToday,projectStepId);
                    allViewTasks=[...finishTodayArrObj];
                    
                }
                if(byHours){
                    let {resultTasks}=store.getTasksWithinTimePeriod(byHours,tasks);
                    allViewTasks=[...allViewTasks,...resultTasks];
                }
                else{

                    allViewTasks=[...allViewTasks,...tasks];
                }
                setViewedTasks(allViewTasks);
            }
           
    
        },[finishTasksToday,tasks])
    return (
        <>
        {isHomePage ? 
             <div className='sTasks-container'>
               {Array.isArray(viewedTasks) && viewedTasks.map((task,index)=><Task key={index} index={index} task={task} isHomePage/>)}
            </div>
            :
            <DropDown  childrenView={4} classes={`tasks-container`} cssAffectsCounter={25}>
                <span style={{height:'0px'}}></span>
                <div className="children-container">
                    {viewedTasks.map((task,index) => <Task key={index} index={index} task={task} />)}
                </div>
                <div id="chosenEvent" className="tasks-displayer">V</div>
            </DropDown>
        }
        </>
    )
} 
const mapStateToProps = (state) => {
    return {
        finishTasksToday:state.userInfo.finishTasksToday,
        getFinishedToday:(finishedToday,projectStepId)=>state.userInfo.task.filter(ts=> ts['projectStepId']===projectStepId &&finishedToday.includes(ts['_id'])).sort((a,b)=>a.order-b.order),
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        initFinishedToday:()=>dispatch(setFinishTasksToday()),
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(Tasks);
