import {
    LOGOUT_SUCCESS,RESET_IS_ERROR,SET_BY_KEY,
    FETCH_DATA_REQUEST,FETCH_DATA_SUCCESS,FETCH_DATA_FAILURE,
    FETCH_USER_INFO,FETCH_USER_INFO_SUCCESS,FETCH_USER_INFO_FAILURE,
    SET_DATA_EXAMPLE,SET_DATA_EXAMPLE_SUCCESS,SET_DATA_EXAMPLE_FAILURE,
    SET_DATA_REQUEST,SET_DATA_SUCCESS,SET_DATA_FAILURE,SET_BY_KEY_AND_ID,SET_ORDER_BY_DBTYPE,
    SET_NOTIFICATIONS_POPUP_PERIODIC,
    SET_BACKEND,SET_BACKEND_SUCCESS,SET_BACKEND_FAILURE,SET_SUCCESS_API,SET_BY_KEY_FIXED,SET_TASKS_FINISH_TODAY,SET_TASKS_FINISH_TODAY_SUCCESS
} from "../types/userInfoTypes";


export const successApi=(message)=>{
    return{
        type:SET_SUCCESS_API,
        payload:message
    }
}

export const fetchDataReq=(dbType)=>{
    return {
        type:FETCH_DATA_REQUEST,
        payload:dbType
    }
}
export const fetchDataSuccess=(dbType,data)=>{
    return {
        type:FETCH_DATA_SUCCESS,
        payload:data,
        dbType
    }
}
export const fetchDataError=(message)=>{
    return{
        type:FETCH_DATA_FAILURE,
        payload:message
    }
}


export const fetchUserInfo=()=>{
    return{
        type:FETCH_USER_INFO
    }
}
export const fetchUserInfoSuccess=userInfo=>{
    return{
        type:FETCH_USER_INFO_SUCCESS,
        payload:userInfo
    }
}
export const fetchUserInfoFailure=err=>{
    return{
        type:FETCH_USER_INFO_FAILURE,
        payload:err
    }
}


export const setExample=(dbtype,value)=>{
    return {
        type: SET_DATA_EXAMPLE,
        dbtype,
        value
    }
}
export const setExampleSuccess=(value)=>{
    return {
        type:SET_DATA_EXAMPLE_SUCCESS,
        payload:value
    }
}
export const setExampleFailure=()=>{
    return {
        type: SET_DATA_EXAMPLE_FAILURE
    }
}


export const setDataReq=(dbtype,method,data)=>{
    return {
        type:SET_DATA_REQUEST,
        dbtype,
        method,
        data
    }
}
export const setDataSuccess=(dbtype,method,data)=>{
    return {
        type:SET_DATA_SUCCESS,
        ...(dbtype && { dbtype }),
        ...(method && { method }),
        ...(data && { payload: data }),
    }
}
export const setDataFailure=()=>{
    return {
        type:SET_DATA_FAILURE

    }
}


export const setByKey=(key,value)=>{
    return {
        type:SET_BY_KEY,
        key,
        payload:value
    }
}
export const setByKeyFixed=(key,value)=>{
    return {
        type:SET_BY_KEY_FIXED,
        key,
        payload:value
    }
}
export const setValueByKeyAndId=(key,_id,value)=>{
    return {
        type:SET_BY_KEY_AND_ID,
        key,
        _id,
        payload:value
    }
}
export const setOrderByDbtype=(key,value)=>{
    return {
        type:SET_ORDER_BY_DBTYPE,
        key,
        payload:value
    }
}
export const resetIsError=()=>{
    return {
        type:RESET_IS_ERROR
    }
}

export const setNotificationsPopUpPeriodic=(periodicObj)=>{
    return {
        type:SET_NOTIFICATIONS_POPUP_PERIODIC,
        payload:periodicObj
    }
}

export const setBackEnd=(dbtype,path,method,data,isResponse=false,key_andIdObj)=>{
    return{
        type:SET_BACKEND,
        dbtype,
        path,
        method,
        data,
        isResponse,
        key_andIdObj
    }

}
export const setBackEnd_Success=()=>{
    return{
        type:SET_BACKEND_SUCCESS
    }
}
export const setBackEnd_Failure=()=>{
    return{
        type:SET_BACKEND_FAILURE
    }
}

export const setFinishTasksToday=(data)=>{
    return{
        type:SET_TASKS_FINISH_TODAY,
        payload:data || []
    }

}

export const logout=()=>{
    return {
        type:LOGOUT_SUCCESS
    }
}