
import {Routes, Route,useNavigate } from "react-router-dom";
import { Header,DailyNotifications,Loader,Alerts,TaskTimer,NotificationsPopUp,WorkoutDone} from "./components";
import {HomePage,Ideas,Personal,Projects,Login,AdminPanel} from "./pages";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { connect } from "react-redux";
import {isAuthRequest,gotUserData} from "./redux/actions/authActions"
import {setFinishTasksToday} from './redux/actions/userInfoActions';



// SKELETON 
function App({isAuth,authUser,userInfo,onTask,gotUserInfo,initFinishedToday}) {

  const navigate = useNavigate();
useEffect(()=>{
  !authUser.isAuth && isAuth();
  authUser.isAuth && navigate('/');

},[authUser.isAuth])
useEffect(()=>{
  if(userInfo.bank!=false){
    gotUserInfo();
  }
 

},[userInfo])


// material ui colors provider
const theme = createTheme({
  palette: {
    default:{
      main:'#FFFFFF',
    },
    primary: {
      main: '#8C696', // Change the primary color
    },
    secondary: {
      main: '#FFFFFF', // Change the secondary color
    },
    // direction: "rtl"
  },
});



  return (
    <div className="App" >
      <Alerts/>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
        {authUser.isAuth && userInfo.project===false && <Loader/>}
        {authUser.loader ? <Loader/> : authUser.isAuth && Array.isArray(userInfo.project) ?
          <ThemeProvider theme={theme}>
            <Header/> 
            <DailyNotifications/>
            {userInfo.notification!=false && <NotificationsPopUp/>}
            {onTask['_id'] && <TaskTimer/>}
            {/* <WorkoutDone/> */}
            
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/projects" element={<Projects/>} />
              <Route path="/ideas" element={<Ideas/>} />
              <Route path="/personal" element={<Personal/>} />
              <Route path="/admin" element={<AdminPanel/>} />
            </Routes>
          </ThemeProvider>
          :
          <>
            <Routes>
            <Route path="*" element={<Login/>} />
            </Routes>
          </>
        }
      
      </LocalizationProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    userInfo:state.userInfo,
    onTask:state.onTask,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isAuth: () => dispatch(isAuthRequest()),
    gotUserInfo:()=>dispatch(gotUserData()),
    initFinishedToday:()=>dispatch(setFinishTasksToday()),
  };
};
export default  connect(mapStateToProps,mapDispatchToProps)(App);
