import './style.css';
const BasicTable = ({rowPerPage=null,tableData}) => {
  
    return (
        <div className='basic_table-container'>
            <h1>{tableData.header}</h1>
            <table className='theme'>
                    <thead>
                        <tr>
                            {tableData.headCells.map(headCell=><th>{headCell.label}</th>)}
                            <th>Edit</th>
                        </tr>
                    </thead>           
                    <tbody>
                        {(new Array(rowPerPage ? rowPerPage : tableData.dataList.length).fill(null)).map((_,index)=>
                            <tr>
                                {tableData.headCells.map(headCell=><td>{tableData.dataList[index][headCell.key]}</td>)}
                                <td>
                                    <span>Add Edit Icons</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
        </div>
    );
};

export default BasicTable;