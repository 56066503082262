import { useState,useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, Paper, Box, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {loginRequest} from '../redux/actions/authActions';
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Form } from '../components';
import {useNavigate } from "react-router-dom";


const defaultTheme = createTheme();



 const Login=({loginReq,loader,isAuth})=> {
    
    const [username,setUsername]=useState('');
    const [password,setPassword]=useState('');
    const [register,setRegister]=useState(false);
    const navigate = useNavigate(); 
    const handleSubmit = (e) => {
        e.preventDefault(); 
        loginReq({username,password});
    };
    useEffect(()=>{
      isAuth && navigate('/');
    },[isAuth])
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'success.light' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              התחברות
            </Typography>
            <Box component="form" className='material-form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                color="success"
                fullWidth
                id="username"
                label="שם משתמש"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e)=>setUsername(e.target.value)}
                autoFocus
                sx={{
                  "& label": {
                    left: "unset",
                    right: "1.75rem",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                  },
                  "& legend": {
                    textAlign: "right",
                    fontSize: "0.6rem",
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                color="success"
                fullWidth
                name="password"
                label="סיסמא"
                type="password"
                id="password"
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                autoComplete="current-password"
                sx={{
                  "& label": {
                    left: "unset",
                    right: "1.75rem",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                  },
                  "& legend": {
                    textAlign: "right",
                    fontSize: "0.6rem",
                  },
                }}
              />
              <LoadingButton
                type="submit"
                fullWidth
                className='theme-color'
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={loader}
              >
                 login
              </LoadingButton>
             <Button type='button' onClick={e=>setRegister(true)}>register</Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?Business)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
      {register && <Form dbtype='user' method='POST' setFormMethod={setRegister} />}
    </ThemeProvider>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.authUser.loader,
    isAuth:state.authUser.isAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginReq: (credentials) => dispatch(loginRequest(credentials)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Login);