import store from "../store";

const api= {
    newData: (dbtype, data) => {
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/new${dbtype}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    },
    get:(dbtype,path)=>{
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${path}`);
    },
    update: (dbtype, id, data) => {
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    },
    getOne: (dbtype, id) => {
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${id}`, {
        method: 'GET',
      });
    },
    getAll: (dbtype) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/getAll`, {
          method: 'GET',
        });
    },
    getAllByFactor:(dbtype,Factor)=>{
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/getAll/${Factor}`, {
        method: 'GET',
      });
    },
    delete: (dbtype, id) => {
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${id}`, {
        method: 'DELETE',
      });
    },
    post:(dbtype,path,data)=>{
      return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    },
    getUserInfo:()=>{
      return fetch(`${process.env.REACT_APP_BACKEND_API}/user/getRequired`);
    },
    logout:()=>{
      return fetch(`${process.env.REACT_APP_BACKEND_API}/auth/logout`);
    },
    setExampleData:(dbtype,value)=>{
      return dbtype ? fetch(`${process.env.REACT_APP_BACKEND_API}${dbtype}/setExample/${value}`) : fetch(`${process.env.REACT_APP_BACKEND_API}/setExample/${value}`) 
    },
    setData:function(dbtype,method,data){
      switch (method) {
        case 'POST':
          data.hasOwnProperty('_id') && delete data['_id'];
          return this.newData(dbtype,data);
        case 'PUT':
          data.hasOwnProperty('notifications') && delete data['notifications'];
          return this.update(dbtype,data._id,data);
        case 'DELETE':
          return this.delete(dbtype,data._id);
        default:
         return false;
      }
    },
    isData_ObjValid:(dbtype,method,data)=>{
      const validObjKeys=Object.keys(store.formKeys(dbtype));
      const unneededKeys = validObjKeys.filter(key => !validObjKeys.includes(key));
      for(const unneededKey of unneededKeys){
         data.hasOwnProperty(unneededKey) && delete data[unneededKey];
      }
    },
  };
  

  api.setData = api.setData.bind(api);
  export default api;