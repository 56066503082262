
const AddOptions=({route,setFormMethod,isOn})=>{
    let options;
    const off = {
        maxWidth: '0px',
        maxHeight: '0px',
        overflowX:'hidden',
        border: '0'
      };
    const on={
        maxWidth: '5000px',
        maxHeight: '5000px',
    }
    const setFormState=(formInfo)=>{
        setFormMethod(formInfo);
    }
    if(route==='projects'){
        options = (
            <div style={isOn ? on :off} className="add-options-container">
                
                <div className="add-option" >
                    <span className="add-option-header">
                        project
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['project','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['project','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['project','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                
                <div className="add-option">
                    <span className="add-option-header">
                        Project-S
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['projectStep','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['projectStep','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['projectStep','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                
                <div className="add-option" >
                    <span className="add-option-header">
                        Task
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['task','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['task','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['task','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
            
            </div>
          );
    }
    if(route==='ideas'){
        options = (
            <div style={isOn ? on :off} className="add-options-container">
               <div className="add-option" >
                <span className="add-option-header">
                    idea
                </span>
                <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['idea','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['idea','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['idea','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                {/* <div className="add-option" >
                <span className="add-option-header">
                    notes
                </span>
                <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['notes','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['notes','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['notes','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div> */}
            </div>
          );
    }
    if(route==='personal'){
        options = (
            <div style={isOn ? on :off} className="add-options-container">
                
                <div className="add-option" >
                    <span className="add-option-header">
                        notificatioin-G
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['notificationGroup','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['notificationGroup','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['notificationGroup','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                
                <div className="add-option" >
                    <span className="add-option-header">
                        notificatioin
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['notification','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['notification','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['notification','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>

                <div className="add-option" >
                    <span className="add-option-header">
                        Bank
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['bank','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['bank','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['bank','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>

                <div className="add-option" >
                    <span className="add-option-header">
                        card
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['card','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['card','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['card','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                
                <div className="add-option" >
                    <span className="add-option-header">
                        expenses
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['expense','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['expense','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['expense','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>

                <div className="add-option" >
                    <span className="add-option-header">
                        Income
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['income','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['income','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['income','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>

                <div className="add-option" >
                    <span className="add-option-header">
                        paycheck
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['paycheck','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['paycheck','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['paycheck','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                
                <div className="add-option" >
                    <span className="add-option-header">
                        workout
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['workoutPlan','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['workoutPlan','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['workoutPlan','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>
                
                <div className="add-option" >
                    <span className="add-option-header">
                        weekly
                    </span>
                    <span className="add-action-container">
                        <span className="action">
                            <i onClick={()=>setFormState(['weeklySchedule','POST'])} className="bi bi-plus-circle-fill"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['weeklySchedule','DELETE'])} className="bi bi-trash"></i>
                        </span>
                        <span className="action">
                            <i onClick={()=>setFormState(['weeklySchedule','PUT'])} className="bi bi-pen"></i>
                        </span>
                    </span>
                </div>

            </div>
          );
    }

    return (
    <>
    {options}
    </>
    )
}

export default AddOptions;