import React from 'react';
import {connect} from 'react-redux';
import { Switch } from "@mui/material";
import {setExample} from '../../../redux/actions/userInfoActions';
const index = ({setExampleData,exampleData}) => {
    return (
        <div className="exampleData-container container-boxShadow">
            <span> Use DB</span> 
            <Switch color='warning' checked={!exampleData} onChange={()=>setExampleData(!exampleData)}/>
          </div>
    );
};
const mapStateToProps = (state) => {
return {
    exampleData: state.userInfo.isExample,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    setExampleData:(value)=>dispatch(setExample(null,value? 'true' : 'false')),
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(index);