import { Chart,ScheduledDay,YearlyTimeLine} from "../components";
import {connect} from 'react-redux';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
const HomePage =({authUser})=>{
    return (
    <div className="page-container">
        <h1>Hello {authUser.username==='ceo'? 'CEO' : `${authUser.firstName} ${authUser.lastName}`}</h1>
        <h1>Welcome Home</h1>
        
        <Carousel infiniteLoop autoPlay>
            <YearlyTimeLine/>
            <Chart/>
        </Carousel>
        <ScheduledDay/>

    </div>
    )
}
const mapStateToProps = (state) => {
    return {
        authUser:state.authUser
      };
    };

export default connect(mapStateToProps)(HomePage);