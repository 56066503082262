import React, { useState } from "react";
function convertArrayToObjects(stringArray) {
  const arrayOfObjects = stringArray.map(text => ({ text, visible: true }));
  arrayOfObjects.push({ text: "", visible: false });
  return arrayOfObjects;
}
const Note = ({ formData , setFormData }) => { 
  const [notes, setNotes] = useState(convertArrayToObjects(formData.notes))
  const handleCheckboxChange = (index) => {
    const updatedNotes = [...notes];
    updatedNotes[index].visible = !updatedNotes[index].visible; // Toggle visibility

    // If the last checkbox is checked, add a new row
    if(updatedNotes[index].visible){
      if (index === notes.length - 1) {
        updatedNotes.push({ text: "", visible: false }); // Add a new row with an empty note and unchecked checkbox
      }
      const notesForm=updatedNotes.map(note=> note.text);
      setFormData('notes',notesForm);
      setNotes(updatedNotes);
    }
    else{
      updatedNotes.splice(index,1);
      const notesForm=updatedNotes.map(note=> note.text);
      setFormData('notes',notesForm);
      setNotes(updatedNotes);
    }
  };

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index].text = value;
    const notesForm=updatedNotes.map(note=> note.text);
    setFormData('notes',notesForm); 
    setNotes(updatedNotes);
  };

  return (
    <div>
      {notes.map((note, index) => (
        <div key={index}>
          <label>
            <input
              type="checkbox"
              checked={note.visible}
              onChange={() => handleCheckboxChange(index)}
            />
            Add Note
          </label>
          {note.visible && (
            <input type="text"
              value={note.text}
              onChange={(e) => handleNoteChange(index, e.target.value)}
              placeholder="Enter a short note"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Note;
