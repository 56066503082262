import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const BarsChart = ({data,chosenColors}) => {
    const bars=Object.keys(data[0]).filter(key=>key!='name');
    const colors=['#8884d8','#82ca9d','rgb(0, 128, 255)'];
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {bars.map((dataKey,index)=><Bar dataKey={dataKey} fill={chosenColors ? chosenColors[dataKey]:colors[index%colors.length]} />)}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarsChart;