import { useState,useEffect} from 'react';
import { setTimeout,clearTimeout,setInterval,clearInterval } from "worker-timers";
import Popup from 'reactjs-popup';
import { LoadingButton } from '@mui/lab';
import {connect} from 'react-redux';
import store from '../store';
import NotPopupComp from './NotPopupComp';

const milisecondCheck=(timeto)=>{
    const date = new Date();
    date.setMilliseconds(date.getMilliseconds() + timeto); 
    return date.toTimeString();
}



// run all over notification check is same day set time interval by closest notification , missed notifications popup all together to set done or not
const NotificationsPopUp = ({notifications,notificationPopUpPeriodic,missedNotificationPopUpPeriodic}) => {
    const [popOn,setPopOn]=useState(false);
    const [popOnByHour,setPopOnByHour]=useState(false);
    const [popOnMissedByHour,setPopOnMissedByHour]=useState(false);
    const [notificationsView,setNotificationsView]=useState(store.getNotificationPopUp_obj(notifications));
    
    useEffect(()=>{
        
        const newView=(store.getNotificationPopUp_obj(notifications));
        setNotificationsView(newView);
        if(newView.timeToPopUp){
        const timeOutId = setInterval(() => {
            setPopOnByHour(true);
          }, newView.timeToPopUp);
        return ()=> clearInterval(timeOutId)
        }
        
    },[notifications])
    useEffect(()=>{
        const timeOutId = setInterval(()=>{
            notificationsView.hasOwnProperty('duringDay') && notificationsView.duringDay.length>0 && setPopOn(true);
        },1000*60*notificationPopUpPeriodic);
        return ()=> clearInterval(timeOutId)
    },[notificationPopUpPeriodic])
    useEffect(()=>{
        const timeOutId = setInterval(()=>{
            notificationsView.hasOwnProperty('missedByhour') && notificationsView.missedByhour.length>0 && setPopOnMissedByHour(true)
        },1000*60*missedNotificationPopUpPeriodic);
        return ()=> clearInterval(timeOutId)
    },[missedNotificationPopUpPeriodic])
    const handleRemindLater=()=>{
        setPopOn(false);
      
    }
    return (
        <div>
            <Popup open={popOn}  closeOnDocumentClick={false}>
                <div className='container-boxShadow notifications_popup-container during_day'>
                    <header>
                        {store.getRandomFunnyTaskReminder()}
                        <span style={{fontSize:'25px'}} onClick={()=>setPopOn(false)} className="exit-form exit_form_abs">X</span>
                    </header>
                    <div className='grid_3c notifications_popup'>
                        {notificationsView.hasOwnProperty('duringDay') && notificationsView.duringDay.map(notification=>!notification.isFinished && <NotPopupComp notification={notification}/>)}
                    </div>
                    <div className='flex-even mid'>
                        <LoadingButton variant="contained" onClick={handleRemindLater} color='warning'>There are more urgent things, remind me later</LoadingButton>
                    </div>
                </div>
            </Popup>
            <Popup open={popOnByHour} closeOnDocumentClick={false} >
                <div className='container-boxShadow notifications_popup-container by_hour'>
                    <header>
                        {/* { `${store.getRandomFunnyTaskReminder()} : ${milisecondCheck(notificationsView.timeToPopUp)}`} */}
                        {store.getRandomFunnyTaskReminder()}
                        <span style={{fontSize:'25px'}} onClick={()=>setPopOnByHour(false)} className="exit-form exit_form_abs">X</span>
                    </header>
                    <div className='grid_3c notifications_popup'>
                        {notificationsView.hasOwnProperty('byHour') && notificationsView.byHour.map(notification=>
                            store.notificationValidation_ByTime(notification,{isExact:true}) &&  !notification.isFinished &&  <NotPopupComp byHour={true} notification={notification}/>
                        )}
                    </div>

                </div>
            </Popup>
            <Popup open={popOnMissedByHour} closeOnDocumentClick={false} >
                <div className='container-boxShadow notifications_popup-container missed_by_hour'>
                    <header>
                        {/* { `${store.getRandomFunnyTaskReminder()} : ${milisecondCheck(notificationsView.timeToPopUp)}`} */}
                        Missed By Hour<br/>{store.getRandomFunnyTaskReminder()}
                        <span style={{fontSize:'25px'}} onClick={()=>setPopOnMissedByHour(false)} className="exit-form exit_form_abs">X</span>
                    </header>
                    <div className='grid_3c notifications_popup'>
                        {notificationsView.hasOwnProperty('missedByhour') && notificationsView.missedByhour.map((notification,index)=>
                             !notification.isFinished && <NotPopupComp key={index} byHour={true} notification={notification}/>
                        )}
                    </div>

                </div>
            </Popup>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        notifications:state.userInfo.notification,
        notificationPopUpPeriodic:state.userInfo.notificationPopUpPeriodic,
        missedNotificationPopUpPeriodic:state.userInfo.missedNotificationPopUpPeriodic,
      };
    };
  const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(NotificationsPopUp);