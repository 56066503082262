import React,{useEffect,useState} from 'react';
import './style.css';
import store from '../../store';
import {connect} from "react-redux";
import api from '../../api';
// hour width is 100px


const SchoolSchedule = ({activeWeeklySchedule,notifications,activeWorkoutPlan,getProjectById,notificationGroupIds}) => {
    
    const hourPixels_ratio=50;
    const [hours,setHours]=useState(store.getSchedule_latestAndEarliest_hour(activeWeeklySchedule));
    const [notByWeekDays,setNotByWeekDays]=useState(new Array(7).fill([]));
    const getNotificationsDisplayByDay=(startWorkHour,day)=>notByWeekDays[day].filter(not=> !not.finishDate  ? true : store.getHoursDifference(startWorkHour,not.byHour)[0]>= 0 ? true : false );

    const colors=store.colors;
    let colorIndex=0;
    const getColor=()=>{
        const eventColor= {
          
                backgroundColor:colors[colorIndex%colors.length],
            
        }
        colorIndex++;
        return eventColor;

    }
    useEffect(()=>{
        setHours(store.getSchedule_latestAndEarliest_hour(activeWeeklySchedule));
    },[activeWeeklySchedule]);
    useEffect(()=>{
    
        api.post('notification','get-notifications-week',notificationGroupIds).then(res=>res.json())
            .then(NotByWeekDaysData=>setNotByWeekDays(NotByWeekDaysData))
            .catch(err=>console.log(err))
       
    
    },[])
    const getHeightByHour=(hour )=>{
        const totalMinutes = hour[0] * 60 + hour[1]; // Convert hours to minutes
        const pixelsPerHour = hourPixels_ratio; // 100 pixels = 1 hour
        const pixels=totalMinutes / 60 * pixelsPerHour; // Convert minutes to pixels
        return {height:`${pixels}px`};

    }

    return (
        <div className='sch_comp-container'>
            <div className='school_schedule-container'>
                <div className='hours-wrapper'>
                    <h3>Hours</h3>
                    {Array.from({ length: hours.sumHour+1 }, (_, index) => (
                        <div key={index} className='hour_wrapper' style={{height:`${hourPixels_ratio}px`}}>
                            {store.pretifyTime(index==hours.sumHour ? hours.latestHour[0] : ((hours.earliestHour[0] + index)%24)) }:00
                        </div>
                    ))}
                </div>
                {Array.from({ length: 7 }, (_, index) => (
                    <div key={index} className='daily_school_schedule-container' style={{maxHeight:`${((hours.sumHour+1)*hourPixels_ratio)+60}px`}}>
                        <h3>{store.getDayNameByNumber(index)}</h3>
                        <div className='daily_hours_schedule-container'>
                         {store.isEarlier(hours.earliestHour,activeWeeklySchedule.schedule[index][0].startDayHour) && 
                            <div className='sleep-wrapper' style={getHeightByHour(store.getHoursDifference(activeWeeklySchedule.schedule[index][0].startDayHour,hours.earliestHour))}>
                                Sleeping
                            </div>
                         }
                         {store.isEarlier(activeWeeklySchedule.schedule[index][0].startDayHour,activeWeeklySchedule.schedule[index][0].startWorkHour) &&
                            <div className='inbetween-wrapper' style={ getHeightByHour(store.getHoursDifference(activeWeeklySchedule.schedule[index][0].startWorkHour,activeWeeklySchedule.schedule[index][0].startDayHour))}>
                                {/* In Between */}
                                {getNotificationsDisplayByDay(activeWeeklySchedule.schedule[index][0].startWorkHour,index).map(not=><span>{not.name}</span>)}
                            </div>
                         }
                            {activeWeeklySchedule.schedule[index].slice(1).map((scheduleByHour,index)=>
                                <div style={{...getColor(),...getHeightByHour(scheduleByHour.hours)}}>
                                    {getProjectById(scheduleByHour.key)?.name || 'Personal'}
                                </div>
                            )}
                            <wrapper className='flex-mid' style={{height:`${hourPixels_ratio}px`}}>
                                <h5>Done</h5>
                            </wrapper>
                        </div>
                    </div>
                ))}
              
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        notifications: state.userInfo.notification,
        notificationGroupIds: state.userInfo.notificationGroup.map(notGroup=>notGroup['_id']),
        activeWeeklySchedule: state.userInfo.weeklySchedule && state.userInfo.weeklySchedule.find(ws=>ws.isActive),
        activeWorkoutPlan: state.userInfo.workoutPlan && state.userInfo.workoutPlan.find(ws=>ws.isActive),
        getProjectById:(id)=>state.userInfo.project.find(p=>p['_id']===id),
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(SchoolSchedule);
