import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {connect} from 'react-redux';
import {resetIsError} from '../redux/actions/userInfoActions'
import { clearInterval, setInterval } from "worker-timers";
import { useEffect } from 'react';
const Alerts=({isError,resetErrorState,setResetTime})=> {
  useEffect(()=>{
    if(isError.status!=null){
      const interval = setInterval(() => {
        resetErrorState();
      },setResetTime || 900)
    
    return () => clearInterval(interval);
    }
  },[isError])
  return (
   <>
    {isError.status!=null ?
        <div className='alerts-container'>
            <Stack sx={{ width: '100%' }} spacing={2}>
            {  isError.status==true ?
                    <Alert severity="error">{isError.message!='' ? isError.message : 'This is an error alert — check it out!'}</Alert>
                    :
                    <Alert severity="success">{isError.message!='' ? isError.message : 'This is a success alert — check it out!'}</Alert>
            }
            </Stack>
        </div> 
        :
        <></>
        }
    </>
  );
}

const mapStateToProps = (state) => {
    return {
        isError: state.userInfo.isError,
      };
    };
    const mapDispatchToProps = (dispatch) => {
      return {
        resetErrorState:()=>dispatch(resetIsError())
      };
    };

export default connect(mapStateToProps,mapDispatchToProps)(Alerts)