import React from 'react';
import './style.css';
import store from '../../store';
import {connect} from "react-redux";
import { useState,useEffect,useRef } from 'react'; 
import {InfoHover} from '../';
// Array of month names
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
// get month width push by month width left:moth width width:month width*monthdifference || width : month width *12
const getProjectTimeLineComponent=(project,index,monthWidth)=>{
    const heigthDifference=50;
    const projectInfo=`Start Date - ${store.formatDate(project.startDate)} <br>
    Finish Date - ${store.formatDate(project.finishDate)} <br>
    Status - do a color line based on status (progress)`;
    
    const ChosenColor=store.colors[index%store.colors.length];
    const borderColor= { borderTop: `4px solid ${ChosenColor}`,borderRight: `4px dashed ${ChosenColor}`,borderLeft: `4px double ${ChosenColor}`}
    
    return (
            <div key={index} className={`project_tl-container ${project.untillEndYear && 'endYear'}`} style={{height:`${(project.position+1)*heigthDifference}px`,left:`${project.startMonth *monthWidth}px`,width:`${project.untillEndYear ? `100%` : `${(project.monthDifference+1) *monthWidth}px`}`,...borderColor}}>
                <InfoHover info={projectInfo} classes={'name cursor_def'} text={project.name}/>
            </div>
        
    )
}
// YearlyTimeLine component
const YearlyTimeLine = ({projects}) => {
    const monthsContainerRef = useRef(null);
    const [monthWidth, setMonthWidth] = useState(100);

    useEffect(() => {
      // Calculate the width of each month dynamically
      if (monthsContainerRef.current) {
        const containerWidth = monthsContainerRef.current.offsetWidth;
        const calculatedMonthWidth = containerWidth / months.length;
        setMonthWidth(calculatedMonthWidth);
      }
    }, []);
  
    const projectsTimeLineData=store.getProjectsYearlyTimeLine_obj(projects);

    return (
        <div className='yearlyTimeLine-container'>
     
            <div className='projects_timeline-container'>
                {projectsTimeLineData.map((project,index)=>getProjectTimeLineComponent(project,index,100))}

            </div>
            <div className="timeline-container">
            {months.map((month, index) => (
                <div key={index} className="month-container" ref={monthsContainerRef}>
                    <div className="month">{month}</div>
                </div>
            ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        projects:state.userInfo.project,
    };
  };
export default connect(mapStateToProps)(YearlyTimeLine);
