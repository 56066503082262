import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import api from '../../../api';
import './style.css'
import {connect} from 'react-redux';
import {successApi,fetchDataError} from '../../../redux/actions/userInfoActions';
const ResetNot = ({apiStatus}) => {

    const resetNot=()=>{
        api.get('admin','reset-notification').then(res=>res.ok ? apiStatus(true,'Reset Success') : apiStatus(false,'Reset Fail'));
    }
    return (
        <div className="exampleData-container container-boxShadow">
            <span> Reset Notification</span> 
            <div className='click icon-wrapper' onClick={resetNot}><RestartAltIcon style={{color:'rgb(255, 165, 0)'}}/></div>
          </div>
    );
};

const mapStateToProps = (state) => {
    return {
        exampleData: state.userInfo.isExample,
        };
    };
    const mapDispatchToProps = (dispatch) => {
        return {
            apiStatus:(success,message)=>dispatch(success ? (successApi(message)) : (fetchDataError(message))),
        };
    };
export default connect(mapStateToProps,mapDispatchToProps)(ResetNot);