import { useState,useEffect } from "react";
import {connect} from 'react-redux';
import store from "../store";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {setBackEnd,resetIsError} from '../redux/actions/userInfoActions';
import { LoadingButton } from '@mui/lab';
const DailyNotifications=({notificationsGroups,getNotificationsByGroupId,notifications,setIsAssigmentDone})=>{
    const [notificationsView,setNotificationsView]=useState(store.getNotificationPopUp_obj(notifications));
    // Adding Notifications Data

    Array.isArray(notificationsGroups) && notificationsGroups.forEach(notificationGroup => {
        const notifications=getNotificationsByGroupId(notificationGroup._id);
        notificationGroup['notifications']=notifications;
       
    });
    const finishAssigment=(notification)=>{
        
        const notificationStatsObj={
            score:{finishTime:new Date()},
            notificationGroupId:notification['notificationGroupId'],
            notificationId:notification['_id']
        }
        setIsAssigmentDone(notificationStatsObj);
    }
  
    //SLIDER CSS
    const [slideStyle,setSlideStyle]=useState(false);
    const setSlideStyleHandler=(prioreties)=>{
            prioreties ? 
                setSlideStyle(false) 
            :
                setSlideStyle({
                    left:'100%'
                });
    }

    // Visability On/Off 

    const [visability,setVisability]=useState(false);

    const handleVisability=(e)=>{
        setVisability(!visability);
    }

    useEffect(()=>{
        setNotificationsView(store.getNotificationPopUp_obj(notifications))
    },[notifications])



    return (
      
            <div className={`dailyNotification-container  ${visability && `visible`}`} id="dailyNotification-container">
                <div className={`visability flex-even mid`} onClick={handleVisability}>
                    <div className={`flex-even mid ${visability && `visible`}`}><ArrowBackIosIcon/></div>
                </div>
                <div className="header-wrapper flex-even mid" id="header-wrapper"><h3 className="notification-header">Daily Assignments</h3></div>
                <div className={`display-option-container`}>
                    <div className="option-wrapper">
                        <ul className="navbar-dailyNotification">
                            {/* <li className="nav-item-n" onClick={()=>setSlideStyleHandler(true)} style={!slideStyle ? {backgroundColor:'white'}: {}}>Prioreties </li>
                            <li className="nav-item-n" onClick={()=>setSlideStyleHandler()} style={slideStyle ? {backgroundColor:'white'}: {}} >All </li> */}
                        </ul>
                    </div>
                    <div className="slider-container-n" style={slideStyle.left && slideStyle}>
                        <div className="subpage-n">
                         <div className="nGroup-contaier" >
                            <h3 className="nGroup-header txt_center">Missed By Hour! </h3>
                                {notificationsView.hasOwnProperty('missedByhour') && notificationsView.missedByhour.map((notification,index)=>
                                    !notification.isFinished && 
                                    <div className="notification-wrapper" key={index}>
                                        <h5 className="notification-name missed">{notification.name}:</h5>
                                        <span className="notification-description">{notification.description}</span>
                                        <LoadingButton className="notification-done_btn missed" onClick={()=>finishAssigment(notification)}  variant="contained" color={'warning'}>Done</LoadingButton>
                                    </div>
                                )}
                         </div>
                         <div className="nGroup-contaier" >
                            <h3 className="nGroup-header txt_center">duringDay</h3>
                                {notificationsView.hasOwnProperty('duringDay') && notificationsView.duringDay.map((notification,index)=>
                                    !notification.isFinished && 
                                    <div className="notification-wrapper" key={index}>
                                        <h5 className="notification-name">{notification.name}:</h5>
                                        {notification.hasOwnProperty('byHour') && <div style={{fontSize:'18px'}}>Untill - {store.displayHours(notification.byHour)}</div>}
                                        <span className="notification-description">{notification.description}</span>
                                        <LoadingButton className="notification-done_btn during" onClick={()=>finishAssigment(notification)}  variant="contained" color={'success'}>Done</LoadingButton>
                                    </div>
                                )}
                         </div>
                        </div>
                        {/* <div className="subpage-n">
                        </div> */}
                    </div>
                </div>
            </div>

    )

}
const mapStateToProps = (state) => {
    return {
        notificationsGroups: state.userInfo.notificationGroup,
        notifications: state.userInfo.notification,
        getNotificationsByGroupId:(notificationGroupId)=>state.userInfo.notification.filter(currentNotification=> currentNotification.notificationGroupId==notificationGroupId)
      };
    };
    const mapDispatchToProps = (dispatch) => {
        return {
            resetErrorState:()=>dispatch(resetIsError()),
            setIsAssigmentDone:(data)=>dispatch(setBackEnd('notification','finish-notification','post',data,true,{_id:data.notificationId,key:'notification',value:{isFinished:true}})),
        };
    };


export default connect(mapStateToProps,mapDispatchToProps)(DailyNotifications);