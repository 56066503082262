import React from 'react';
import { useState,useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import {InfoHover,Form} from "..";
const FinancialConduct = () => {
    const [formParams,setFormParams]=useState(false);
    const openForm=()=>{
        setFormParams(['FinancialConduct','POST']);
    }
    
    return (
        <div>
            <InfoHover info='Quick Bank Update' classes={'quick_add_icon FinancialConduct flex-mid click'} children={<AddIcon/>} onClick={openForm}  />
            {formParams && <Form dbtype={'FinancialConduct'} method='POST' setFormMethod={setFormParams} />}
        </div>
    );
};

export default FinancialConduct;