
// responsible for fetching any data from api
export const FETCH_DATA_REQUEST='FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS='FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE='FETCH_DATA_FAILURE';

// responsible for  userInfo data
export const FETCH_USER_INFO='FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS='FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILURE='FETCH_USER_INFO_FAILURE';

// responsible for post put delete requests
export const SET_DATA_REQUEST='SET_DATA_REQUEST';
export const SET_DATA_SUCCESS='SET_DATA_SUCCESS';
export const SET_DATA_FAILURE='SET_DATA_FAILURE';

// responsible to reset isError to null after requests
export const RESET_IS_ERROR='RESET_IS_ERROR';
export const SET_BY_KEY='SET_BY_KEY';
export const SET_BY_KEY_FIXED='SET_BY_KEY_FIXED';
export const SET_BY_KEY_AND_ID='SET_BY_KEY_AND_ID';
export const SET_ORDER_BY_DBTYPE='SET_ORDER_BY_DBTYPE';

//RAM BACKEND DATA
export const SET_DATA_EXAMPLE='SET_DATA_EXAMPLE';
export const SET_DATA_EXAMPLE_SUCCESS='SET_DATA_EXAMPLE_SUCCESS';
export const SET_DATA_EXAMPLE_FAILURE='SET_DATA_EXAMPLE_FAILURE';

// responsible for notification not by hour popup time
export const SET_NOTIFICATIONS_POPUP_PERIODIC='SET_NOTIFICATIONS_POPUP_PERIODIC';

// responsible to update backend by dbtype,path,method and data
export const SET_BACKEND='SET_BACKEND';
export const SET_BACKEND_SUCCESS='SET_BACKEND_SUCCESS';
export const SET_BACKEND_FAILURE='SET_BACKEND_FAILURE';

// Success API
export const SET_SUCCESS_API='SET_SUCCESS_API';

// SET_TASKS_FINISH_TODAY from localStorage
export const SET_TASKS_FINISH_TODAY='SET_TASKS_FINISH_TODAY';
export const SET_TASKS_FINISH_TODAY_SUCCESS='SET_TASKS_FINISH_TODAY_SUCCESS';

export const LOGOUT_SUCCESS='LOGOUT_SUCCESS';