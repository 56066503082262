import {
    LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAILURE,
    LOGOUT_REQUEST,LOGOUT_SUCCESS,LOGOUT_FAILURE,
    IS_AUTH_REQUEST,IS_AUTH_SUCCESS,IS_AUTH_FAILURE,
    REGISTER_REQUEST,REGISTER_SUCCESS,REGISTER_FAILURE,GOT_USER_DATA
} from "../types/authTypes"

export const isAuthRequest=()=>{
    return {
        type:IS_AUTH_REQUEST
    }
}
export const isAuthSuccess=userInfo=>{
    return {
        type:IS_AUTH_SUCCESS,
        payload:userInfo
    }
}
export const isAuthFailure=()=>{
    return {
        type:IS_AUTH_FAILURE
    }
}
export const loginRequest=credentials=>{
    return {
        type:LOGIN_REQUEST,
        payload:credentials
    }
}
export const loginSuccess=authUser=>{
    return {
        type:LOGIN_SUCCESS,
        payload:authUser
    }
}
export const loginFailure=err=>{
    return {
        type:LOGIN_FAILURE,
        payload:err
    }
}

export const logoutRequest=()=>{
    return {
        type:LOGOUT_REQUEST
    }
}
export const logoutSuccess=()=>{
    return{
        type:LOGOUT_SUCCESS
    }
}
export const logoutFailure=()=>{
    return{
        type:LOGOUT_FAILURE
    }
}

export const registerRequest=credentials=>{
    return {
        type:REGISTER_REQUEST,
        payload:credentials
    }
}
export const registerSuccess=()=>{
    return {
        type:REGISTER_SUCCESS
    }
}
export const registerFailure=err=>{
    return {
        type:REGISTER_FAILURE,
        payload:err
    }
}

export const gotUserData=()=>{
    return {
        type:GOT_USER_DATA
    }
}