import React, { useEffect, useState } from 'react';
import store from '../../store';
import './VerticalProgressBar.css';
// FIXED DISTANCES SCHEDULE
  // first Task from top : 184.9
  // Project: 48
  // ProjectStep container padding top/bottom: 2 ,
  // ProjectStep: 29.9 + 100 margin( bottom top 50) = 129.9
  // each Task padding top: 5
  // task 124.58
  // between Project: 20




const VerticalProgressBar = ({ tasks ,propScheduledProjects,startHour}) => {
  
  const [completedTasks, setCompletedTasks] = useState(false);
  const [scheduledProjects,setScheduledProjects]=useState(propScheduledProjects)
  const getProgressBarData=()=>{

  }
  const getProgressHeight = (type,index,isLast,add=0,sub=0) => {
  
    switch (type) {
      case 'project':
        if(scheduledProjects[index]['projectSteps'].length==0) return 0;
        else return 45+add-sub;
      case 'projectStep':
        return 45+add-sub;
      case 'task':
        if(isLast) return 45+94.58+add-sub
        else return 94.58+add-sub
      case 'self':
        return add;
      case 'betweenProjects':
        if (scheduledProjects[index-1].name == 'personal') {
          return 0;
        }
        
        else if (scheduledProjects[index-1]['projectSteps'].length == 0) {
            return 0;
        }
        
        else if (scheduledProjects[index-1]['projectSteps'].hasOwnProperty('tasks') && scheduledProjects[index-1]['projectSteps']['tasks'].length == 0) {
            return 0;
        }
        else return add;
    
      default:
        break;
    }
  };
  useEffect(()=>{
    setScheduledProjects(propScheduledProjects);
    
  },[propScheduledProjects])
  return (
    <>
    <div className="scheduleProjects-progress-bar">
      {scheduledProjects.map((project,projIndex)=>
          <div key={projIndex}>
          {projIndex>0 &&  <div style={{height:getProgressHeight('betweenProjects',projIndex,false,10)}} className='project-line-wrapper line_progress_wrapper'></div>}
          <div className="scheduleProject-progress-bar-inner mid_circle">
              <div className={`project-circle ${projIndex!=0 && 'pMargin_top_circle'} circle-wrapper`}>
                <div className={`progress-circle ${project['isActive'] && 'completed'}`}></div>
              </div>
              <div style={{height:getProgressHeight('project',projIndex)}} className='project-line-wrapper line_progress_wrapper'>
                <div style={{height:project['isActive'] ? `${getProgressHeight('project',projIndex,false,-4)}px` : 0 }} className='project_progress-line progress-line'></div>
              </div>
              {Array.isArray(project['projectSteps']) && project['projectSteps'].map((projectStep,psIndex)=> 
                <div key={psIndex} className="scheduleProjectStep-progress-bar-inner mid_circle">
                  {psIndex!=0 &&  <div style={{height:getProgressHeight('projectStep',psIndex,false,10)}} className='project-line-wrapper line_progress_wrapper'></div>}
                  <div className='projectStep-circle circle-wrapper'>
                    <div className={`progress-circle ${projectStep['isActive'] && 'completed'}`}></div>
                  </div>
                  <div style={{height:getProgressHeight('projectStep')}} className='projectStep-line-wrapper line_progress_wrapper'>
                    <div style={{height:projectStep['isActive'] ? `${getProgressHeight('projectStep',psIndex,false,-4)}px` : 0 }} className='projectStep_progress-line progress-line'></div>
                  </div>
                  {Array.isArray(projectStep['tasks']) && projectStep['tasks'].map((task,taskIndex)=>
                    <div key={taskIndex} className="scheduleTask-progress-bar-inner mid_circle">
                      <div className='task-circle circle-wrapper'>
                        <div className={`progress-circle ${task.status!=-1 && 'completed'}`}></div>
                      </div>
                      <div style={{height:getProgressHeight('task')}} className='task-line-wrapper line_progress_wrapper '>
                        <div style={{height:task.status!=-1 ? `${getProgressHeight('task',taskIndex,false,-4)}px` : 0 }} className='task_progress-line progress-line'></div>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
          </div>
      )}
    </div></>
  );
};

export default VerticalProgressBar;
