import React from 'react';
import VerticalProgressBar from '../progress-bar/VerticalProgressBar';
import Tasks from '../Tasks';
import store from '../../store';
import localStorageUtil from '../../localStorageUtil';
import {connect} from 'react-redux';
import { useState,useEffect } from 'react';
import {setFinishTasksToday} from '../../redux/actions/userInfoActions';

const ScheduledProjects = ({scheduledProjects,startHour}) => {
  
    const getByOrderTasks=(tasks)=>{
        let sortedByOrder=tasks.filter(task=>task.status!==1);
        sortedByOrder.sort((a,b)=>a.order-b.order);
        return sortedByOrder;
    }
    

    return (
        <div className='scheduledProjects-container'>
            <div className='progress_bar-container'>
                    <VerticalProgressBar propScheduledProjects={scheduledProjects} startHour={startHour}/>
            </div>
            <div className='flex-column'>
                {Array.isArray(scheduledProjects) && scheduledProjects.map((project,pIndex)=>
                    <div key={pIndex} className='sProject-container container-boxShadow'>
                        <h1 className='header_startdir'>{project.name}  (<span style={{fontSize:'26px'}}>{store.displayHours(project['hours']) }</span>)</h1>
                        {Array.isArray(project['projectSteps']) && project['projectSteps'].map((projectStep,psIndex)=> 
                            <div key={psIndex} className='sProjectStep-container'>
                                <h4 className='header_startdir sProjectStep-header'>{projectStep.name}</h4>
                                {Array.isArray(projectStep['tasks']) && <Tasks projectStepId={projectStep['_id']} tasks={getByOrderTasks(projectStep['tasks'])}  isHomePage />}

                            </div>
                        )}
                    </div>
                )}
            </div>
            
        </div>
    );
};
const mapStateToProps = (state) => {
    return {

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        initFinishedToday:()=>dispatch(setFinishTasksToday()),
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(ScheduledProjects);
