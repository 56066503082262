import {
    LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAILURE,
    LOGOUT_REQUEST,LOGOUT_SUCCESS,LOGOUT_FAILURE,
    IS_AUTH_REQUEST,IS_AUTH_SUCCESS,IS_AUTH_FAILURE,
    REGISTER_REQUEST,REGISTER_SUCCESS,REGISTER_FAILURE,GOT_USER_DATA
} from "../types/authTypes"


const initializeAuth={
    isAuth:false,
    _id:false,
    username:'',
    firstName:'',
    lastName:'',
    loader:false
}


export default function reducer(state=initializeAuth,action){
    switch (action.type) {
        case LOGIN_REQUEST:
            return {...state,loader:true};
        case LOGIN_SUCCESS:
            return {isAuth:true,loader:false,...action.payload};
        case LOGIN_FAILURE:
            return {...state,loader:false};
        case IS_AUTH_REQUEST:
            return {...state,loader:true};
        case IS_AUTH_SUCCESS:
            return {isAuth:true,loader:true,...action.payload};
        case IS_AUTH_FAILURE:
            return {
                isAuth:false,
                username:'',
                firstName:'',
                lastName:'',
                loader:false
            }
        case GOT_USER_DATA:
            return {...state,loader:false}
        case LOGOUT_REQUEST:
            return state;
        case LOGOUT_FAILURE:
            return state;
        case LOGOUT_SUCCESS:
            return {
                isAuth:false,
                username:'',
                firstName:'',
                lastName:'',
                loader:false
            }
        
        default:
           return state
    }
}



