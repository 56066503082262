import { useEffect } from "react";
import {connect} from 'react-redux';
import {resetIsError} from '../redux/actions/userInfoActions'
// import BlurrBg from "./BlurrBg";
import ChosenFrom from "./ChosenFrom";

const getHebrew=(dbtype,method)=>{
    let headline='';
    switch (method) {
        case "POST":
            headline += "הוסף-";
            break;
        case "DELETE":
            headline += "מחק-";
            break;
        case "PUT":
            headline += "עדכן-";
            break;
        case "UPDATE":
            headline += "עדכן-";
            break;
        default:
            return false;
    }
    switch (dbtype) {
        case "project":
          headline += "פרויקט";
          break;
        case "projectStep":
          headline += "שלב פרויקט";
          break;
        case "task":
          headline += "משימה";
          break;
        case "idea":
          headline += "רעיון";
          break;
        case "notificationGroup":
          headline += "קבוצת התראות";
          break;
        case "notification":
          headline += "התראות";
          break;
        case "user":
          headline += "משתמש";
          break;
        case "workoutPlan":
          headline += "תוכנית אימון";
          break;
        case "weeklySchedule":
          headline += "מערכת שבועית";
          break;
        case 'expense':
          headline +='הוצאות';
          break;
        case 'bank':
          headline +='בנק';
          break;
        case 'card':
          headline +='כרטיס אשראי';
          break;
        case 'income':
          headline +='הכנסה';
          break;
        case 'paycheck':
          headline +='משכורת';
          break;
        case 'FinancialConduct':
          headline +='התנהלות פיננסית';
          break;
       
        default:
          return false;
      }
      
   
    return headline;
}

const Form= ({dbtype,method,setFormMethod,isError,resetErrorState,quickAddData})=>{
    let headline=getHebrew(dbtype,method);
    const setFormState=(exitFormState,e)=>{
        document.getElementsByClassName('App')[0].removeAttribute('style');
        (e.target.className==='blurrBg' || e.target.className==='exit-form') && setFormMethod(exitFormState);
    }
    const handleIsErrorChange = () => {
      switch (isError.status) {
        case false:
          // resetErrorState();
          document.getElementsByClassName('App')[0].removeAttribute('style');
          setFormMethod(false);
          setTimeout(()=>{resetErrorState()},3000)
          break;
        case true:
          setTimeout(()=>{resetErrorState()},3000)
          // handle show error popup
          break;
        case null:
          
          break;
      
        default:
          break;
      }
    };
    
    useEffect(()=>{
       document.getElementsByClassName('App')[0].style.cssText='overflow-y:hidden;';
    },[dbtype,method]);
    
    useEffect(handleIsErrorChange, [isError]);
    
    return (
     <>
        {method && setFormMethod && 
            <div onClick={(e)=>setFormState(false,e)} className="blurrBg">
                <div className="form-container">
                    <header>
                      <h2>{headline}</h2>
                      <span onClick={(e)=>setFormState(false,e)} className="exit-form">X</span>
                    </header>
                    <ChosenFrom dbtype={dbtype} method={method} quickAddData={quickAddData}/>
                </div>
            </div> 
        }
    </>
    )
}
const mapStateToProps = (state) => {
  return {
      isError: state.userInfo.isError,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
     resetErrorState:()=>dispatch(resetIsError())
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(Form);


{/* <div onClick={()=>setFormState(false)} className="blurrBg"> */}