import React, { useState,useEffect} from 'react';

import {connect} from 'react-redux';



const Check = ({projects}) => {

    return (
        <div className="page-container projects">
          
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        projects: state.userInfo.project,
      };
    };
    const mapDispatchToProps = (dispatch) => {
      return {
       
      };
    };
export default connect(mapStateToProps,mapDispatchToProps)(Check);
// export default connect(mapStateToProps,mapDispatchToProps)(Projects);