import Form from "../components/Form";
import Add from '../components/Add';
import { useState ,useEffect} from "react";
import { Idea } from "../components";
import {connect} from 'react-redux';
import { WidthProvider, Responsive } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);
const Ideas =({ideas})=>{
    const [formParams,setFormParams]=useState(false);

    useEffect(()=>{
    },[formParams,ideas])
    return (
    <div className="page-container">
        <div dir="ltr"> 
            <ResponsiveGridLayout style={{ height: "2000px" }}  className="layout" cols={{ lg: 3, md: 2, sm: 1,xs:1 }} resizable={false}>
            {Array.isArray(ideas) &&
            ideas.map((idea, index) => (
                <div dir="RTL" style={{ height: "auto" }} key={index} data-grid={{ x: (index % 3), y: Math.floor(index / 3), w: 1, h: 1.5 }} >
                <Idea  ideaInfo={idea} key={index} />
                </div>
            ))}
            </ResponsiveGridLayout>
         </div>
        <Add route='ideas' setFormMethod={setFormParams}/>
        {formParams!==false?  <Form dbtype={formParams[0]} method={formParams[1]} setFormMethod={setFormParams}/> : <></>}
    </div>
    )
}
const mapStateToProps = (state) => {
    return {
        ideas: state.userInfo.idea,
      };
};
const mapDispatchToProps = (dispatch) => {
      return {
       
      };
};
export default connect(mapStateToProps,mapDispatchToProps)(Ideas);

