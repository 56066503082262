import React from 'react';
import './style.css';
import api from '../../../api';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { LoadingButton } from '@mui/lab';
import { useState,useEffect } from 'react';
const WaitingList = () => {
    const [list,setList]=useState([]);
    useEffect(()=>{
        api.get('admin','wating-list').then(res=>res.json())
        .then(waitingList=>setList(waitingList))
        .catch(err=>console.log(err));
    },[])
    const updateApproveStatus=(userId,status)=>{
        api.post('admin','approve',{list:[userId],status})
        .then(res=>{
            if(res.ok){
                const updatedList=list.filter(user=>user._id!==userId);
                setList(updatedList);
            }
        })
        .catch(err=>console.log(err));
    }
    const handleApproveAll=()=>{
        if(list.length===0) return;
        const usersIds=list.map(user=>user._id);
        api.post('admin','approve',usersIds)
        .then(res=>{
            if(res.ok){
                setList([]);
            }
        })
        .catch(err=>console.log(err));
    }

    const getAllUsers=()=>{
        api.get('admin','get-users').then(res=>res.json())
        .then(waitingList=>setList(waitingList))
        .catch(err=>console.log(err));
    }

    return (
       
        <div className='waitinglist-container'>
            <div className='header'>
                User List (Default Waiting For)
            </div>
            <div className=''>
                <div className="exampleData-container container-boxShadow">
                    <span> Get All Users</span> 
                    <div className='click icon-wrapper' onClick={getAllUsers}><FilterListOffIcon style={{color:'rgb(255, 165, 0)'}}/></div>
                </div>
            </div>
            <div className='users-container'>
                <table className='theme'>
                    <thead>
                        <tr>
                            <th>first name</th>
                            <th>last name</th>
                            <th>username</th>
                            <th>email</th>
                            <th>approve</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(user=>
                            <tr>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>
                                    <span onClick={()=>!user.approved && updateApproveStatus(user['_id'],true)} className={`approve_icon-wrapper ${user.approved && 'cursor_def'}`}><CheckBoxIcon style={{color:!user.approved? 'green' : 'grey'}}/></span>
                                    <span onClick={()=>user.approved && updateApproveStatus(user['_id'],false)} className={`approve_icon-wrapper ${!user.approved && 'cursor_def'}`}><RemoveCircleIcon style={{color:user.approved ? 'red' : 'grey'}}/></span>
                                </td>
                            </tr>

                        )}
                        
                    </tbody>
                </table>
                <div className='approve_all-wrapper'>
                <LoadingButton onClick={handleApproveAll} variant="contained" >Approve All</LoadingButton>
                </div>
            </div>
            
        </div>
   
    );
};

export default WaitingList;