import NavHeader from "./NavHeader";
import {connect} from 'react-redux';
import { useState,useEffect } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import {logoutRequest} from '../redux/actions/authActions';
import store from "../store";
const Header=({logout,leftTasks,finishedTasks,tasks,unfinished})=>{
    const [totalDuration,setTotalDuration]=useState(0);
    const initTotal=()=>{
        let sumD=0;
        unfinished().forEach(ts=>sumD+=ts.duration);
        setTotalDuration(sumD)
    }
    useEffect(()=>{
        initTotal()
    },[tasks]);
  

    return (
    <header className="header">
        <NavHeader/>
        <span style={{color:Number(leftTasks()) > 10 ? `red`:`black`}} className={`tasks_left-wrapper cursor_def`}>{`Tasks Left : ${leftTasks()}`}<br/>{`Hours(${store.displayHours([Math.floor(totalDuration / 60),totalDuration%60])})-Days(${store.displayHours([Math.floor((totalDuration / 60)/24),Math.floor(totalDuration / 60)%24])})`}</span>
        <span style={{color:Number(leftTasks()) > 25 ? `white`:`blue`}} className={`tasks_left-wrapper finished cursor_def`}>Finished Tasks : {finishedTasks()}</span>
        <span onClick={logout} className="logout_icon-wrapper flex-even mid"><LogoutIcon/></span>
    </header>
    )
}

const mapStateToProps = (state) => {
return {
    leftTasks:()=>(state.userInfo.task.filter(t=>t.status!=1)).length,
    finishedTasks:()=>(state.userInfo.task.filter(t=>t.status===1)).length,
    unfinished:()=>(state.userInfo.task.filter(t=>t.status!==1)),
    tasks:state.userInfo.task,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    logout:()=>dispatch(logoutRequest())
    
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Header);
