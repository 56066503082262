import React from 'react';
import { useState,useEffect} from 'react';
import { clearInterval, setInterval } from "worker-timers";
import { LoadingButton } from '@mui/lab';
import {connect} from 'react-redux';
import store from '../store';
import Popup from 'reactjs-popup';
import {setFinishTask} from '../redux/actions/onTaskActions';


const getTimeLeft=(onTask)=>{
  // checks if added extra time for task
  if(onTask.extraTime){
    return store.calculateTimeLeft(new Date(onTask.endTime.getTime() + onTask.extraTime * 60000));
  }
  else{
    return store.calculateTimeLeft(onTask.endTime)
  }
}

const TimerHook = ({onTask ,extraTimeEl,finishTask,almostFinishTask,notFinishTask}) => {
    const [countdownTimer,setCountdownTimer]=useState(getTimeLeft(onTask));    
    const [popOn,setPopOn]=useState(false);
    useEffect(() => {
      if(getTimeLeft(onTask).hasOwnProperty('hours')){
        
          const interval = setInterval(() => {
            onTask.breakStart===false && setCountdownTimer(getTimeLeft(onTask));
          }, 1000);
          return () => clearInterval(interval);
      }
      else{
        setCountdownTimer({hours:0,minutes:0,seconds:0});
        setPopOn(true);

      }
      }, [countdownTimer,onTask]);
    
    return (
      <>
        <div className='timerhook_wrapper'>{store.pretifyTime(countdownTimer.hours)}:{store.pretifyTime(countdownTimer.minutes)}:{store.pretifyTime(countdownTimer.seconds)}</div>
        <Popup open={popOn}  >
          <div className='task_endTime-container container-boxShadow'>
            <h3 className='timesUp_header'>TIMES UP!</h3>
            <div className='task_endTime_options-container'>
              <div>
                <span>Set Finish</span>
                <LoadingButton variant="contained" onClick={finishTask} color='success'>Finish</LoadingButton>
              </div>
              <div>
                  <span>Add Extra Time</span>
                  <LoadingButton  variant="contained" color='info' onClick={extraTimeEl} >Extra Time</LoadingButton>
              </div>
              <div>
                <span>Almost Finished</span>
                <LoadingButton variant="contained" onClick={almostFinishTask} color='warning'>Almost Finish</LoadingButton>
              </div>
              <div>
                <span>Not Finished</span>
                <LoadingButton variant="contained" onClick={notFinishTask} color='error'>Not Finish</LoadingButton>
              </div>
              
            </div>
          </div>
        </Popup>
      </>
    );
};
const mapStateToProps = (state) => {
  return {
      onTask:state.onTask,
      isTaskDone:(taskId)=>state.userInfo.tasks.find(task=>task['_id']===taskId)
    };
  };
const mapDispatchToProps = (dispatch) => {
  return {
    finishTask:()=>dispatch(setFinishTask(1)),
    almostFinishTask:()=>dispatch(setFinishTask(0)),
    notFinishTask:()=>dispatch(setFinishTask(-1)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(TimerHook);