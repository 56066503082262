import React, { useState ,useRef} from 'react';
import './style.css';

const InfoHover = ({ info, text ,classes , children , onClick,addedStyle={}}) => {
  const [hovered, setHovered] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const hoverRef = useRef(null);

  const handleMouseEnter = (e) => {
    const rect = hoverRef.current.getBoundingClientRect();
    setPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      ref={hoverRef}
      className={`hover-container ${classes}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick && onClick}
      style={addedStyle}
    >
      {text && text}
      {children}
      {hovered && (
        <div
          className="info-window"
          style={{ top: `${position.y}px`, left: `${position.x}px`,color:'black' }}
          dangerouslySetInnerHTML={{ __html: info }}
        >
        </div>
      )}
    </div>
  );
};

export default InfoHover;
