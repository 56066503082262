import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';


// Create the Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Define the store configuration
const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

// Run your root Saga
sagaMiddleware.run(rootSaga);

export default store;