import {Chart} from "..";
import ProjectStep from "./ProjectStep";
import {connect} from 'react-redux';
import { useState,useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import TocIcon from '@mui/icons-material/Toc';
import {InfoHover,Form} from "..";
import Popup from 'reactjs-popup';
import ProjectStepTaskOrganizer from './ProjectStepTaskOrganizer';
import {BucketOfWater} from '../';
const ProjectPage=({projectId,projectSteps,getProjectInfo})=>{
    const [popOn,setPopOn]=useState(false);
    const project=getProjectInfo(projectId);
    const [sortList,setSortList]=useState([]);
    const [formParams,setFormParams]=useState(false);
    const openForm=()=>{
        setFormParams(['projectStep','POST']);
    }
    useEffect(()=>{
        
        let sortedByOrder=projectSteps.filter((projectStep) => projectStep.projectId === projectId);
        sortedByOrder.sort((a,b)=>a.order-b.order);
        setSortList(sortedByOrder);
    },[projectSteps]);

    return (
    <div className="project-container">
        <div className="flex-even mid extra">
            <InfoHover info='Quick Project-Step Add' classes={'quick_add_icon flex-mid click'} children={<AddIcon/>} onClick={openForm}  />
            <InfoHover info='Order Project-Steps' classes={'quick_add_icon flex-mid click'} children={<TocIcon/>} onClick={()=>setPopOn(true)}  />
            <BucketOfWater showPercentage bucketStyle={{height:'50px',width:'25px'}}  validIdArr={projectSteps.filter((projectStep) => projectStep.projectId === projectId).map(ps=>ps['_id'])} />
        </div>
        <Chart/>

        {
            sortList
            .map((projectStep, index) => (
            <ProjectStep
                projectId={projectId}
                projectStepId={projectStep._id}
                key={index}
                index={index}
            />
            ))
        }
        {formParams && <Form dbtype={'projectStep'} method='POST' setFormMethod={setFormParams} quickAddData={{projectId}} />}
        {popOn && 
            <Popup open={popOn} >
                <ProjectStepTaskOrganizer dbtype={'projectStep'} parentIdKey={'projectId'} setPopOn={setPopOn} filterId={projectId} name={project.name}/>
            </Popup>
        }
    </div>
    )
}
const mapStateToProps = (state) => {
    return {
        projectSteps: state.userInfo.projectStep,
        getProjectInfo:(projectId)=>state.userInfo.project.find(currentProject=>currentProject._id===projectId)
      };
    };
    const mapDispatchToProps = (dispatch) => {
      return {
       
      };
    };
export default connect(mapStateToProps,mapDispatchToProps)(ProjectPage);