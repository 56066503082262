import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useState,useEffect } from "react";
import moment from 'moment';
import {connect} from 'react-redux'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment) 
// https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/examples--luxon-localizer

const Scheduler=({activeWeeklySchedule,userInfo})=>{
    const [view,setView]=useState('day');
    const setNewView=newView=>{
        setView(newView);
    }
    const colors = ['rgb(255, 255, 0,0.7)','rgb(136,198,150)', 'rgb(0, 128, 255,0.7)','rgb(255, 165, 0,0.7)'];
    let colorIndex=0;
//    events handlers
    const getDataById_dbType=(id,dbType)=>{
        return userInfo[dbType].find(type=>type._id==id)
    }
    const scheduleToEvent=()=>{
        const events=[];
        const day=(new Date()).getDay();
        let sumHours=[...activeWeeklySchedule.schedule[day][0].startWorkHour];
        const scheduledProjects=activeWeeklySchedule.schedule[day].slice(1);
        for(let id in scheduledProjects){
            let title= scheduledProjects[id].key!='personal' ? getDataById_dbType(scheduledProjects[id].key,'project').name : 'personal';
            let startHour=new Date(new Date().setHours(sumHours[0],sumHours[1]));
            let left=sumHours[1]+scheduledProjects[id][1] >= 60 ? 1 :0;
            sumHours[1]= left==1? (sumHours[1]+scheduledProjects[id]['hours'][1])%60 :sumHours[1]+scheduledProjects[id]['hours'][1];
            sumHours[0]=scheduledProjects[id]['hours'][0]+sumHours[0]+left;
            let endHour=new Date(new Date().setHours(sumHours[0],sumHours[1]));
            events.push({title,start:startHour,end:endHour});
        }
        return events;
    }
    const eventPropGetterColors=(event)=>{
        if(colorIndex>=colors.length){
            colorIndex=colorIndex%colors.length
        }
        const eventColor= {
            style:{
                backgroundColor:colors[colorIndex%colors.length],
                color:'black'
            }
        }
        colorIndex++;
        return eventColor;

    }
    const events= activeWeeklySchedule ? scheduleToEvent() : false ;
    const scrollDate=()=>{
        return  new Date(new Date().setHours(moment().hour()));
    }
    
    return (
        <div className="scheduler-container">
            {  events ?
                    <Calendar
                    style={{backgroundColor:'white',borderRadius: '10px',color:'black'}}
                    localizer={localizer}
                    step={30}
                    startAccessor="start"
                    endAccessor="end"
                    className='element-boxShadow'
                    events={events}
                    view={view}
                    onView={setNewView}
                    scrollToTime={['week','day'].includes(view) && scrollDate()}
                    eventPropGetter={eventPropGetterColors}
                    />
                    :
                    
                       <h1>NADA</h1>
                   
            }
      </div>
    )
}
const mapStateToProps = (state) => {
    return {
        activeWeeklySchedule: state.userInfo.weeklySchedule && state.userInfo.weeklySchedule.find(ws=>ws.isActive),
        userInfo:state.userInfo
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(Scheduler);
{/* <CustomView localizer={localizer} /> */}