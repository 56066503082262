import { useState } from "react";
import store from "../store";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {setStartTask,setBreakTask,setReturnBreakTask,setExtraTimeTask,setFinishTask} from '../redux/actions/onTaskActions';
import {connect} from 'react-redux';


const Task = ({task,isHomePage,startTask,onTask}) =>{
    // const [isClosed,setIsClosed]=useState(isHomePage ? false :  true)
    const [isClosed,setIsClosed]=useState(false )
    const handleTaskInfoExpantion=(e)=>{
        if(isHomePage) return;
        const taskContainer=e.target.closest('.task-container');
        const projectStepContainer=e.target.closest('.projectStep-container');
        if(!isClosed){
            projectStepContainer.style.cssText=`height:${projectStepContainer.clientHeight-(taskContainer.clientHeight-46)}px;`;
        }
        else{
            projectStepContainer.style.cssText=`height:${projectStepContainer.clientHeight+taskContainer.clientHeight+10}px;`;
        }
        setIsClosed(!isClosed);
       

    }
    return (
        <div   className={`task-container${isClosed ? ' task-closed' : ''} ${task.status==1 ? 'completed_task-container' : task.status==0 ? 'inprogress_task-container' :''}`}>
            <div className={`task_header-wrapper`}>
                <h3>{task.name}</h3>
                <div className={`task-duration_start-wrapper ${!isHomePage && `projects`}`} >
                     <PlayCircleFilledWhiteIcon color={task.status==1 ? 'action' :  !(onTask._id) ? "success" :onTask._id===task._id ? 'warning' : 'action'} onClick={()=>!(onTask._id) && task.status!=1 && startTask(task)}/>
                    <div className="flex-even">
                        <h3>{store.formatMinutesToHHMM(task.duration)}</h3>
                        {task.status===1 && <CheckCircleOutlineIcon  color="success" />}
                    </div>
                </div>
            </div>
            <div className="task_info-wrapper">
                {task.info}
            </div>
        </div>
    )
} 


const mapStateToProps = (state) => {
    return {
        onTask:state.onTask,
        isTaskDone:(taskId)=>state.userInfo.tasks.find(task=>task['_id']===taskId)
      };
    };
const mapDispatchToProps = (dispatch) => {
    return {
        startTask:(task)=>dispatch(setStartTask(task))
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Task);