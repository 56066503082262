import React, { useEffect, useRef, useState } from 'react';
import './DropDown.css'
import { current } from '@reduxjs/toolkit';
const DropDown = ({ classes, children,childEventId='chosenEvent',childrenView ,cssAffectsCounter=0}) => {
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(false);
    const [isOpen,setIsOpen]=useState(false);
    const setChosenHeight=(chosenCase)=>{
        const container = containerRef.current;
        let counter=cssAffectsCounter;
        switch (chosenCase) {
            case 'auto':
                container.childNodes.forEach(el => {
                    counter += el.offsetHeight;
                });
                setIsOpen(true);
                break;
        
            default:
                if(childrenView && !isNaN(childrenView) && childrenView>0){
                    container.childNodes.forEach((el, index) => {
                        if(el.classList.contains('children-container'))  {
                            el.childNodes.forEach((children, indexChild) => {
                                if (indexChild+1 <= childrenView) {
                                    counter += children.offsetHeight;
                                }
                            })
                        }
                    });
                }
                 counter+=container.firstChild.offsetHeight;
                 
                setIsOpen(false);

                break;
        }
        
        setContainerHeight({height:counter + 'px'});
    }
    const clickHandler=()=>{
        isOpen ? setChosenHeight() : setChosenHeight('auto');
    }
    useEffect(() => {
        setChosenHeight();
    }, [children]);
    
    const clonedChildren = React.Children.map(children, (child, index) => {
        if (child && child.props.id === childEventId) {
            return React.cloneElement(child, { onClick: clickHandler });
        }
        return child;
    });
    useEffect(()=>{
        const eventChild = Array.from(containerRef.current.childNodes).find(el => el.getAttribute('id') === childEventId);
        if(isOpen){
            eventChild && eventChild.classList.add('open');
        }
        else{
            eventChild && eventChild.classList.remove('open');
        }
    },[isOpen])
    return (
        <div
            className={`dropdown-container ${classes && classes}`}
            style={containerHeight ? containerHeight : {}}
            ref={containerRef}
        >
            {clonedChildren}
        </div>
    );
};

export default DropDown;
