import {Chart} from "..";
const PanelPage=({allProjects})=>{
    return (
        <div className="projectsHome">
            <h1>Projects Progress Bar</h1>
            <Chart/>
        </div>
    )
}

export default PanelPage;