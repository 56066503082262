
import {SET_TASK_START,SET_TASK_FINISH,SET_TASK_BREAK,SET_TASK_RETURN_BREAK,
    SET_TASK_EXTRA_TIME,SET_TASK_FINISH_SUCCESS,SET_TASK_FINISH_FAILURE} from '../types/onTaskTypes';


export const setStartTask=({_id,name,duration})=>{
    return {
        type:SET_TASK_START,
        payload:{
            _id,
            name,
            duration
        }
    }
}

export const setBreakTask=()=>{
    return {
        type:SET_TASK_BREAK
    }
}
export const setReturnBreakTask=()=>{
    return {
        type:SET_TASK_RETURN_BREAK
    }
}
export const setExtraTimeTask=(duration)=>{
    return {
        type:SET_TASK_EXTRA_TIME,
        payload:duration
    }
}


export const setFinishTask=(status)=>{
    return {
        type:SET_TASK_FINISH,
        payload:status
    }
}
export const setFinishTaskSuccess=()=>{
    return {
        type:SET_TASK_FINISH_SUCCESS
    }
}
export const setFinishTaskFailure=()=>{
    return {
        type:SET_TASK_FINISH_FAILURE
    }
}